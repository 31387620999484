/* globals Enums */
/* globals EventEmitter */
/* globals _ */

// eslint-disable-next-line no-extra-semi,wrap-iife
;(function AbstractModalScope(nsp) {
  'use strict';

  var HIDE_ANIMATION_DURATION = 200;
  var VISIBLE_CLASS = 'visible';

  var AbstractModal = function(options) {
    this.options = _.defaults(options || {}, {
      onOpen: _.noop,
      onClose: _.noop,
    });
  };

  AbstractModal.prototype = Object.create(EventEmitter.prototype);
  AbstractModal.constructor = AbstractModal;

  AbstractModal.CLOSE_BUTTON_CLASS = 'close-modal-button';

  AbstractModal.prototype.render = function() {
    this.eventRefs = {};

    this.el = document.createElement('section');
    this.el.classList.add('modal-box');

    this.addEvents();
    return this;
  };

  AbstractModal.prototype.getElement = function() {
    return this.el;
  };

  AbstractModal.prototype.destroy = function() {
    this.hide();

    setTimeout(function() {
      if (this.el.parentNode) {
        this.el.parentNode.removeChild(this.el);
      }
    }.bind(this), HIDE_ANIMATION_DURATION);

    document.removeEventListener('keydown', this.eventRefs.keyDown);

    if (this.options && _.isFunction(this.options.onClose)) {
      this.options.onClose();
    }
  };

  AbstractModal.prototype.appendTo = function(element) {
    if (_.isFunction(this.options.onOpen)) {
      this.options.onOpen();
    }

    element.appendChild(this.getElement());
    return this;
  };

  AbstractModal.prototype.show = function(delay) {
    if (delay > 0) {
      setTimeout(this.show.bind(this, 0), delay);
    } else {
      this.el.classList.add(VISIBLE_CLASS);
    }

    return this;
  };

  AbstractModal.prototype.hide = function() {
    this.el.classList.remove(VISIBLE_CLASS);
  };

  AbstractModal.prototype.addEvents = function() {
    this.el.addEventListener('click', this.delegatedClick.bind(this));

    this.eventRefs.keyDown = this.keyDown.bind(this);
    document.addEventListener('keydown', this.eventRefs.keyDown);
  };

  AbstractModal.prototype.delegatedClick = function(e) {
    if (e.target.classList.contains(AbstractModal.CLOSE_BUTTON_CLASS)) {
      this.destroy();
      e.preventDefault();
    }
  };

  AbstractModal.prototype.keyDown = function(e) {
    if (e.keyCode === Enums.KEYS.ESC) {
      this.destroy();
    }
  };

  // eslint-disable-next-line no-param-reassign
  nsp.AbstractModal = AbstractModal;

// eslint-disable-next-line padded-blocks
})(window);
