/* globals _ */

// eslint-disable-next-line no-extra-semi,wrap-iife
;(function RenderTemplateScope(nsp) {
  'use strict';

  var getTemplate = function(name) {
    return document.getElementById(name).innerHTML;
  };

  var RenderTemplate = function(name, params) {
    var rawTemplate = getTemplate(name);
    var template = _.template(rawTemplate);
    return template(params);
  };

  // eslint-disable-next-line no-param-reassign
  nsp.RenderTemplate = RenderTemplate;
})(window.utils);
