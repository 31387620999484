/* eslint-disable */
/* globals AnalyticsManager */
/* globals CollectMetrics */
/* globals DPComponentsList */
/* globals DPManager_CloseHandler */
/* globals DPPageInspector */
/* globals DPProject */
/* globals DPWorkBench */
/* globals DSWConfirmWin */
/* globals DSWindow */
/* globals Dejax */
/* globals Export */
/* globals Promise */
/* globals UXPElementHistory */
/* globals UXPLscacheHistoryStorage */
/* globals dpManager */
/* globals escape */

var DPManager = new MooTools.Class({

  name: 'DPManager',

  mLogmCounter: {},

  mCounter: 0,

  mShowStack: false,

  mWaiter: null,

  mWaiterQueue: [],

  // Czy wlaczony jest tryb collaboration (DPComent/Push)
  mCollaboration: false,

  /**
  * Czas po jakim znika komunikat podczas wywołania metody delayRemoveNotification
  */
  mRemoveNotificationDelay: 2000,

  mUserAvatar: null,

  mLocal: 0,

  mGroupUpdate: false,

  User: {},

  mZipChecker: false,

  mDashboardHeader: null,

  mDashboardToolbar: null,

  mToolbarsState: {
    sitemap: false,
    sidebar: false,
  },

  onboardingTips: null,

  mLoadPromise: null,

  mLoadResolve: function() {},

  Analytics: null,

  editorCheckInterval: null,

  DATA: {},

  emitter: false,

  initialize: function() {
    this.Export = new Export();
    this.mCloseHandler = new DPManager_CloseHandler();
    this.Analytics = AnalyticsManager;

    this.mLoadPromise = new Promise(function(resolve) {
      this.mLoadResolve = resolve;
    }.bind(this));
  },

  DataForCollaboration: {},
  DataForAnalytics: {},

  // this must be compatible with ajax() in preloader.tpl
  ajax: function ajax(options) {
    return window.fetch(this.decorateRequestURIWithExperimentalHost(options.url), {
      credentials: 'include',
      method: options.method,
      body: options.data,
      headers: Object.assign({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }, options.headers),
    }).then(function(response) { return response.clone().json(); });
  },

  setData: function(pData) {
    this.DATA = pData;

    this.setCollaboration(this.DATA.active_collaboration);

    this.dejax = new Dejax();

    this.dpWorkBench = new DPWorkBench();
    this.dpProject = new DPProject(this.DATA.project_data);

    this.dpMainToolbar = {
      refresh: function() {},

      pageWidget: {
        refresh: function() {},

        showOnStart: function() {},

        togglePageInspector: function() {},

        addEvent: function() {},
      },
    };

    this.dpPageInspector = new DPPageInspector(null);
    this.dpComponentsList = new DPComponentsList();
  },

  /**
   * Tworzy obiekty wszystkich potrzebnych klas
   * Metoda ta nie jest konstruktorem, ponieważ potrzebujemy referencji do samego DPManagera w tworzonych obietach
   * Referencja ta zostałaby zwrócona dopiero po zakończeniu metody, a więc już po inicjalizacji
   *
   * @param integer pIdProject numer aktualnie zaladowanego projektu - przechowywany pozniej w dpProject
   */
  load: function() {
    this.dpWorkBench = new DPWorkBench();
    this.dpWorkBench.setCurrentVersion(this.DATA.project_data.main_version);

    this.dpWorkBench.elementHistory = new UXPElementHistory(new UXPLscacheHistoryStorage(this.DATA.project_data.id_project));

    this.dpWorkBench.upload_root = this.DATA.upload_root;
    this.dpPageInspector.setPages(this.DATA.project_data);

    this.mLoadResolve();
    this.afterLoad();
  },

  loaded: function() {
    return this.mLoadPromise;
  },

  afterLoad: function() {
    if (dpManager.dpMainToolbar.pageWidget.showOnStart()) {
      dpManager.dpMainToolbar.pageWidget.togglePageInspector();
    }
  },

  attachUpgradeEvent: function(pButton, pCallback) {
    pButton.addEvent('click', function(e) {
      e.preventDefault();
      dpManager.showUpgrade(false, e.target.dataset.source, e.target.dataset.preselectPlan);

      if (typeof pCallback == 'function') {
        pCallback();
      }
    });
  },

  restoreSitemapStatus: function() {
    if (this.mToolbarsState.sitemap) {
      this.dpMainToolbar.pageWidget.togglePageInspector(true, false);
      // eslint-disable-next-line no-multi-spaces
      this.mToolbarsState.sitemap =  false;
    }
  },

  restoreSidebarStatus: function() {
    if (this.mToolbarsState.sidebar) {
      this.dpSidebar.getWidget(this.mToolbarsState.sidebar).show({ animate: false, fire: false, force: false });
      this.mToolbarsState.sidebar = false;
    }
  },

  setDashboardToolbar: function(pToolbar) {
    this.mDashboardToolbar = pToolbar;
  },

  getDashboardToolbar: function() {
    return this.mDashboardToolbar;
  },

  setDashboardHeader: function(pHeader) {
    this.mDashboardHeader = pHeader;
  },

  getDashboardHeader: function() {
    return this.mDashboardHeader;
  },

  setUserData: function(pData) {
    this.User = pData;
  },

  setCollaborationData: function(pData) {
    this.DataForCollaboration = pData;
  },

  getUserAvatar: function() {
    return this.User.avatar;
  },

  getUserId: function() {
    return this.DataForCollaboration.user_id;
  },

  /**
   * Generuje dla użytkownika unikalne id.
   *
   * jezeli ten sam user jest zalogowany przy pomocy tego samego loginu na tym samym komputerze to ma to samo unique_user_id
   * jeżeli 2 osoby są zalogowane przy pomocy TEGO SAMEGO loginu na 2 różnych maszynach to mają różne unique_user_id
   *
   * @return unknown
   */
  setUniqueUserId: function(pUniqueUserId) {
    this.mUniqueUserId = pUniqueUserId;
  },

  getUniqueUserId: function() {
    return this.mUniqueUserId;
  },

  setLocal: function(pValue) {
    this.mLocal = pValue;
  },

  isLocal: function() {
    return this.mLocal == '1';
  },

  showWaiter: function(pName, pInjectEl) {
    // eslint-disable-next-line one-var
    var el = pInjectEl || dpManager.dpWorkBench.getWorkBenchElMain(),
        offset = {
          left: -10,
          top: -10,
        };

    if (pInjectEl) {
      offset.left = 0;
      offset.top = 0;
    }

    if (this.mWaiter) {
      this.mWaiter.destroy();
    }

    this.mWaiter = new MooTools.Element('div', {
        styles: {
          opacity: 0.6,
          position: 'absolute',
          'z-index': 99999,
          background: '#FFF',
          left: 0,
          top: 0,
          display: 'none',
        },
      })
      .adopt(new MooTools.Element('img', {
        styles: {
          position: 'absolute',
          left: 0,
          top: 0,
          width: 32,
          height: 32,
        },
        src: '/p/waiter.gif',
      }));

    this.mWaiter.injectInside(el);

    this.mWaiter.setStyles({
      width: el.getSize().x + offset.left,
      height: el.getSize().y + offset.top,
    });

    this.mWaiter.getElement('img').setStyles({
      left: Math.round((el.getSize().x + offset.left - 32) / 2),
      top: Math.round((el.getSize().y + offset.top - 32) / 2),
    });

    this.mWaiter.setStyle('display', 'block');

    if (pName) {
      this.mWaiterQueue.push(pName);
    }

  },

  hideWaiter: function(pName) {
    if (!this.mWaiter) {
      return;
    }

    if (pName) {
      this.mWaiterQueue.erase(pName);
    }

    if (0 === this.mWaiterQueue.length) {
      this.mWaiter.setStyle('display', 'none');
    }
  },

  /**
   * This function is not being in use besideds one case,
   * which is not even displayed to the user.
   */
  setNotification: function(pText, pNotShowWaiter) {
    console.warn('DPManager.setNotification()', pText, pNotShowWaiter);
  },

  removeNotification: function(pWaiterQueueName) {
    this.hideWaiter(pWaiterQueueName);
  },

  /**
   * Tworzy DSWindow wraz ze wszystkimi elementami potrzebnymi do niego
   *
   * @access private
   * @param object pOptions {title: 'Window title', width: 300, height: 300, x: 'left', y: 'top', icon: ''crystal/16/action/delete.png', 'modal': false, 'ignore_resize': false, 'ignore_position': false, 'close_button': false, 'resizable': true, 'close_confirm': false, 'url': '/dms/SomeModule', 'on_escape_close': false, 'hide_on_close': false}
   *
   *
   */
  createDSWindow: function(pOptions) {

    var content_el = new MooTools.Element('div', {
      // eslint-disable-next-line key-spacing
      id:  'DSWContent' + MooTools.$random(0, 1000000000000),
    }).injectInside(document.body);

    if (!MooTools.$defined(pOptions.resizable)) {
      pOptions.resizable = true;
    }

    // Tryb "iframe" zawsze full_window wlaczone
    if (pOptions.url) {
      pOptions.full_window = true;
    }

    var dsw = new DSWindow('DPManager' + escape(pOptions.title.replace(/\W+/g, '')), pOptions.width, pOptions.height, pOptions.x, pOptions.y, pOptions.title, pOptions.icon, content_el, pOptions.modal, pOptions.ignore_position, pOptions.ignore_resize, pOptions.resizable, pOptions.close_button, pOptions.close_confirm, false, pOptions.full_window, pOptions.on_escape_close, pOptions.hide_on_close, pOptions.resize_limits, pOptions.minimize_button, pOptions.minimize, pOptions.position, pOptions.abTest);

    // Tworzy iframe i laduje content do niej
    if (pOptions.abTest) {
      dsw.ShowLoadIndicator();

      var frame_id = 'dswframe' + MooTools.$random(0, 1000000000000);

      dsw.mContentElement.set('html', '<iframe frameborder=0 border=0 id=' + frame_id + ' name=' + frame_id + ' width=100% height=100%></iframe>');
      var frame_el = $(frame_id);

      frame_el.setAttribute('style', 'background:transparent !important; background-image: none');
      frame_el.setProperty('allowTransparency', true);
      frame_el.addEvent('load', dsw.HideLoadIndicator.bind(dsw));
      frame_el.setProperty('src', pOptions.url);

      /*dsw.addEvent('OnResize', function() {
       });
       */
    } else if (pOptions.url) {
      dsw.ShowLoadIndicator();

      var frame_id = 'dswframe' + MooTools.$random(0, 1000000000000);

      dsw.mContentElement.set('html', '<iframe frameborder=0 border=0 id=' + frame_id + ' name=' + frame_id + ' width=100% height=100%></iframe>');
      var frame_el = $(frame_id);

      frame_el.setStyles({
        'background-color': 'transparent',
        'background-image': 'none',
      });
      frame_el.allowTransparency = true;
      frame_el.addEvent('load', dsw.HideLoadIndicator.bind(dsw));
      frame_el.setProperty('src', pOptions.url);

      /*dsw.addEvent('OnResize', function() {
       });
       */
    } else if (pOptions.html) {
      dsw.mContentElement.set('html', pOptions.html);
    } else if (pOptions.html_el) {
      pOptions.html_el.inject(dsw.mContentElement);
    }

    if (pOptions.onClose) {
      dsw.addEvent('OnClose', pOptions.onClose);
    }

    return dsw;
  },

  showStack: function() {
    this.mShowStack = true;
  },

  addStack: function(pName) {

    if (false === this.mShowStack) {
      return;
    }

    if (!MooTools.$defined(this.mLogmCounter[pName])) {
      this.mLogmCounter[pName] = 0;
    }

    console.info(pName + ' [' + (++this.mLogmCounter[pName]) + ', ' + (++this.mCounter) + ']');
  },

  setTimestampDiff: function(pServerTimestamp) {
    this.mTimestampDiff = new Date().getTime() - pServerTimestamp;
  },

  getTimestamp: function() {
    return new Date().getTime();
  },

  setPageTitle: function(projectName, iterationName) {
    const isOriginalProject = dpManager.dpProject.isOriginalProject();

    const isExperimental = this.experimental;

    if (isExperimental) {
      document.title = 'UXPin Experimental Mode';
    } else if (isOriginalProject) {
      document.title = `${projectName} - UXPin`;
    } else {
      const name = iterationName || dpManager.dpProject.getIterationName();
      document.title = `${projectName} | Iteration: ${name} - UXPin`;
    }
  },

  showAddCoWorkers: function() {
    window.location.href = '/dms/UserManagement/AddNewMembers/#/addMembers';
  },

  closeAddCoWorkers: function() {
    if (this.mAddCoWorkersWindow) {
      this.mAddCoWorkersWindow.Destroy();
      this.mAddCoWorkersWindow = false;
    }
  },

  showAddCollection: function(pIdCollection) {
    var uri = new URI('/dms/Collections/EditRecord/');

    if (pIdCollection) {
      uri.setData('id_collection', pIdCollection);
    }

    this.mAddCollectionWindow = this.createDSWindow({
      title: pIdCollection > 0 ? 'Rename project' : 'Add project',
      width: 645,
      height: 130,
      x: 'center',
      y: 'center',
      icon: '',
      modal: true,
      ignore_resize: true,
      ignore_position: true,
      close_button: true,
      resizable: false,
      close_confirm: false,
      url: uri.toString(),
      on_escape_close: false,
    });
  },

  showTeamList: function(pIdCollection, pRefresh) {
    var uri = new URI('/dms/Collections/TeamList/');

    if (pIdCollection) {
      uri.setData('id_collection', pIdCollection);
    }

    // if to refresh the page after submitting
    uri.setData('refresh', typeof pRefresh === 'undefined' || pRefresh ? '1' : '0');

    this.mAddCollectionWindow = this.createDSWindow({
      title: pIdCollection > 0 ? 'Add/remove people to this project' : 'Add coworker',
      width: 550,
      height: 400,
      x: 'center',
      y: 'center',
      icon: '',
      modal: true,
      ignore_resize: true,
      ignore_position: true,
      close_button: true,
      resizable: false,
      close_confirm: false,
      url: uri.toString(),
      on_escape_close: false,
    });
  },

  showEasyAddCollection: function(pIdCollection) {
    var uri = new URI('/dms/Collections/NewRecord/');

    if (pIdCollection) {
      uri.setData('id_collection', pIdCollection);
    }

    this.mAddCollectionWindow = this.createDSWindow({
      title: pIdCollection > 0 ? 'Project Settings' : 'Create project',
      width: 280,
      height: 130,
      x: 'center',
      y: 'center',
      icon: '',
      modal: true,
      ignore_resize: true,
      ignore_position: true,
      close_button: true,
      resizable: false,
      close_confirm: false,
      url: uri.toString(),
      on_escape_close: false,
    });
  },

  closeAddCollection: function() {
    this.mAddCollectionWindow && this.mAddCollectionWindow.Destroy();
  },

  /**
   * Wlacza lub wylacza tryb collaboration
   *
   * Jesli true to wlacza sie push/dpcomet oraz chat
   */
  setCollaboration: function(pState) {
    this.mCollaboration = pState;
  },

  /**
   * Pokazuje edytor danych konta
   */
  showAccountSettings: function(pIsOwner) {

    this.mAccountSettingsWindow = this.createDSWindow({
      title: 'Edit account',
      width: 500,
      height: 350,
      x: 'center',
      y: 'center',
      icon: '',
      modal: true,
      ignore_resize: true,
      ignore_position: true,
      close_button: true,
      resizable: false,
      close_confirm: false,
      url: '/dms/DPAccountSettings/EditRecord/',
      on_escape_close: false,
    });
  },

  closeAccountSettings: function() {

    if (!this.mAccountSettingsWindow) {
      return false;
    }

    return this.mAccountSettingsWindow.Destroy();
  },

  /**
   * Wyswietla okno do zarzadzania danymi billingowymi przez usera
   */
  showBillingInfo: function() {
    this.closeBillingInfo();

    this.mBillingInfoWindow = this.createDSWindow({
      title: 'Billing info',
      width: 500,
      height: 470,
      x: 'center',
      y: 'center',
      icon: '',
      modal: true,
      ignore_resize: true,
      ignore_position: true,
      close_button: true,
      resizable: false,
      close_confirm: false,
      url: '/dms/DPAccountSettings/BillingInfo/',
      on_escape_close: false,
    });
  },

  closeBillingInfo: function() {
    this.mBillingInfoWindow && this.mBillingInfoWindow.Destroy();
    this.mBillingInfoWindow = false;
  },

  showDesignViewsTos: function() {
    var width = 700;
    var height = 600;
    var url = '/dms/DPAccountSettings/DesignViewsTos/';

    this.mTosWindow = this.createDSWindow({
      title: 'Terms of Service',
      width: width,
      height: height,
      x: 'center',
      y: 'center',
      icon: '',
      modal: true,
      ignore_resize: true,
      ignore_position: true,
      close_button: true,
      resizable: false,
      close_confirm: false,
      url: url,
      on_escape_close: true,
    });
  },


  /**
   * Wyswietla okno do zarzadzania danymi billingowymi przez usera
   */
  showPaymentDetails: function(account) {
    // eslint-disable-next-line radix
    var idAccount = parseInt(account);
    var externalUrl = idAccount > 0 ? false : account;
    var width = 700;
    var height = 600;

    if (externalUrl) {
      width = 650;
      height = 740;
      url = externalUrl;
    } else {
      url = '/dms/DPAccountSettings/PaymentDetails/' + idAccount + '/';
    }

    this.mPaymentDetailsWindow = this.createDSWindow({
      title: 'Change billing information',
      width: width,
      height: height,
      x: 'center',
      y: 'center',
      icon: '',
      modal: true,
      ignore_resize: true,
      ignore_position: true,
      close_button: true,
      resizable: false,
      close_confirm: false,
      url: url,
      on_escape_close: false,
    });
  },

  closePaymentDetails: function() {
    this.mPaymentDetailsWindow && this.mPaymentDetailsWindow.Destroy();
    this.mPaymentDetailsWindow = false;
  },

  /**
   * Pokazuje edytor danych konta
   *
   * @param string pTitle nadpisuje tytul okna
   *
   */
  showNewUserPictures: function(pTitle) {

    this.mNewUserPicturesWindow = this.createDSWindow({
      title: pTitle || 'New pictures arrived!',
      width: 700,
      height: 500,
      x: 'center',
      y: 'center',
      icon: '',
      modal: true,
      ignore_resize: true,
      ignore_position: true,
      close_button: true,
      resizable: false,
      close_confirm: false,
      url: '/dms/DPNewUserPictures/ShowList/',
      on_escape_close: false,
    });
  },

  closeNewUserPictures: function() {

    if (!this.mNewUserPicturesWindow) {
      return false;
    }

    return this.mNewUserPicturesWindow.Destroy();
  },
  /**
   * Pokazuje edytor danych konta
   */
  showPicturePreview: function(pIdPicture) {

    this.mPicturePreviewWindow = this.createDSWindow({
      title: 'Picture details',
      width: 780,
      height: 650,
      x: 'center',
      y: 'center',
      icon: '',
      modal: true,
      ignore_resize: true,
      ignore_position: true,
      close_button: true,
      resizable: false,
      close_confirm: false,
      url: '/dms/DPNewUserPictures/ShowPreview/?id_image=' + pIdPicture,
      on_escape_close: false,
    });
  },

  closePicturePreview: function() {

    if (!this.mPicturePreviewWindow) {
      return false;
    }

    return this.mPicturePreviewWindow.Destroy();
  },

  showSetPassword: function() {
    this.mChangePasswordWindow = dpManager.createDSWindow({
      title: 'Set password',
      width: 500,
      height: 295,
      x: 'center',
      y: 'center',
      icon: '',
      modal: true,
      ignore_resize: true,
      ignore_position: true,
      close_button: true,
      resizable: false,
      close_confirm: false,
      url: '/dms/UserPreferences/SetPassword',
      on_escape_close: true,
    });
  },

  showChangePassword: function() {
    this.mChangePasswordWindow = dpManager.createDSWindow({
      title: 'Change password',
      width: 500,
      height: 375,
      x: 'center',
      y: 'center',
      icon: '',
      modal: true,
      ignore_resize: true,
      ignore_position: true,
      close_button: true,
      resizable: false,
      close_confirm: false,
      url: '/dms/UserPreferences/ChangePassword',
      on_escape_close: true,
    });
  },

  closeChangePassword: function() {
    this.mChangePasswordWindow.Destroy();
  },

  showChangeOwner: function(onSuccess) {
    if (onSuccess instanceof Function) {
      this.onOwnershipChanged = onSuccess;
    }

    this.mChangeOwnerWindow = dpManager.createDSWindow({
      title: 'Transfer ownership',
      width: 410,
      height: 180,
      x: 'center',
      y: 'center',
      icon: '',
      modal: true,
      ignore_resize: true,
      ignore_position: true,
      close_button: true,
      resizable: false,
      close_confirm: false,
      url: '/dms/dmsAccounts/ChangeOwnership',
      on_escape_close: true,
    });
  },

  closeChangeOwner: function(success) {
    if (success && this.onOwnershipChanged instanceof Function) {
      this.onOwnershipChanged();
    }

    this.mChangeOwnerWindow.Destroy();
  },

  showAccountClose: function() {
    this.mAccountCloseWindow = dpManager.createDSWindow({
      title: 'Close Account',
      width: 545,
      height: 500,
      x: 'center',
      y: 'center',
      icon: '',
      modal: true,
      ignore_resize: true,
      ignore_position: true,
      close_button: false,
      resizable: false,
      close_confirm: false,
      url: '/dms/UserPreferences/AccountClose',
      on_escape_close: true,
    });
  },

  closeAccountClose: function(reload) {
    this.mAccountCloseWindow.Destroy();
    if (reload) {
      window.location.reload();
    }
  },

  showImportInfo: function(pStatus) {
    var width = 700;
    var height = 360;

    if ('UNAVAILABLE' == pStatus) {
      width = 480;
      height = 165;
    }

    this.importPorn = dpManager.createDSWindow({
      title: 'UXPorn Import',
      width: width,
      height: height,
      x: 'center',
      y: 'center',
      icon: '',
      modal: true,
      ignore_resize: true,
      ignore_position: true,
      close_button: true,
      resizable: false,
      close_confirm: false,
      url: '/dms/Dashboard/ImportInfo',
      on_escape_close: true,
    });
  },

  getUpgradeWindowParams: function(pSize, pUrl) {
    'use strict';
    return {
      title: 'Upgrade account',
      width: pSize[0],
      height: pSize[1],
      x: 'center',
      y: 'center',
      icon: '',
      modal: true,
      ignore_resize: true,
      ignore_position: true,
      close_button: true,
      resizable: false,
      close_confirm: false,
      url: pUrl,
      on_escape_close: true,
    };
  },

  /**
   * Shows upgrade account modal with annual/monthly switch
   */
  showUpgrade: function(pAnnual, pSource, pPreselect, pCloseCallback) {
    var url = '/dms/DashboardModals/Upgrade/';
    var query = [];

    if (pSource) {
      query.push('source=' + pSource);
    }

    if (pPreselect) {
      query.push('preselect=' + pPreselect);
    }

    // little hack for upgrades from basic plan
    if (!pPreselect && pSource && pSource.test(/^basic\splan/)) {
      query.push('preselect=per_seat_pro_CURRENT');
    }

    this.closeUpgrade();
    var windowParams = this.getUpgradeWindowParams([1180, 800], url + '?' + query.join('&'));

    if ('function' === typeof pCloseCallback) {
      windowParams.onClose = pCloseCallback;
    }

    windowParams.abTest = dpManager.User.new_pricing;

    this.mBuyAccountWindow = dpManager.createDSWindow(windowParams);
  },

  showSpecUpgrade: function(pType, pAnnual, pSource, pPreselect, pCloseCallback) {
    'use strict';
    var url = '/dms/DashboardModals/SpecUpgrade/';
    var query = [];

    query.push('type=' + pType);

    if (pSource) {
      query.push('source=' + pSource);
    }

    if (pPreselect) {
      query.push('preselect=' + pPreselect);
    }

    // little hack for upgrades from basic plan
    if (!pPreselect && pSource && pSource.test(/^basic\splan/)) {
      query.push('preselect=per_seat_pro_CURRENT');
    }

    this.closeUpgrade();
    var windowParams = this.getUpgradeWindowParams([860, 480], url + '?' + query.join('&'));

    if ('function' === typeof pCloseCallback) {
      windowParams.onClose = pCloseCallback;
    }

    this.mBuyAccountWindow = dpManager.createDSWindow(windowParams);
  },

  showPromoSpecUpgrade: function(pAnnual, pSource, pPreselect, pCloseCallback) {
    var url = '/dms/DashboardModals/PromoSpecUpgrade';

    var windowParams = this.getUpgradeWindowParams([1180, 700], url);

    if ('function' === typeof pCloseCallback) {
      windowParams.onClose = pCloseCallback;
    }

    this.mPromoSpecUpgrade = dpManager.createDSWindow(windowParams);
  },

  /**
   * Shows promo modal
   */
  showLastChance: function() {
    var url = '/dms/Dashboard/LastChance/';
    this.mLastChanceWindow = dpManager.createDSWindow(this.getLastChanceWindowData(url));
  },

  showPromoOffer: function(pOffer) {
    var url = '/dms/DashboardModals/ReleasePromo/' + pOffer + '/';
    this.mLastChanceWindow = dpManager.createDSWindow(this.getLastChanceWindowData(url));
  },

  getLastChanceWindowData: function(pUrl) {
    return {
      title: 'Last Chance',
      width: 600,
      height: 587,
      x: 'center',
      y: 'center',
      icon: '',
      modal: true,
      ignore_resize: true,
      ignore_position: true,
      close_button: true,
      resizable: false,
      close_confirm: false,
      url: pUrl,
      on_escape_close: true,
      onClose: function() {
        if (window.onboardingDialog) {
          window.onboardingDialog.show();
        }
      },
    };
  },

  /**
   * Hides promo modal
   */
  closeLastChance: function() {
    if (this.mLastChanceWindow) {
      this.mLastChanceWindow.Destroy();
      this.mLastChanceWindow = false;
    }
  },

  /**
   * Hides upgrade account modal
   */
  closeUpgrade: function() {
    this.mBuyAccountWindow && this.mBuyAccountWindow.Destroy();
    this.mBuyAccountWindow = false;
  },

  showBuyAccount: function(pIsOwner, pIsUpgrade, pAnnual) {
    pIsOwner = undefined === pIsOwner || pIsOwner;

    this.closeAccountExpired();
    this.closeBuyAccount();

    this.mBuyAccountWindow = dpManager.createDSWindow({
      title: 'Upgrade account',
      width: pIsUpgrade ? 835 : 400,
      height: pIsUpgrade ? 364 : 388,
      x: 'center',
      y: 'center',
      icon: '',
      modal: true,
      ignore_resize: true,
      ignore_position: true,
      close_button: true,
      resizable: false,
      close_confirm: false,
      url: '/dms/Dashboard/Buy' + (pAnnual ? '/?annual=1' : ''),
      on_escape_close: true,
    });
  },

  closeBuyAccount: function() {
    this.mBuyAccountWindow && this.mBuyAccountWindow.Destroy();
    this.mBuyAccountWindow = false;
  },

  showParkAccount: function(pGoToCancel, pSource) {
    var url = '/dms/Dashboard/ParkAccount/?';
    url += 'cancel=' + (pGoToCancel ? 1 : 0);

    if (pSource) {
      url += '&source=' + pSource;
    }

    this.mParkAccountWindow = dpManager.createDSWindow({
      title: 'A pause in project?',
      width: 545,
      height: 512,
      x: 'center',
      y: 'center',
      icon: '',
      modal: true,
      ignore_resize: true,
      ignore_position: true,
      close_button: true,
      resizable: false,
      close_confirm: false,
      url: url,
      on_escape_close: true,
    });
  },

  closeParkAccount: function() {
    this.mParkAccountWindow && this.mParkAccountWindow.Destroy();
    this.mParkAccountWindow = false;
  },

  showSwitchAnnual: function(back) {
    this.closeSwitchAnnual();

    var url = '/dms/DPAccountSettings/SwitchAnnual/';

    if (back) {
      url += '?back=' + back;
    }

    this.mSwitchAnnualWindow = dpManager.createDSWindow({
      title: 'Switch to annual',
      width: 440,
      height: 380,
      x: 'center',
      y: 'center',
      icon: '',
      modal: true,
      ignore_resize: true,
      ignore_position: true,
      close_button: true,
      resizable: false,
      close_confirm: false,
      url: url,
      on_escape_close: true,
    });
  },

  closeSwitchAnnual: function() {
    if (this.mSwitchAnnualWindow) {
      this.mSwitchAnnualWindow.Destroy();
      this.mSwitchAnnualWindow = false;
    }
  },

  showVideoTutorial: function() {

    this.mVideoTutorialWindow = dpManager.createDSWindow({
      title: 'UXPin Overview in 4 Minutes',
      width: 640,
      height: 438,
      x: 'center',
      y: 'center',
      icon: '',
      modal: true,
      ignore_resize: true,
      ignore_position: true,
      close_button: true,
      resizable: false,
      close_confirm: false,
      url: '/dms/Desktop/VideoTutorial',
      on_escape_close: true,
    });
  },

  showLifeCycleVideo: function(pVideoId) {
    var metrics = new CollectMetrics();

    try {
      metrics.pushEvent('Lifecycle video ' + pVideoId + ' opened');
    // eslint-disable-next-line no-empty
    } catch (e) {}

    var title = 'UXpin';
    // eslint-disable-next-line default-case
    switch (pVideoId) {
      case 'd0':
        title = 'UXPin - Getting Started';
        break;

      case 'd1':
        title = 'Wireframing in UXPin';
        break;

      case 'd3':
        title = 'Prototyping in UXPin';
        break;

      case 'd5':
        title = 'Design Collaboration in UXPin';
        break;
    }

    this.mVideoTutorialWindow = dpManager.createDSWindow({
      title: title,
      width: 640,
      height: 438,
      x: 'center',
      y: 'center',
      icon: '',
      modal: true,
      ignore_resize: true,
      ignore_position: true,
      close_button: true,
      resizable: false,
      close_confirm: false,
      url: '/dms/Desktop/VideoTutorial/' + pVideoId,
      on_escape_close: true,
    });
  },

  spreadLoveForExtend: function() {
    this.mTweetForExtendWindow = dpManager.createDSWindow({
      title: '',
      width: 804,
      height: 580,
      x: 'center',
      y: 'center',
      icon: '',
      modal: true,
      ignore_resize: true,
      ignore_position: true,
      close_button: true,
      resizable: false,
      close_confirm: false,
      url: '/dms/Dashboard/TweetExtendsTrial',
      on_escape_close: true,
    });
  },

  showTemplates: function(pId) {
    this.mTemplateWindow = this.createDSWindow({
      title: 'Choose a Template',
      width: 700,
      height: 450,
      x: 'center',
      y: 'center',
      icon: '',
      modal: true,
      ignore_resize: true,
      ignore_position: true,
      close_button: true,
      resizable: false,
      close_confirm: false,
      url: '/dms/Dashboard/TemplatePicker/' + pId,
      on_escape_close: true,
    });
  },

  showTemplatesInProject: function(pId) {
    this.mTemplateWindow = this.createDSWindow({
      title: 'Choose a Template',
      width: 700,
      height: 319,
      x: 'center',
      y: 'center',
      icon: '',
      modal: true,
      ignore_resize: true,
      ignore_position: true,
      close_button: true,
      resizable: false,
      close_confirm: false,
      url: '/dms/Dashboard/TemplatePicker/' + pId,
      on_escape_close: true,
    });
  },

  closeTemplates: function() {
    this.mTemplateWindow && this.mTemplateWindow.Destroy();
  },

  closeImportInfo: function() {
    this.importPorn && this.importPorn.Destroy();
  },

  showAccountExpired: function(trial, annual, parked) {
    this.closeAccountExpired();
    var title;

    if (trial) {
      title = 'Buy subscription';
    } else if (parked) {
      title = 'Renew your subscription';
    } else {
      title = 'Account suspended';
    }

    trial = undefined === trial ? true : trial;

    this.mAccountExpiredtWindow = dpManager.createDSWindow({
      title: title,
      width: 418,
      height: trial || parked ? 520 : 610,
      x: 'center',
      y: 'center',
      icon: '',
      modal: true,
      ignore_resize: true,
      ignore_position: true,
      close_button: true,
      resizable: false,
      close_confirm: false,
      url: '/dms/Dashboard/Expired' + (annual ? '/?annual=1' : ''),
      on_escape_close: false,
    });
  },

  closeAccountExpired: function() {
    this.mAccountExpiredtWindow && this.mAccountExpiredtWindow.Destroy();
    this.mAccountExpiredtWindow = false;
  },

  setLeavingConfirmation: function(pMessage) {

    if (this.mLeavingConfirmationRef) {
      return;
    }

    this.mLeavingConfirmationRef = function(pE) {
      if (pE) {
        pE.returnValue = pMessage;
      }

      return pMessage;
    };

    window.onbeforeunload = this.mLeavingConfirmationRef;
  },

  removeLeavingConfirmation: function() {

    if (this.mLeavingConfirmationRef) {
      window.onbeforeunload = MooTools.$empty;
      this.mLeavingConfirmationRef = null;
    }

  },

  isCollaboration: function() {
    return (this.mCollaboration && typeof socket != 'undefined');
  },

  exportDownloadCheck: function() {
    this.downloadCookieCheckCounter = 0;
    this.cookieChecker = this.checkDownloadCookie.periodical(1500, this);
  },

  checkDownloadCookie: function() {
    var cookie = MooTools.Cookie.read('exportdownload');
    this.downloadCookieCheckCounter++;

    if (cookie == 0 || !cookie) {

      if (dpManager.dpProject.mShareProjectWindow) {
        dpManager.dpProject.mShareProjectWindow.Destroy();
      }

      DSWConfirmWin.Destroy(true);
      this.stopExportChecker();

    }

    if (this.downloadCookieCheckCounter > 40) {
      this.stopExportChecker();
    }
  },

  stopExportChecker: function() {
    clearInterval(this.cookieChecker);
    delete this.cookieChecker;
    this.downloadCookieCheckCounter = 0;
  },

  showMakeDefaultAccount: function(pIdAccount) {

    DSWindow.CreateConfirm('Do you want to set this account as the default?<br/> You can change this later in your account settings.', 'Set account as default', 'Yes, make it default', 'No', function() {

      new Dejax().newRequest({
        url: '/ajax/dmsAccounts/MakeDefault/',
        mode: 'cancel',
        data: pIdAccount,

      });
    // eslint-disable-next-line no-extra-bind
    }.bind(this));
  },

  updateZipProgress: function(pProgress) {
    var data = MooTools.JSON.decode(pProgress, true);

    if (!data) {
      dpManager.stopZipChecker();
      return;
    }

    if (data[0] == data[1]) {
      dpManager.stopZipChecker();
    }

    var progress;
    progress = 'Progress: ' + data[0] + '/' + data[1] + '.';

    if (data[0] / data[1] > 3 / 4) {
      progress += ' Download should start automatically.';
    }

    var progress_holder = document.getElementById('zip_progress');
    if (progress_holder) {
      progress_holder.innerHTML = progress;
    }
  },

  isSafari: function() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') != -1) {
      if (ua.indexOf('chrome') > -1) {
        return false;
      // eslint-disable-next-line keyword-spacing,no-else-return
      }else {
        return true;
      }
    }

    return false;
  },

  emit: function(event, data) {
    'use strict';
    if (this.canEmit()) {
      this.emitter.emit(event, data);
    }
  },

  addListener: function(event, callback) {
    'use strict';
    if (this.canEmit()) {
      this.emitter.on(event, callback);
    }

    return callback;
  },

  canEmit: function() {
    'use strict';
    return typeof this.emitter === 'object' && typeof this.emitter.emit === 'function';
  },

  decorateRequestURIWithExperimentalHost: function(uri) {
    const SET_ACTIVE_PAGE_URI = '/ajax/dmsDPPage/SetActivePage/';

    if (this.experimental && this.experimentalHost && SET_ACTIVE_PAGE_URI === uri) {
      return this.experimentalHost + uri;
    }

    return uri;
  },
});
