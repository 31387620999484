// eslint-disable-next-line no-extra-semi,wrap-iife
;(function UtilsNamespaceScope(global) {
  'use strict';

  if (typeof window.utils !== 'undefined') {
    throw new Error('utils namespace is not empty');
  }

  // eslint-disable-next-line no-param-reassign
  global.utils = {};

// eslint-disable-next-line padded-blocks
})(window);
