// ---------------------------------------------------------------------
// Browser Detection 
// 
// ---------------------------------------------------------------------
isMac = (navigator.appVersion.indexOf("Mac")!=-1) ? true : false; 
isDOM=(document.getElementById)?true:false
isOpera=isOpera5=window.opera && isDOM
isOpera6=isOpera && window.print
isOpera7=isOpera && navigator.userAgent.indexOf("Opera 7") > 0 || navigator.userAgent.indexOf("Opera/7") >= 0
isMSIE=isIE=document.all && document.all.item && !isOpera
isMSIE4 = ((document.all)&&(navigator.appVersion.indexOf("MSIE 4.")!=-1)) ? true : false; 
isMSIEmac = ((document.all)&&(isMac)) ? true : false;
isAnyMSIE = window.navigator.msPointerEnabled || /*@cc_on!@*/false
isEdge = navigator.appVersion.indexOf('Edge') > -1
isNC=navigator.appName=="Netscape"
isNC4=isNC && !isDOM
isNC6=isNC && isDOM



// ---------------------------------------------------------------------
// Safe loading into window.onload
//
// Body onload utility (supports multiple onload functions) 
// ---------------------------------------------------------------------
var gSafeOnload = new MooTools.Array(); 
var gSafeOnloadDelay = new MooTools.Array();

function SafeAddOnload(f) {
  SafeAddOnloadDelayed(f, 0);
}

function SafeAddOnloadDelayed(f, d) {
  if (isMSIEmac && isMSIE4)  // IE 4.5 blows out on testing window.onload 
  { 
    window.onload = SafeOnload; 
    gSafeOnload[gSafeOnload.length] = f;
    gSafeOnloadDelay[gSafeOnloadDelay.length] = d;
  } 
  else if (window.onload) 
  { 
    if (window.onload != SafeOnload) 
    { 
      gSafeOnload[0] = window.onload; 
      gSafeOnloadDelay[0] = 0;
      window.onload = SafeOnload; 
    } 
    gSafeOnload[gSafeOnload.length] = f; 
    gSafeOnloadDelay[gSafeOnloadDelay.length] = d;
  } 
  else {
    window.onload = f;
  }
} 

function SafeOnload() 
{ 
  var xyziilen = gSafeOnload.length;
  
  for (xyzii = 0; xyzii < xyziilen; xyzii++) {
    
    if (typeof(gSafeOnload[xyzii]) != 'function') {
      gSafeOnload[xyzii] = new MooTools.Function(gSafeOnload[xyzii]);
    }
    
    if (gSafeOnloadDelay[xyzii] > 0) {
      setTimeout(gSafeOnload[xyzii], gSafeOnloadDelay[xyzii]); 
    }
    else {
      gSafeOnload[xyzii]();
    }  
  }
} 

// ---------------------------------------------------------------------
// Set a layer opacity
// 
// ---------------------------------------------------------------------
function SetLayerOpacity(layername, opacitylevel) {
  if (!isDOM) {
    return false;
  }
  if(isMSIE) {
    document.getElementById(layername).style.filter='alpha(opacity='+opacitylevel+')';
  }
        if(isNC6) {
    document.getElementById(layername).style.MozOpacity = opacitylevel/100;
  }
}

// ---------------------------------------------------------------------
// Open new browser window
// 
// Params:
//
//   pWidth - popup width in px
//   pHeight - popup height in px
//   pXpos - x window position or one of: center, left, right
//   pYpos - y window position or one of: center, top, bottom
//   pURL - URL to open in window - optional
//   pWindowName - name of window - optional
//   pFocusAfterLoad - focus window after load
//   pAddon - turn on addons. give what addons you want to enable separated by ,
//            ie: scrollbars,hotkeys,location,menubar,resizable,titlebar,toolbar
// ---------------------------------------------------------------------
function PopupWindow(pWidth, pHeight, pXpos, pYpos, pURL, pWindowName, pFocusAfterLoad, pAddon) {

  // Position X
  if ('center' == pXpos) {
    pXpos = Math.round((screen.width -  pWidth) / 2);
  }
  else if('left' == pXpos) {
    pXpos = 0;
  }
  else if('right' == pXpos) {
    pXpos = screen.width -  pWidth;
  }
  
  // Position Y
  if ('center' == pYpos) {
    pYpos = Math.round((screen.height - pHeight) / 2);
  }
  else if('top' == pYpos) {
    pYpos = 0;
  }
  else if('bottom' == pYpos) {
    pYpos = screen.height - pHeight;
  }
  
  var addons = '' + 
    'hotkeys=no' + 
    ',location=no' + 
    ',menubar=no' + 
    ',resizable=no' + 
    ',scrollbars=no' + 
    ',status=no' + 
    ',titlebar=no' + 
    ',toolbar=no';
    
  // Replace addons to yes - ie. status=no -> status=yes
  if (pAddon) {
    splitaddons = pAddon.split(',');
    
    for(i = 0; i < splitaddons.length; i++) {
      regexp = new RegExp(splitaddons[i] + '=no', 'i');
      addons = addons.replace(regexp, splitaddons[i] + '=yes');
    }
  }
    
  windowhandle = window.open(
    pURL, pWindowName, 
    'height=' + pHeight +
    ',width=' + pWidth +
    ',screenX=' + pXpos + 
    ',screenY=' + pYpos + 
    ',left=' + pXpos +
    ',top=' + pYpos + 
    addons
  );
  
  // Raise window after window load
  if (true == pFocusAfterLoad) {
    windowhandle.focus();
  }
  
  //windowhandle.opener = window;
  
  return windowhandle;
}


function PopupWindowResize(pWidth, pHeight, pXpos, pYpos) {
  
  // Position X
  if ('center' == pXpos) {
    pXpos = Math.round((screen.width -  pWidth) / 2);
  }
  else if('left' == pXpos) {
    pXpos = 0;
  }
  else if('right' == pXpos) {
    pXpos = screen.width -  pWidth;
  }
  
  // Position Y
  if ('center' == pYpos) {
    pYpos = Math.round((screen.height - pHeight) / 2);
  }
  else if('top' == pYpos) {
    pYpos = 0;
  }
  else if('bottom' == pYpos) {
    pYpos = screen.height - pHeight;
  }
    
  window.resizeTo(pWidth, pHeight);
  
  if (pXpos > 0 && pYpos > 0) {
    window.moveTo(pXpos, pYpos);  
  }
}

// ---------------------------------------------------------------------
//  Loads dynamically script
// ---------------------------------------------------------------------
var gLoadedScripts = new MooTools.Array();

function LoadScript(pScriptSrc) {
  
  // Check if script was'nt loaded already
  if (true == in_array(pScriptSrc, gLoadedScripts)) {
    return;  
  }
  
  gLoadedScripts.push(pScriptSrc);
  
  script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = pScriptSrc;
  
  document.getElementsByTagName("head")[0].appendChild(script);
  
  return script;
}

function in_array(pNeedle, pHaystack) {
  for(var i in pHaystack) {
    if (pNeedle == pHaystack[i]) {
      return true;
    }  
  }  
  return false;
}



function print_r(pMixed, pReturn) {
  var tmp = new MooTools.String;
  
  for(var i in pMixed) {
    tmp += i + ' = ' + pMixed[i] + '\n';
  }
  
  if (true === pReturn) {
    return tmp;
  }
  else {
    alert(tmp);
  }
}


// ---------------------------------------------------------------------
// Add event to object
// 
// ---------------------------------------------------------------------
function AddEvent(pObject, pHandler, pFunction){

  if (!document.all && document.getElementById){
    pObject.setAttribute(pHandler, pFunction);
  }    
  
  //workaround for IE 5.x and IE 6
  if (document.all && document.getElementById){
    pObject[pHandler.toLowerCase()] = new MooTools.Function(pFunction);
  }
}


function GetWindowWidth() {
  return parseInt(
    (document.body && !isOpera && document.body.clientWidth)? document.body.clientWidth : (window.innerWidth || 0)
  );
};

function GetWindowHeight() {
  return parseInt(
    (document.body && !isOpera && document.body.clientHeight)? document.body.clientHeight : (window.innerHeight || 0)
  );
};

function CenterLayerOnPage(pLayer, pWidth, pHeight) {
  
  if (document.documentElement.clientWidth < pWidth) {
    leftPom = Math.round((document.documentElement.scrollWidth - pWidth)/2);
  }
  else {
    leftPom = Math.round((document.documentElement.clientWidth - pWidth)/2) + document.documentElement.scrollLeft;
  }
  
  if (document.documentElement.clientHeight < pHeight) {
    topPom = Math.round((document.documentElement.scrollHeight - pHeight)/2);
  }
  else {
    topPom = Math.round((document.documentElement.clientHeight - pHeight)/2) + document.documentElement.scrollTop;
  }
  
  $(pLayer).style.top = topPom + 'px';
  $(pLayer).style.left = leftPom  + 'px';
}

function ImgAlpha(pSrc, pWidth, pHeight, pIEScalingOff) {

  var img_el = new MooTools.Element('img');
  img_el.width = pWidth;
  img_el.height = pHeight;
  img_el.src = pSrc;
  
  return img_el;
}

// Mouse positions are stored in MouseX and MouseY
var MouseX = 0;
var MouseY = 0;

function MousePositionXYHandler(ev){
  if (isNC6) {
    MouseX = ev.pageX;
    MouseY = ev.pageY;
  }
  else if(isMSIE4) {
    MouseX = document.body.scrollLeft + window.event.x;
    MouseY = document.body.scrollTop + window.event.y;
  }
  else if(isMSIE && event && document.documentElement && document.body) {
    MouseX = event.clientX + document.documentElement.scrollLeft + document.body.scrollLeft;
    MouseY = event.clientY + document.documentElement.scrollTop + document.body.scrollTop;
  }
  else {
    MouseX = event.pageX ? event.pageX - window.pageXOffset : event.clientX;
    MouseY = event.pageY ? event.pageY - window.pageYOffset : event.clientY;
  }
}
// Mouse handler - stores mouse position in var MouseX and MouseY
document.onmousemove = MousePositionXYHandler;

var WindowPosition = {
  GetX: function() {
    if (isMSIE) {
      return window.screenLeft;
    }
    return window.screenX;
  },
  
  GetY: function() {
    if (isMSIE) {
      return window.screenTop;
    }
    return window.screenY;
  }
}

/*
funkcja kopiujaca obiekt
*/
copyObject = function(pObject) {
  var newObj = MooTools.$type(pObject) == 'array' ? [] : {};
  for (i in pObject){
    if (pObject[i] && MooTools.$type(pObject[i]) == "object") {
      newObj[i] = copyObject(pObject[i]);
    }
    else{
      newObj[i] = pObject[i]
    }
  }
  return newObj;
}

var customUploader = new MooTools.Class({
  
  'mEventsEl': null,
  'mFileboxEl': null,
  'mCustomButtonEl': null,
  'mStrictEl': null,
  'mEventAttached': false,
  'mEventsElementPosition': null,
  'mEventsElementSize': null,
  'mBrowserButtonPosition': 130,
  
  'mStart': null,
  
  'initialize': function(pEventsEl, pFileboxEl, pCustomButtonEl, pStrictEl){
    
    this.mEventAttached = false;
    
    if(!pStrictEl){
      pStrictEl = false;
    }
    
    this.mEventsEl = pEventsEl;
    this.mFileboxEl = pFileboxEl;
    this.mCustomButtonEl = pCustomButtonEl;
    this.mStrictEl = pStrictEl;
    
    this.mEventsElementPosition = pEventsEl.getPosition();
    this.mEventsElementSize = pEventsEl.getSize();
    
    this.setBrowserDifferencePosition();
    this.allocateElements();
    
    this.registerEvents();
    
  },
  
  'setBrowserDifferencePosition': function(){
  	if(MooTools.Browser.Engine.gecko){
  	  this.mBrowserButtonPosition = 180;
  	}
  	else if(MooTools.Browser.Engine.presto){
  	  this.mBrowserButtonPosition = 150;
  	}
  },
  
  'allocateElements': function(){
    
  	if(false == this.mStrictEl){
  	  this.mEventsEl.getParent().getParent().adopt(this.mFileboxEl);
  	}
  	else{
  	  this.mEventsEl.adopt(this.mFileboxEl);
  	}
    
    this.mFileboxEl.setStyles({
      'opacity': 0.01,
      'left': -400,
      'position': 'absolute'
    });    
   
    this.mFileboxEl.addClass('curDefault');
     
  },
  
  'attachEvent': function(){
  	this.mStart = this.mEventsEl.getPosition();
  	
  	if (false === this.mEventAttached) {
	  	this.mEventsEl.addEvent('mousemove', this.followButton.bindWithEvent(this));
			this.mEventAttached = true;
  	}
  	
  	this.mFileboxEl.setStyle('display', '');
  	
  },
  
  'detachEvent': function(){
    this.mEventsEl.removeEvents('mousemove');
    this.mEventAttached = false;
    this.mFileboxEl.setStyles({
      'display': 'none',
      'left': -400
    });
    
  },
  
  'followButton': function(pE){
    
    if(!this.mStart){
      this.mStart = {'x': 0, 'y': 0};
    }
    
		this.mFileboxEl.setStyles({
      'left': pE.page.x - this.mStart.x - this.mBrowserButtonPosition,
      'top': pE.page.y - this.mStart.y - 13
    });
    
    this.checkPosition(pE);
    
  },
  
  'checkPosition': function(pE) {
  	if (pE.page.x < this.mEventsElementPosition.x || pE.page.x > (this.mEventsElementPosition.x + this.mEventsElementSize.x) || pE.page.y < this.mEventsElementPosition.y || pE.page.y > (this.mEventsElementPosition.y + this.mEventsElementSize.y)){
  		this.detachEvent();
  	}
  	
  },
  
  'registerEvents': function(){
    this.mEventsEl.addEvent('mousemove', this.followButton.bindWithEvent(this));
    this.mFileboxEl.addEvent('mousemove', this.followButton.bindWithEvent(this));

    this.mEventsEl.addEvent('mouseenter', function(){
      this.attachEvent();
    }.bind(this));

    this.mFileboxEl.addEvent('mousedown', function(pE){
      this.mCustomButtonEl.fireEvent('mousedown', pE);
    }.bindWithEvent(this));
    
    document.addEvent('mouseup', function(pE){
      this.mCustomButtonEl.fireEvent('mouseup', pE);
    }.bindWithEvent(this));
    
  }
  
});

/**
*
*  MD5 (Message-Digest Algorithm)
*  http://www.webtoolkit.info/
*
**/
 
var MD5 = function (string) {
 
	function RotateLeft(lValue, iShiftBits) {
		return (lValue<<iShiftBits) | (lValue>>>(32-iShiftBits));
	}
 
	function AddUnsigned(lX,lY) {
		var lX4,lY4,lX8,lY8,lResult;
		lX8 = (lX & 0x80000000);
		lY8 = (lY & 0x80000000);
		lX4 = (lX & 0x40000000);
		lY4 = (lY & 0x40000000);
		lResult = (lX & 0x3FFFFFFF)+(lY & 0x3FFFFFFF);
		if (lX4 & lY4) {
			return (lResult ^ 0x80000000 ^ lX8 ^ lY8);
		}
		if (lX4 | lY4) {
			if (lResult & 0x40000000) {
				return (lResult ^ 0xC0000000 ^ lX8 ^ lY8);
			} else {
				return (lResult ^ 0x40000000 ^ lX8 ^ lY8);
			}
		} else {
			return (lResult ^ lX8 ^ lY8);
		}
 	}
 
 	function F(x,y,z) { return (x & y) | ((~x) & z); }
 	function G(x,y,z) { return (x & z) | (y & (~z)); }
 	function H(x,y,z) { return (x ^ y ^ z); }
	function I(x,y,z) { return (y ^ (x | (~z))); }
 
	function FF(a,b,c,d,x,s,ac) {
		a = AddUnsigned(a, AddUnsigned(AddUnsigned(F(b, c, d), x), ac));
		return AddUnsigned(RotateLeft(a, s), b);
	};
 
	function GG(a,b,c,d,x,s,ac) {
		a = AddUnsigned(a, AddUnsigned(AddUnsigned(G(b, c, d), x), ac));
		return AddUnsigned(RotateLeft(a, s), b);
	};
 
	function HH(a,b,c,d,x,s,ac) {
		a = AddUnsigned(a, AddUnsigned(AddUnsigned(H(b, c, d), x), ac));
		return AddUnsigned(RotateLeft(a, s), b);
	};
 
	function II(a,b,c,d,x,s,ac) {
		a = AddUnsigned(a, AddUnsigned(AddUnsigned(I(b, c, d), x), ac));
		return AddUnsigned(RotateLeft(a, s), b);
	};
 
	function ConvertToWordArray(string) {
		var lWordCount;
		var lMessageLength = string.length;
		var lNumberOfWords_temp1=lMessageLength + 8;
		var lNumberOfWords_temp2=(lNumberOfWords_temp1-(lNumberOfWords_temp1 % 64))/64;
		var lNumberOfWords = (lNumberOfWords_temp2+1)*16;
		var lWordArray=MooTools.Array(lNumberOfWords-1);
		var lBytePosition = 0;
		var lByteCount = 0;
		while ( lByteCount < lMessageLength ) {
			lWordCount = (lByteCount-(lByteCount % 4))/4;
			lBytePosition = (lByteCount % 4)*8;
			lWordArray[lWordCount] = (lWordArray[lWordCount] | (string.charCodeAt(lByteCount)<<lBytePosition));
			lByteCount++;
		}
		lWordCount = (lByteCount-(lByteCount % 4))/4;
		lBytePosition = (lByteCount % 4)*8;
		lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80<<lBytePosition);
		lWordArray[lNumberOfWords-2] = lMessageLength<<3;
		lWordArray[lNumberOfWords-1] = lMessageLength>>>29;
		return lWordArray;
	};
 
	function WordToHex(lValue) {
		var WordToHexValue="",WordToHexValue_temp="",lByte,lCount;
		for (lCount = 0;lCount<=3;lCount++) {
			lByte = (lValue>>>(lCount*8)) & 255;
			WordToHexValue_temp = "0" + lByte.toString(16);
			WordToHexValue = WordToHexValue + WordToHexValue_temp.substr(WordToHexValue_temp.length-2,2);
		}
		return WordToHexValue;
	};
 
	function Utf8Encode(string) {
		string = string.replace(/\r\n/g,"\n");
		var utftext = "";
 
		for (var n = 0; n < string.length; n++) {
 
			var c = string.charCodeAt(n);
 
			if (c < 128) {
				utftext += MooTools.String.fromCharCode(c);
			}
			else if((c > 127) && (c < 2048)) {
				utftext += MooTools.String.fromCharCode((c >> 6) | 192);
				utftext += MooTools.String.fromCharCode((c & 63) | 128);
			}
			else {
				utftext += MooTools.String.fromCharCode((c >> 12) | 224);
				utftext += MooTools.String.fromCharCode(((c >> 6) & 63) | 128);
				utftext += MooTools.String.fromCharCode((c & 63) | 128);
			}
 
		}
 
		return utftext;
	};
 
	var x=MooTools.Array();
	var k,AA,BB,CC,DD,a,b,c,d;
	var S11=7, S12=12, S13=17, S14=22;
	var S21=5, S22=9 , S23=14, S24=20;
	var S31=4, S32=11, S33=16, S34=23;
	var S41=6, S42=10, S43=15, S44=21;
 
	string = Utf8Encode(string);
 
	x = ConvertToWordArray(string);
 
	a = 0x67452301; b = 0xEFCDAB89; c = 0x98BADCFE; d = 0x10325476;
 
	for (k=0;k<x.length;k+=16) {
		AA=a; BB=b; CC=c; DD=d;
		a=FF(a,b,c,d,x[k+0], S11,0xD76AA478);
		d=FF(d,a,b,c,x[k+1], S12,0xE8C7B756);
		c=FF(c,d,a,b,x[k+2], S13,0x242070DB);
		b=FF(b,c,d,a,x[k+3], S14,0xC1BDCEEE);
		a=FF(a,b,c,d,x[k+4], S11,0xF57C0FAF);
		d=FF(d,a,b,c,x[k+5], S12,0x4787C62A);
		c=FF(c,d,a,b,x[k+6], S13,0xA8304613);
		b=FF(b,c,d,a,x[k+7], S14,0xFD469501);
		a=FF(a,b,c,d,x[k+8], S11,0x698098D8);
		d=FF(d,a,b,c,x[k+9], S12,0x8B44F7AF);
		c=FF(c,d,a,b,x[k+10],S13,0xFFFF5BB1);
		b=FF(b,c,d,a,x[k+11],S14,0x895CD7BE);
		a=FF(a,b,c,d,x[k+12],S11,0x6B901122);
		d=FF(d,a,b,c,x[k+13],S12,0xFD987193);
		c=FF(c,d,a,b,x[k+14],S13,0xA679438E);
		b=FF(b,c,d,a,x[k+15],S14,0x49B40821);
		a=GG(a,b,c,d,x[k+1], S21,0xF61E2562);
		d=GG(d,a,b,c,x[k+6], S22,0xC040B340);
		c=GG(c,d,a,b,x[k+11],S23,0x265E5A51);
		b=GG(b,c,d,a,x[k+0], S24,0xE9B6C7AA);
		a=GG(a,b,c,d,x[k+5], S21,0xD62F105D);
		d=GG(d,a,b,c,x[k+10],S22,0x2441453);
		c=GG(c,d,a,b,x[k+15],S23,0xD8A1E681);
		b=GG(b,c,d,a,x[k+4], S24,0xE7D3FBC8);
		a=GG(a,b,c,d,x[k+9], S21,0x21E1CDE6);
		d=GG(d,a,b,c,x[k+14],S22,0xC33707D6);
		c=GG(c,d,a,b,x[k+3], S23,0xF4D50D87);
		b=GG(b,c,d,a,x[k+8], S24,0x455A14ED);
		a=GG(a,b,c,d,x[k+13],S21,0xA9E3E905);
		d=GG(d,a,b,c,x[k+2], S22,0xFCEFA3F8);
		c=GG(c,d,a,b,x[k+7], S23,0x676F02D9);
		b=GG(b,c,d,a,x[k+12],S24,0x8D2A4C8A);
		a=HH(a,b,c,d,x[k+5], S31,0xFFFA3942);
		d=HH(d,a,b,c,x[k+8], S32,0x8771F681);
		c=HH(c,d,a,b,x[k+11],S33,0x6D9D6122);
		b=HH(b,c,d,a,x[k+14],S34,0xFDE5380C);
		a=HH(a,b,c,d,x[k+1], S31,0xA4BEEA44);
		d=HH(d,a,b,c,x[k+4], S32,0x4BDECFA9);
		c=HH(c,d,a,b,x[k+7], S33,0xF6BB4B60);
		b=HH(b,c,d,a,x[k+10],S34,0xBEBFBC70);
		a=HH(a,b,c,d,x[k+13],S31,0x289B7EC6);
		d=HH(d,a,b,c,x[k+0], S32,0xEAA127FA);
		c=HH(c,d,a,b,x[k+3], S33,0xD4EF3085);
		b=HH(b,c,d,a,x[k+6], S34,0x4881D05);
		a=HH(a,b,c,d,x[k+9], S31,0xD9D4D039);
		d=HH(d,a,b,c,x[k+12],S32,0xE6DB99E5);
		c=HH(c,d,a,b,x[k+15],S33,0x1FA27CF8);
		b=HH(b,c,d,a,x[k+2], S34,0xC4AC5665);
		a=II(a,b,c,d,x[k+0], S41,0xF4292244);
		d=II(d,a,b,c,x[k+7], S42,0x432AFF97);
		c=II(c,d,a,b,x[k+14],S43,0xAB9423A7);
		b=II(b,c,d,a,x[k+5], S44,0xFC93A039);
		a=II(a,b,c,d,x[k+12],S41,0x655B59C3);
		d=II(d,a,b,c,x[k+3], S42,0x8F0CCC92);
		c=II(c,d,a,b,x[k+10],S43,0xFFEFF47D);
		b=II(b,c,d,a,x[k+1], S44,0x85845DD1);
		a=II(a,b,c,d,x[k+8], S41,0x6FA87E4F);
		d=II(d,a,b,c,x[k+15],S42,0xFE2CE6E0);
		c=II(c,d,a,b,x[k+6], S43,0xA3014314);
		b=II(b,c,d,a,x[k+13],S44,0x4E0811A1);
		a=II(a,b,c,d,x[k+4], S41,0xF7537E82);
		d=II(d,a,b,c,x[k+11],S42,0xBD3AF235);
		c=II(c,d,a,b,x[k+2], S43,0x2AD7D2BB);
		b=II(b,c,d,a,x[k+9], S44,0xEB86D391);
		a=AddUnsigned(a,AA);
		b=AddUnsigned(b,BB);
		c=AddUnsigned(c,CC);
		d=AddUnsigned(d,DD);
	}
 
	var temp = WordToHex(a)+WordToHex(b)+WordToHex(c)+WordToHex(d);
 
	return temp.toLowerCase();
}
