/* eslint-disable brace-style */
/* eslint-disable comma-dangle */
/* eslint-disable eqeqeq */
/* eslint-disable indent */
/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
/* eslint-disable one-var */
/* eslint-disable padded-blocks */
/* eslint-disable quote-props */
/* eslint-disable semi */

// eslint-disable-next-line no-extra-semi,wrap-iife
;(function() {
  var zen = function(string) {
    var replace = {
        '\\[([a-zA-Z\\-]+)=([^\\]]+)\\]': function(parsed) {
          var props = {};
          props[parsed[1]] = parsed[2];

          return props;
        },
        '#([a-zA-Z][a-zA-Z0-9\\-_]*)': function(parsed) {
          return { 'id': parsed[1] }
        },
        '\\.([a-zA-Z][a-zA-Z0-9\\-_]*)': function(parsed) {
          return { 'class': parsed[1] }
        }
      },
      props = {};

    new MooTools.Hash(replace).each(function(parser, regexp) {
      var match;

      regexp = new RegExp(regexp);

      // eslint-disable-next-line keyword-spacing
      while(string.test(regexp)) {
        match = string.match(regexp);

        MooTools.$extend(props, parser(match));
        string = string.replace(match[0], '');
      }
    })

    return [string, props];
  };

  var microjungle = function(template) {

    // they just doing their job.
    // eslint-disable-next-line no-multi-spaces
    var monkeys =  function(what, who) {
      what.each(function(j) {

        if (!j) {
          return;
        }

        if (typeof j == 'string') {
          who.innerHTML += j;
        }
        else if (typeof j[0] == 'string') {
          // eslint-disable-next-line vars-on-top,camelcase
          var parsed_zen = zen(j.shift()),
              el = new MooTools.Element(parsed_zen[0]),
              attrs = {}.toString.call(j[0]) === '[object Object]' ? j.shift() : {};

          MooTools.$extend(attrs, parsed_zen[1]);

          // eslint-disable-next-line no-unused-expressions
          attrs && el.setProperties(attrs);
          who.appendChild(monkeys(j, el));
        }
        else if (j.nodeType === 1 || j.nodeType === 11) {
          who.appendChild(j);
        }
        else {
          monkeys(j, who);
        }
      })

      return who;
    };

    return monkeys(template, document.createDocumentFragment());
  };

  MooTools.Element.implement({

    microjungle: function(template) {
      this.appendChild(microjungle(template));

      return this;
    }
  })
  $.microjungle = microjungle

})();
