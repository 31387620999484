// eslint-disable-next-line no-extra-semi,wrap-iife
;(function DesktopAppDetectorScope(nsp) {
  'use strict';

  const isDesktopApp = function() {
    return navigator.userAgent.toLowerCase().indexOf('uxpin desktop') !== -1;
  };

  // eslint-disable-next-line no-param-reassign
  nsp.isDesktopApp = isDesktopApp;

// eslint-disable-next-line padded-blocks
})(window.utils);
