/* eslint-disable */
/* globals ExportItem */

// eslint-disable-next-line no-unused-vars
var Export = new MooTools.Class({

  name: 'Export',

  mInstanceId: null,

  mEl: null,

  mItems: [],

  mChecker: null,

  mCookieName: 'exportprocess',

  mClosed: null,


  initialize: function() {
    this.mInstanceId = 'export_id_' + (Math.random() * 1000);

    // sprawdza cookie czy sa jakies trwajace exporty
    var processes = this.readCookie();

    processes.each(function(pId) {
      var item = new ExportItem();
      item.setId(pId);

      try {
        item.restart();
        this.appendItem(item);
      }
      catch(e) {
        console.error(e.message);
        this.stop(pId);
      }
    }.bind(this));

    if(processes.length > 0) {
      this.startChecker();
    }
  },

  createEl: function() {
    if(null !== this.mEl) {
      return;
    }

    this.mEl = new MooTools.Element('div', {
      'id': this.mInstanceId,
      'class': 'export-container',
      'styles': {'display': 'none'}
    }).inject(document.getElement('body'));
  },

  showEl: function() {
    this.mEl.setStyle('display', 'block');
  },

  hideEl: function() {
    this.mEl.setStyle('display', 'none');
  },

  /**
   * Uruchamia nowy export.
   *
   * @param int pIdProject id_project projektu ktory ma zostac wyeksportowany
   * @param object pOptions opcje exportu przekazywane do tworzonej instancji ExportItem (id_project, type, menu)
   */
  start: function(pOptions) {
    // tworzymy instancje pojdynczego exportu
    // eslint-disable-next-line no-param-reassign
    pOptions.started_at = this.getTimestamp();
    var item = new ExportItem(pOptions);
    item.start();

    // don't start checker yet
    this.appendItem(item, false);
    this.showEl();
  },

  appendItem: function(pItem, pStartChecker) {
    this.addItem(pItem);

    if (typeof pStartChecker === 'undefined' || pStartChecker) {
      this.startChecker();
    }

    this.createEl();
    pItem.getEl().inject(this.mEl);
  },

  stop: function(pId, pStartChecker) {

    var item = this.getItemById(pId);
    var start_checker = pStartChecker === undefined || pStartChecker;
    // usun z tablicy
    if(item) {
      this.removeItem(item);
      item.close();
    }

    // usun z cookie
    var cookie = this.readCookie();

    if(-1 !== cookie.indexOf(pId)) {
      cookie.splice(cookie.indexOf(pId));
      this.setCookie(cookie);
    }

    // delete from RedisCache on the server
    new MooTools.Request({
      method: 'post',
      url: '/ajax/dmsExport/Finish/?__ajax_request=1',
      data: {'json': MooTools.JSON.encode({'id': pId})}
    }).send();

    // resetuj sprawdzanie
    if (start_checker) {
      this.startChecker();
    }

    // zamknij okienko jesli jest juz puste
    if(this.mItems.length === 0) {
      this.hideEl();
    }
  },

  addItem: function(pItem) {
    this.mItems.push(pItem);
  },

  getItemById: function(pId) {
    var item = this.mItems.filter(function(pItem) {
      return pItem.getId() === pId;
    })[0];

    return item;
  },

  removeItem: function(pItem) {
    this.mItems.splice(this.mItems.indexOf(pItem), 1);
  },

  startChecker: function() {

    // jesli checker juz chodzi to anulujemy go i rozpoczynamy kolejny
    if(null != this.mChecker) {
      this.stopChecker();
    }

    // jesli pusta tablica exportow, to nie rozpoczynamy sprawdzania
    if(0 == this.mItems.length) {
      return;
    }

    this.mChecker = new MooTools.Request({
      method: 'post',
      url: '/ajax/dmsExport/Check/?__ajax_request=1',
      initialDelay: 3000,
      delay: 1500,
      limit: 250000,
      mode: 'cancel',
      // eslint-disable-next-line space-before-blocks
      onSuccess: function(responseText){
        var response = MooTools.JSON.decode(responseText, true);

        if(responseText === "" || !response) {
          this.stopChecker();
          return;
        }

        var data = new MooTools.Hash(response);
        var exports = new MooTools.Hash(data.exports);
        var exports_showing = [];
        var exports_processing = 0;

        if(!data.status || !data.exports || exports.getLength() == 0) {
          // close all existing in js memory exports
          this.mItems.each(function(pItem) {
            this.stop(pItem.getId(), false);
          }.bind(this));

          this.stopChecker();
          return;
        }

        exports.each(function(pItem, pId) {
          var item = this.getItemById(pId);

          // jesli item nie istnieje w JS, to znaczy ze to jakas staroc i pozbywamy sie jej
          if(!item) {
            this.stop(pId);
            return;
          }

          // jezeli item jest done i jest starszy niz 24h, wywalamy go
          if(pItem.status == 4 && pItem.finished + 24*60*60 < Math.round((new Date()).getTime()/1000)) {
            this.stop(pId);
            return;
          }
          exports_showing.push(pId);

          // uzupelnij obiekt danymi po reloadzie strony
          if(item.mRestared) {
            item.setOptions(pItem);
            item.fillEl();
            item.mRestared = false;
          }

          // update progress
          item.setProgress(pItem.progress + "%");

          // jesli zakonczono
          if(pItem.status == 4 && pItem.url) {
            item.setUrl(pItem.url);
            item.setProgress('');
          }
          else if(pItem.status == 9) {
            item.setProgress('');
            item.setFailed();
          }
          else {
            // eslint-disable-next-line no-plusplus
            exports_processing++;
          }
        }.bind(this));

        // wyswietl okno z exportami jesli jest ukryte
        if(exports_showing.length > 0 && true !== this.mClosed) {
          this.showEl();
        }

        // zatrzymaj sprawdzanie, jesli wszystkie exporty sa zakonczone
        if(0 === exports_processing) {
          this.stopChecker();
        }

        // close all exports that are not listed on the server
        this.mItems.each(function(pItem) {
          if (pItem.isInitialized() && !exports_showing.contains(pItem.getId())) {
            this.stop(pItem.getId(), false);
          }
        }.bind(this));

      }.bind(this)
    }).startTimer();
  },

  stopChecker: function() {
    if(this.mChecker && this.mChecker.stopTimer) {
      this.mChecker.stopTimer();
      this.mChecker = null;
    }
  },

  readCookie: function() {
    return MooTools.JSON.decode(MooTools.Cookie.read(this.mCookieName)) || [];
  },

  setCookie: function(pData) {
    // eslint-disable-next-line key-spacing
    MooTools.Cookie.write(this.mCookieName, MooTools.JSON.encode(pData), {'path':'/'});
  },

  getTimestamp: function() {
    return Math.round((new Date()).getTime() / 1000); // in seconds
  }

});
