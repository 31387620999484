/* eslint-disable brace-style */
/* eslint-disable comma-dangle */
/* eslint-disable keyword-spacing */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-undef */
/* eslint-disable object-curly-spacing */
/* eslint-disable quote-props */
/* globals Dejax */
/* globals dpManager */

var UXPStack = new MooTools.Class({
  
  mStack: [],
  
  mStackSize: 20,
  
  
  // eslint-disable-next-line padded-blocks
  initialize: function(pStackSize) {
    
    if(pStackSize > 0) {
      this.setStackSize(pStackSize);
    }
    
    // nie uruchamiam automatycznego wysylania stacka po bledzie, gdyz zawaliloby nam to serwer
    // eslint-disable-next-line max-len
    // narazie trzeba umieszczac wywolanie wyslania w kopdzie w miejscach gdzie wystepuja konkretne bledy
    // window.addEvent('error', function() {
    //  this.report("On error stack send");
    // }.bind(this));
  },
          
  setStackSize: function(pSize) {
    this.mStackSize = pSize;
  },
  
  addToStack: function(pData) {
    this.mStack.push(pData);
    
    // usun stare wpisy
    if(this.mStack.length > this.mStackSize) {
      this.mStack.splice(0, this.mStack.length - this.mStackSize);
    }
  },
  
  getStack: function() {
    return this.mStack;
  },
  
  clearStack: function() {
    this.mStack = [];
  },
  
  report: function(pMessage, pParams) {
    var stack = this.getStack();
    // eslint-disable-next-line camelcase
    var stack_parsed = [];
    
    stack.each(function(pStacked) {
      var stacked = {'action': pStacked.action, 'params': []};
      
      // eslint-disable-next-line no-unused-expressions
      pStacked.params && pStacked.params.each(function(pParam) {
        try {
          MooTools.JSON.encode(pParam);
          stacked.params.push(pParam);
        }
        catch(e) {
          stacked.params.push('_callback_');
        }
      });
      
      stack_parsed.push(stacked);
    });
    
    new Dejax().send({
      mode: 'queue',
      url: '/ajax/dmsUXPStack/Report/',
      data: {
        'message': pMessage,
        'params': pParams,
        'stack': stack_parsed
      },
      onComplete: this.clearStack.bind(this)
    });
  }
  
}).extend({
  
  mInstance: null,
          
  instance: function() {
    // eslint-disable-next-line yoda
    if(null == UXPStack.mInstance) {
      UXPStack.mInstance = new UXPStack();
    }
    
    return UXPStack.mInstance;
  },     
  
  add: function(pAction, pParams) {
    UXPStack.instance().addToStack({
      'url': document.URL,
      'action': pAction,
      'params': pParams
    });
  },
          
  getClean: function() {
    var stack = dpManager.uxpStack.getStack();
    UXPStack.instance().clearStack();
    
    return stack;
  }, 
  
  error: function(pMessage, pParams) {
    UXPStack.instance().report(pMessage, pParams);
  },
  
  parseStackParamRecursive: function(pStackParam) {
    var param = null;
    
    if(typeof pStackParam === 'object') {
      param = {};
      
      new MooTools.Hash(pStackParam).each(function(pValue, pKey) {
        param[pKey] = UXPStack.parseStackParamRecursive(pValue);
      });
    }
    else if(typeof pStackParam === 'function') {
      param = '_callback_';
    }
    else {
      param = pStackParam;
    }
    
    return param;
  }
  
});
