/* eslint-disable comma-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable space-before-function-paren */
/* eslint-disable vars-on-top */
/* eslint-disable yoda */
/* globals _ */

// eslint-disable-next-line no-extra-semi,wrap-iife
;(function ModalDialogScope(global) {
  // eslint-disable-next-line lines-around-directive
  'use strict';
  // requires: underscore

  var ModalDialog = function(pOptions) {
    // defaults
    pOptions = _.defaults(pOptions || {}, {
      show_loader: true,
      type: 'ajax',
      animation_ms: 350,
      close_btn_selector: '.action-close-modal',
      callback: function() {},
      error: function() {}
    });

    this.bg_layer = null;
    this.modal_clbk = null;
    this.animation_ms = pOptions.animation_ms;

    if (pOptions.show_loader) {
      this.showLoader.call(this);
    }

    switch (pOptions.type.toLowerCase()) {
      case 'ajax':
        this.modal_clbk = {
          method: this.displayAjax,
          params: [
            {
              method: pOptions.method,
              url: pOptions.url,
              req_params: pOptions.req_params,
              close_btn_selector: pOptions.close_btn_selector
            },
            pOptions.callback,
            pOptions.error
          ]
        };
        break;

      default:
      case 'html':
        this.modal_clbk = {
          method: this.displayHtml,
          params: [
            {
              html: pOptions.html,
              close_btn_selector: pOptions.close_btn_selector
            }
          ]
        };
        break;
    }
  };

  ModalDialog.prototype.isSupportedAjaxMethod = function(pMethod) {
    return (pMethod && ['GET', 'POST'].indexOf(pMethod.toUpperCase()) > -1);
  };

  // eslint-disable-next-line consistent-return
  ModalDialog.prototype.getAjaxContent = function(pParams) {
    pParams = _.defaults(pParams || {}, {
      method: 'GET',
      url: null,
      params: '',
      success: null,
      error: null
    });

    if (!pParams.method || !this.isSupportedAjaxMethod(pParams.method) || !pParams.url) {
      return false;
    }

    if (pParams.success && 'function' !== typeof pParams.success) {
      return false;
    }

    if (pParams.error && 'function' !== typeof pParams.error) {
      return false;
    }

    var ajax = new XMLHttpRequest();

    switch (pParams.method.toUpperCase()) {
      case 'POST':
        ajax.setRequestHeader('Content-type', 'application/json');
        break;

      default:
      case 'GET':
        pParams.url += '/?' + pParams.params;
        break;
    }

    ajax.open(pParams.method, pParams.url, true);
    ajax.onreadystatechange = function () {
      // 4: request finished and response is ready
      if (ajax.readyState === 4) {
        if (ajax.status !== 200 && pParams.error) {
          pParams.error.apply(ModalDialog, [ajax.responseText, ajax.readyState, ajax.status]);
        }

        if (ajax.status === 200 && pParams.success) {
          pParams.success.apply(ModalDialog, [ajax.responseText, ajax.readyState, ajax.status]);
        }
      }
    };

    ajax.send(pParams.params);
  };

  ModalDialog.prototype.close = function() {
    if (this.bg_layer) {
      this.bg_layer.classList.remove('visible');

      // animation
      setTimeout(function removeBgLayerTimeout() {
        try {
          document.body.removeChild(this.bg_layer);
          this.bg_layer = false;
        // eslint-disable-next-line no-empty
        } catch (e) {}
      }.bind(this), this.animation_ms);
    }
  };

  ModalDialog.prototype._createBgLayer = function() {
    // Create background layer
    this.bg_layer = document.createElement('section');
    this.bg_layer.className = 'blend-layer visible spinner';
    document.body.appendChild(this.bg_layer);
  };

  ModalDialog.prototype.showLoader = function () {
    if (this.bg_layer) {
      this.close();
    }

    this._createBgLayer();
  };

  ModalDialog.prototype.show = function() {
    if ('function' === typeof this.modal_clbk.method) {
      if (!this.bg_layer) {
        this._createBgLayer();
      }

      this.modal_clbk.method.apply(this, this.modal_clbk.params);
    }
  };

  ModalDialog.prototype.appendContent = function(pContent, pOptions) {
    this.bg_layer.innerHTML = pContent;
    var closeBtn = this.bg_layer.querySelector(pOptions.close_btn_selector);

    if (closeBtn) {
      closeBtn.addEventListener('click', function(pE) {
        pE.preventDefault();

        this.close();
      }.bind(this));
    }

    setTimeout(function removeSpinnerTimeout() {
      this.bg_layer.classList.remove('spinner');
    }.bind(this), this.animation_ms);
  };

  ModalDialog.prototype.displayAjax = function(pData, pCallback, pErrorCb) {
    // eslint-disable-next-line camelcase
    var ajax_callback = function(pContent) {
      this.appendContent(pContent, pData);

      if ('function' === typeof pCallback) {
        pCallback.call(global);
      }
    }.bind(this);

    this.getAjaxContent({
      method: 'GET',
      url: pData.url,
      params: pData.req_params,
      success: ajax_callback,
      error: pErrorCb
    });
  };

  ModalDialog.prototype.displayHtml = function(pData) {
    if (pData && pData.html) {
      this.appendContent(pData.html, pData);
    }
  };

  global.ModalDialog = ModalDialog;
})(window);
