/* eslint-disable */
/**
 * Tworzy menu kontekstowe
 *
 * Użycie:
 *
 * - jako pData należy podać:
 *
 *  [
 *    // Pojedyncza pozycja menu
 *    {
 *      'text': 'New project',
 *      'icon': 'http://icons.local.com/famfamfam/16/application_add.png',  // opcjonalnie
 *      'action': function() {},
 *      'click': function() {},  // używane zamiennie z 'action', opcjonalnie
 *      'enabled': true, // opcjonalnie może być callback, domyślnie: true
 *      'visible': true, // opcjonalnie może być callback, domyślnie: true
 *      'shortcut': 'Ctrl+G', // opcjonalnie - pokazuje jaki skrót klawiszowy jest podpięty pod pozycję. Uwaga! Nie rejestruje skrótu automatycznie!
 *
 *      'href': 'javascript:dpManager.dpProject.editProject();void(0);', // juz nieuzywane (deprecated)
 *    },
 *
 *    // Wstawia separator
 *    {
 *      'separator': true
 *    },
 *
 *    // Wstawia pozycje wraz z podmenu
 *    {
 *      'text': 'Z submenu',
 *      ...
 *      'sub': [
 *        {
 *          'text': 'pozycja w submenu'
 *          ,..
 *        },
 *        ...
 *      ]
 *    }
 *  ]
 *
 *  @todo (dooshek) dodać do demods i zintegrować z nowym DSWindow
 */
var DSContextMenu = new MooTools.Class({

  name: 'DSContextMenu',

  mIsVisible: false,

  // Przechowuje referncje do submenu
  mSubs: [],

  // Aktualnie widoczne submenu
  mSubVisible: null,

  // Ustawiany w przypadku kiedy jest submenu
  mParent: null,

  initialize: function(pWidth, pData, pTopBorder, pParent) {

    this.mParent = pParent;

    this.mMainEl = new MooTools.Element('div', {
      'class': 'ContextMenu',
      'styles': {
        'position': 'absolute',
        'left': '0px',
        'top': '0px',
        'z-index': 1000000,
        'visibility': 'hidden'
      }
    }).inject(typeof DPWorkBench === 'object' ? DPWorkBench.getMainContainer() : document.body);

    if (true === pTopBorder) {
      new MooTools.Element('div', {'class': 'TopBorder'}).inject(this.mMainEl);
    }

    this.mWasSeparator = false;

    pData.each(function(pItem, pIndex, pArrayRef) {

      // jakas chujoza z IE zwiazana.. Iteruje o jeden element wiecej :/
      // @todo: sprawdzic czy nie ma lepszego sposobu na naprawienie ..
      // eslint-disable-next-line keyword-spacing
      if(!MooTools.$defined(pItem)) {
        return;
      }

      // Ostatni element jest separatorem - nie dowaj go
      if (pItem.separator && pIndex === pArrayRef.length - 1) {
        return;
      }

      // Nie pokazuje separatora jesli poprzedni element tez byl separatorem
      if (pItem.separator && this.mWasSeparator) {
        return;
      }

      // Tworzy nowy obiekt pojedynczego itema
      // eslint-disable-next-line no-use-before-define
      var item = new DSContextMenu_Item(pItem, this);
      item.addEvent('click', this.destroyParent.bind(this));

      this.mWasSeparator = pItem.separator;

      // Obsluguje submenu
      this.handleSubMenu(item.get(), item.getDomElement());

    }.bind(this));

    // Autodetekcja szerokosci
    if (!pWidth) {

      var width = this.mMainEl.measure(function() {
        return this.getStyle('width').toInt() + this.getStyle('border-left').toInt() + this.getStyle('border-right').toInt() + this.getStyle('padding-left').toInt() + this.getStyle('padding-right').toInt() + this.getStyle('margin-left').toInt() + this.getStyle('margin-right').toInt() + 1 // +1 dla firefoxa
      });

      this.mMainEl.setStyle('width', width);
    }
    else {
      this.mMainEl.setStyle('width', pWidth);
    }
  },

  // Obsluga submenu
  handleSubMenu: function(pData, pEl) {

    // Może wystąpić jeśli np. element ma się nie pokazywać
    if (!pEl) {
      return;
    }


    if ('array' === MooTools.$type(pData.sub)) {

      pData.sub = new DSContextMenu(null, pData.sub, null, this);

      this.mSubs.push(pData.sub);

      pEl.addEvent('mouseenter', function() {

        // Jesli jakieś inne submenu było widoczne to je chowa
        if (this.mSubVisible) {
          this.mSubVisible.Hide();
        }

        // Wspolrzedne w jakim ma sie pojawic submenu
        var coords = pEl.getCoordinates();
        pData.sub.Move(coords.left + coords.width - 4, coords.top - this.mMainEl.getStyle('border-top').toInt() - this.mMainEl.getStyle('margin-top').toInt() - this.mMainEl.getStyle('padding-top').toInt(), true);
        pData.sub.Show();

        this.mSubVisible = pData.sub;

      }.bind(this));

      pEl.addEvent('mouseleave', function() {

        if (this.mSubVisible === pData.sub) {
          return;
        }

        pData.sub.Hide();
      }.bind(this));

    }
    else {
      pEl.addEvent('mouseenter', function() {
        // Jesli jakieś submenu było widoczne to je chowa
        if (this.mSubVisible) {
          this.mSubVisible.Hide();
        }
      }.bind(this));
    }
  },

  Show: function() {
    this.mIsVisible = true;
    this.mMainEl.setStyles({
      'visibility': 'visible',
      'display': 'block',
      'z-index': 1000000
    });
  },

  Hide: function() {
    this.mIsVisible = false;
    this.mMainEl.setStyles({
      'visibility': 'hidden',
      'display': 'none'
    });

    this.mSubs.each(function(pItem) {
      pItem.Hide();
    });
  },

  Toggle: function() {
    if (true === this.mIsVisible) {
      this.Hide();
    }
    else {
      this.Show();
    }
  },

  /**
   * Przesuwa menu na pozycję pX, pY na stronie
   *
   * @param integer pX
   * @param integer pY
   * @param boolean pAutoPosition @see autoPosition()
   *
   */
  Move: function(pX, pY, pAutoPosition) {

    if (true === pAutoPosition) {
      var coords = this.getAutoPositionCoords(pX, pY);
      pX = coords.x;
      pY = coords.y;
    }

    this.mMainEl.setStyles({
      'left': pX,
      'top': pY
    });
  },

  /**
   * Pod uwagę brane są ograniczenia strony - menu może się przesunąć np. do góry jeśli będzie próbowało "wyjść" poza obszar strony
   *
   * Kalkulacja odbywa się również z uwzględnieniem ewentualnego menu parenta
   * - w takim przypadku trochę inaczej jest ustawiane
   *
   * @param integer pX inicjalna pozycja
   * @param integer pY inicjalna pozycja
   */
  getAutoPositionCoords: function(pX, pY) {

    // Oblicza prawdziwy rozmiar glownego elementu menu
    var size = this.getDomElementSize();

    // Automagically position menu layer if near screen edge (to not to cross screen)
    if (pX + size.width > window.getWidth().toInt()) {
      pX = window.getWidth().toInt() - size.width;

      // Jestem submenu - muszę uwzględnić rozmiary parenta
      if (this.mParent) {
        pX = window.getWidth().toInt() - size.width - this.getDomElementSize().width - (pX - this.mParent.getDomElement().getCoordinates().left);
      }

    }

    if (pY + size.height > window.getHeight().toInt()) {
      pY = window.getHeight().toInt() - size.height;
    }

    return {
      'x': pX,
      'y': pY
    };
  },

  destroy: function() {
    this.mMainEl.destroy();

    // Niszczy również submenu jesli jakies istniały
    this.mSubs.each(function(pItem) {
      pItem.destroy(false);
    });

    this.mSubVisible = null;
    this.mSubs = [];
    this.mParent = null;
  },

  /**
   * Wysyla sygnal do najwyzszego elementu (root) aby zamknal wszystkie
   *
   * Obsluguje sytuacje z wieloma menu zaglebionymi
   */
  destroyParent: function() {
    if (this.mParent) {
      this.mParent.destroyParent();
    }
    else {
      this.destroy();
    }
  },


  isVisible: function() {
    return this.mIsVisible;
  },

  /**
   * Pobiera wszystkie elementy DOM ktore sa dziecmi glownego elementu
   *
   * Uwaga! Pobiera rowniez elementy ktore sa elementami DOMa podmenu (rekurencyjnie w glab)
   *
   */
  getDomChildren: function() {

    var elements = [this.mMainEl].extend(this.mMainEl.getElements('*'));

    this.mSubs.each(function(pItem) {
      this.extend(pItem.getDomChildren());
    }.bind(elements));

    return elements;
  },

  getDomElement: function() {
    return this.mMainEl;
  },

  getDomElementSize: function() {
    return this.mMainEl.measure(function() {
      return {
        'width': this.getSize().x + this.getStyle('margin-left').toIntZero() + this.getStyle('margin-right').toIntZero() + this.getStyle('border-left').toIntZero() + this.getStyle('border-right').toIntZero() + this.getStyle('padding-left').toIntZero() + this.getStyle('padding-right').toIntZero(),
        'height': this.getSize().y + this.getStyle('margin-top').toIntZero() + this.getStyle('margin-bottom').toIntZero() + this.getStyle('border-top').toIntZero() + this.getStyle('border-bottom').toIntZero() + this.getStyle('padding-top').toIntZero() + this.getStyle('padding-bottom').toIntZero()
      }
    });
  }

});


/**
 * Pojedynczy element menu (item)
 */
// eslint-disable-next-line camelcase
var DSContextMenu_Item = new MooTools.Class({

  name: 'DSContextMenu_Item',

  Implements: [MooTools.Events],

  initialize: function(pData, pCXMenu) {

    this.mMainEl = pCXMenu.getDomElement();
    this.mData = pData;
    this.mCXMenu = pCXMenu;

    if ('function' === MooTools.$type(this.mData.visible)) {
      this.mData.visible = this.mData.visible(this.mCXMenu);
    }

    if (!this.mData || false === this.mData.visible) {
      return;
    }

    if (this.mData.separator) {
      this.mEl = new MooTools.Element('div', {
        'class': 'Separator'
      });
    }
    else {
      this.mEl = new MooTools.Element('a', {
        'class': 'Item',
        'href': this.mData.href
      });

      // Tworzy ikonkę
      // eslint-disable-next-line spaced-comment
      //this.createIcon(this.mData.icon).inject(this.mEl);

      // Tworzy element tekstowy
      this.createText(this.mData.text).inject(this.mEl);

      // Tworzy strzalke jesli sa submenu
      if (this.mData.sub) {
        this.createArrow().inject(this.mEl);
      }
      // Tworzy element shortcut
      else {
        this.createShortcut(this.mData.shortcut).inject(this.mEl);
      }

      // Obsluga callbackow
      if ('function' === MooTools.$type(this.mData.enabled)) {
        this.mData.enabled = this.mData.enabled(this.mCXMenu);
      }

      if (undefined !== this.mData.enabled && !this.mData.enabled) {
        this.mEl.addClass('disabled');
      }

      this.mData.click = this.mData.action || this.mData.click;

      if (this.mData.click) {

        this.mEl.addEvent('click', function(pEvent) {
          pEvent.stop();

          if (false !== this.mData.enabled) {
            this.mData.click(this.mCXMenu);
            this.fireEvent('click');
            this.destroy();
          }

        }.bind(this));
      }

    }

    this.mEl.inject(this.mMainEl);
  },

  createIcon: function(pIcon) {
    // Brak ikony - pokazuje transparentną
    pIcon = pIcon || 'crystal/16/transparent.png';

    // Sprawdza czy sciezka do ikonki zawiera ta ze skina - jesli nie to dodaje
    if (null === pIcon.match(DSWindowSkin.mIconBaseP)) {
      pIcon = DSWindowSkin.mIconBaseP + pIcon;
    }

    return new MooTools.Element('div', {
      'class': 'Icon',
      'styles': {
        'background-image': "url('" + pIcon + "')"
      }
    });
  },

  createText: function(pText) {
    return new MooTools.Element('div', {
      'class': 'Text',
      'html': pText
    });
  },

  createShortcut: function(pShortcut) {
    return new MooTools.Element('div', {
      'class': 'Shortcut',
      'html': pShortcut || ''
    });
  },

  createArrow: function() {
    return new MooTools.Element('div', {
      'class': 'Arrow'
    });
  },

  get: function() {
    return this.mData;
  },

  destroy: function() {
    this.mData = null;
    this.mCXMenu = null;
    this.mEl.destroy();
    this.mEl = null;
  },

  getDomElement: function() {
    return this.mEl;
  }

});
