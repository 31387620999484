/* eslint-disable */
/**
 * DS Toolbar - dodaje ikonkowego toolbara do DSWindow
 *
 *
 *
 * Przykładowe użycie:
 * myToolbar = new DSToolbar({
 *   'foo1': {
 *     'active': true,
 *     'hint': 'Upload new file',
 *     'icon': 'crystal/16/actions/filenew.png',
 *     'action': function() { },
 *   },
 *   'remove_file': {
 *     'active': false,
 *     'hint': 'Remove file',
 *     'icon': 'crystal/16/actions/delete.png',
 *     'action': function() {  },
 *   },
 *   'sep1': {
 *     'separator': true
 *   }
 * });
 */

var DSToolbar = new MooTools.Class({

  name: 'DSToolbar',

  /**
   * Definicje wszystkich ikonek, jakie mają się znaleźć w toolboxie
   *
   */
  mIcons: {},

  /**
   * Element zawierający całego toolboxa
   */
  mToolbarElContainer: null,

  /**
   * Element pomocniczy
   */
  mToolbarEl: null,

  /**
   * Konstruktor
   */
  // eslint-disable-next-line consistent-return
  initialize: function(pIconsConfig, pOptions, pShortcutReference){

    if(!pIconsConfig || !pOptions.container) {
      return false;
    }

    this.mIcons = pIconsConfig;

    this.mToolbarElContainer = new MooTools.Element('div', {'class': 'Toolbar'});
    this.mToolbarEl = new MooTools.Element('div').injectInside(this.mToolbarElContainer);

    if(pOptions.height) {
      this.mToolbarElContainer.setStyle('height', pOptions.height);
    }

    this.createButtons();

    pOptions.container.adopt(this.getDOMElement());

  },

  /**
   * Dodaje buttona do Toolboxa
   *
   * @param object pEl
   * @param string pName
   */
  addButton: function(pEl, pName){

    var type = this.mIcons[pName].type || 'button';

    this.mIcons[pName].elementLink = new MooTools.Element('a', {'class': 'ToolbarIcon', 'name': 'Toolbar_'+pName, 'title': pEl.hint}).injectInside(this.mToolbarEl);
    if(MooTools.Browser.Engine.trident){
      this.mIcons[pName].elementLink.setProperty('href', '#');
    }
    // eslint-disable-next-line max-len
    this.mIcons[pName].elementImage = ImgAlpha(DSWindowSkin.mIconBaseP + pEl.icon, 16, 16).injectInside(this.mIcons[pName].elementLink);
    this.mIcons[pName].elementImage.disableSelection();

    if(pEl.text_hint) {
      this.mIcons[pName].elementTextHint = new MooTools.Element('span').set('html', pEl.text_hint).injectInside(this.mIcons[pName].elementLink);
    }

    if('file' == this.mIcons[pName].type){
      this.mIcons[pName].filebox = new MooTools.Element('input', {
        'type': 'file',
        'name': 'Toolbar_'+pName+'_File',
        'styles': {
          'position': 'absolute',
          'opacity': '0.01',
          'right': '0px',
          'top': '0px'
        }
      });
      // eslint-disable-next-line no-shadow
      this.mIcons[pName].restoreFilebox = function(pEl){
        pEl.injectInside(this.mIcons[pName].elementLink);
        pEl.set('value', '');
      }.bind(this);
      this.mIcons[pName].filebox.injectInside(this.mIcons[pName].elementLink);
      this.mIcons[pName].filebox.addEvent('change', this.execute.bind(this, pName));
    }
    else{
      this.mIcons[pName].elementLink.addEvent('click', this.execute.bind(this, pName));
    }

    if('checkbox' == type){
      this.mIcons[pName].uncheck = function(){ this.uncheck(pName); }.bind(this, pName);
      if(this.mIcons[pName].checked == 1){
        this.mIcons[pName].elementLink.addClass('ButtonChecked');
        this.mIcons[pName].action();
      }
    }

  },

  /**
   * Wykonuje domyślne akcje
   */
  executeActions: function(){
    for(i in this.mIcons){
      if(this.mIcons[i].type == 'checkbox'){
        this.mIcons[i].checked = !this.mIcons[i].checked;
        this.mIcons[i].action(this.mIcons[i]);
        this.mIcons[i].checked = !this.mIcons[i].checked;
      }
    }
  },

  /**
   * Odznacza checkboxa
   */
  uncheck: function(pName){
    this.mIcons[pName].checked = 0;
    this.mIcons[pName].elementLink.removeClass('ButtonChecked');
  },

  /**
   * Dodaje separator do toolboxa
   */
  addSeparator: function(){
    new MooTools.Element('span', {'class': 'separator'}).injectInside(this.mToolbarEl);
  },

  /**
   * Tworzy buttony do toolboxa
   */
  createButtons: function(){
    for(i in this.mIcons){
      if(MooTools.$defined(this.mIcons[i].separator) && true == this.mIcons[i].separator){
        this.addSeparator();
      }
      else{
        this.addButton(this.mIcons[i], i);
        this.setButtonDefault(i);
      }
    }
  },

  /**
   * Wykonuje akcje po kliknięciu na button
   */
  execute: function(pName){
    if(true == this.mIcons[pName].active){
      this.mIcons[pName].action(this.mIcons[pName]);
    }
    if(this.mIcons[pName].type == 'checkbox'){

      if(this.mIcons[pName].group){
        // eslint-disable-next-line guard-for-in
        for(i in this.mIcons){
          pEl = this.mIcons[i];
          if(pEl.type == 'checkbox' && pEl.group == this.mIcons[pName].group){
            pEl.uncheck();
          }
        }
      }

      if(this.mIcons[pName].checked == 0){
        this.mIcons[pName].checked = 1;
        this.mIcons[pName].elementLink.addClass('ButtonChecked');
      }
      else{
        this.mIcons[pName].checked = 0;
        this.mIcons[pName].elementLink.removeClass('ButtonChecked');
      }
    }
  },

  /**
   * Pobiera element DOM toolboxa
   */
  getDOMElement: function(){
    return this.mToolbarElContainer;
  },

  /**
   * Ustawia button jako aktywny
   *
   * @param string pName
   */
  setButtonActive: function(pName){
    this.mIcons[pName].active = true;
    this.mIcons[pName].elementLink.setStyles({
      'opacity': '1'
    });
    this.mIcons[pName].elementLink.addClass('ButtonActive');
    var hiddenElement = this.mIcons[pName].elementLink.getElement('.DisableButton');
    if(hiddenElement){
      hiddenElement.destroy();
    }
  },

  /**
   * Ustawia wiele buttonów jako aktywne
   *
   * @param array pButton
   */
  setButtonsActive: function(pButtons){
    pButtons.each(this.setButtonActive.bind(this));
  },

  /**
   * Ustawia domyślny status buttona
   *
   * @param string pName
   */
  setButtonDefault: function(pName){

    if(!this.mIcons[pName]){
      return;
    }

    if(true == this.mIcons[pName].active){
      this.setButtonActive(pName);
    }
    else{
      this.setButtonInactive(pName);
    }
  },

  /**
   * Ustawia button jako nieaktywny
   *
   * @param string pName
   */
  setButtonInactive: function(pName){
    this.mIcons[pName].active = false;
    this.mIcons[pName].elementLink.setStyles({
      'opacity': '0.2'
    });
    this.mIcons[pName].elementLink.removeClass('ButtonActive');
    this.mIcons[pName].elementLink.adopt(new MooTools.Element('div', {'class': 'DisableButton'}));
  },

  /**
   * Ustawia wiele buttonów jako aktywne
   *
   * @param array pButton
   */
  setButtonsInactive: function(pButtons){
    pButtons.each(this.setButtonInactive.bind(this));
  },

  /**
   * Dodaje customowy widget do toolbara - może być to cokolwiek
   * co da się opakować w ładnego i zgrabnego diva :}
   *
   */
  addWidget: function(pContent, pWidth){

    if(!pWidth){
      pWidth = 'auto';
    }

    var widgetEl = new MooTools.Element('div', {
      'styles': {
        'float': 'left',
        'margin': '0px',
        'padding': '0px',
        'width': pWidth
      }
    }).set('html', pContent);

    this.mToolbarEl.adopt(widgetEl);
    return widgetEl;

  }

});


/**
 * Tworzy okienko DSWindow w bardziej przyjazny sposób
 */
function createDSWindow(pOptions) {

  // eslint-disable-next-line camelcase
  var content_el = new parent.parent.MooTools.Element('div', {
    'id': 'DSWContent' + MooTools.$random(0,1000000000000)
  }).injectInside(parent.parent.document.body);

  if (!MooTools.$defined(pOptions.resizable)) {
    pOptions.resizable = true;
  }

  // Tryb "iframe" zawsze full_window wlaczone
  if (pOptions.url) {
    pOptions.full_window = true;
  }

  var dsw = new parent.parent.DSWindow('DPManager' + escape(pOptions.title.replace(/\s+/, '')), pOptions.width, pOptions.height, pOptions.x, pOptions.y, pOptions.title , pOptions.icon, content_el, pOptions.modal, pOptions.ignore_position, pOptions.ignore_resize, pOptions.resizable, pOptions.close_button, pOptions.close_confirm, false, pOptions.full_window, pOptions.on_escape_close, pOptions.resize_limits);

  // Tworzy iframe i laduje content do niej
  if (pOptions.url) {
    dsw.ShowLoadIndicator();

    dsw.mContentElement.set('html', '<iframe frameborder=0 border=0 id=dswframe name=dswframe width=100% height=100%></iframe>');
    $('dswframe').setStyles({
      'background-color': 'transparent',
      'background-image': 'none'
    });
    $('dswframe').allowTransparency = true;
    $('dswframe').addEvent('load', dsw.HideLoadIndicator.bind(dsw));
    $('dswframe').setProperty('src', pOptions.url);

    // eslint-disable-next-line spaced-comment
    /*dsw.addEvent('OnResize', function() {
    });
    */
  }

  return dsw;
}
