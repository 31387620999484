// Skin configuration container
// eslint-disable-next-line max-len,no-unused-vars,comma-spacing
function DSWindowSkin(pImgP, pLeftTopW, pLeftTopH, pRightTopW, pRightTopH, pLeftBottomW, pLeftBottomH, pRightBottomW, pRightBottomH, pContentLeft, pContentTop, pContentRight, pContentBottom, pHeaderLeft, pHeaderTop, pHeaderRight,pHeaderHeight, pHeaderIcon, pHeaderIconX, pHeaderIconY, pNoIconHeaderLeft, pCloseBtnW, pCloseBtnH, pCloseBtnRight, pCloseBtnTop, pIconBaseP, pDeBug, pCloseWinMessage, pCloseWinHeader, pCloseWinYes, pCloseWinNo, pLeftFW, pTopFW, pRightExtendFW, pBottomExtendFW, pMinimizeBtnW, pMinimizeBtnH, pMinimizeBtnRight, pMinimizeBtnTop) {
  this.mLeftTopW = pLeftTopW;
  this.mLeftTopH = pLeftTopH;
  this.mRightTopW = pRightTopW;
  this.mRightTopH = pRightTopH;
  this.mLeftBottomW = pLeftBottomW;
  this.mLeftBottomH = pLeftBottomH;
  this.mRightBottomW = pRightBottomW;
  this.mRightBottomH = pRightBottomH;
  this.mContentLeft = pContentLeft;
  this.mContentTop = pContentTop;
  this.mContentRight = pContentRight;
  this.mContentBottom = pContentBottom;
  this.mHeaderLeft = pHeaderLeft;
  this.mHeaderTop = pHeaderTop;
  this.mHeaderRight = pHeaderRight;
  this.mHeaderHeight = pHeaderHeight;
  this.mHeaderIconX = pHeaderIconX;
  this.mHeaderIconY = pHeaderIconY;
  this.mNoIconHeaderLeft = pNoIconHeaderLeft;
  this.mCloseBtnW = pCloseBtnW;
  this.mCloseBtnH = pCloseBtnH;
  this.mCloseBtnRight = pCloseBtnRight;
  this.mCloseBtnTop = pCloseBtnTop;
  this.mMinimizeBtnW = pMinimizeBtnW;
  this.mMinimizeBtnH = pMinimizeBtnH;
  this.mMinimizeBtnRight = pMinimizeBtnRight;
  this.mMinimizeBtnTop = pMinimizeBtnTop;
  this.mImgP = pImgP;
  this.mIconBaseP = pIconBaseP;
  this.mDeBug = pDeBug;
  this.maxZIndex = 1;
  this.mCloseWinMessage = pCloseWinMessage;
  this.mCloseWinHeader = pCloseWinHeader;
  this.mCloseWinYes = pCloseWinYes;
  this.mCloseWinNo = pCloseWinNo;
  this.mLeftFW = pLeftFW;
  this.mTopFW = pTopFW;
  this.mRightExtendFW = pRightExtendFW;
  this.mBottomExtendFW = pBottomExtendFW;
}
