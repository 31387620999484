/* eslint-disable */

var DS_WINDOW_Z_INDEX = 2147483637;
var ActiveWindow = null;
var ConfirmMode = 0;

/**
 * Tworzy okno
 *
 * @events
 *   OnMove - po zakonczeniu przenoszenia okna
 *   OnResize - po zakonczeniu resisezowania okna
 *   OnClose - po zamknieciu okna (jesli bylo potwierdzenie [confirm] i nie zostalo potwierdzenie zamkniecia okna to event nie jest wykonywany)
 *   OnHide - po schowaniu okna
 */
var DSWindow = new MooTools.Class({

  name: 'DSWindow',

  Implements: MooTools.Events,

  initialize: function(pInstanceName, pWidth, pHeight, pX, pY, pHeaderText, pHeaderIcon, pContentElementID, pModal, pIgnorePosition, pIgnoreResize, pResizable, pCloseButton, pCloseConfirm, pReloadAfterClose, pFullContent, pEscapeKey, pHideOnClose, pResizeLimits, pMinimizeButton, pMinimize, pPosition, pAbTest) {

    if (pEscapeKey) {
      ActiveWindow = this;
    }

    this.mInstanceName = pInstanceName;

    this.mEscapeKey = pEscapeKey;

    this.mWinID = this.mInstanceName;
    this.mContentID = pContentElementID;
    this.mWidth = pWidth;
    this.mHeight = pHeight;
    this.mPosition = pPosition || 'absolute';
    this.mX = pX;
    this.mY = pY;
    this.mContentElement = $(this.mContentID);
    this.mHeaderText = pHeaderText;
    this.mHideOnClose = pHideOnClose;
    this.mHidden = false;
    this.mFrame = {};
    this.mAbTest = pAbTest;

    if (pHeaderIcon) {
      this.mHeaderIcon = DSWindowSkin.mIconBaseP + pHeaderIcon;
    }
    else {
      this.mHeaderIcon = false;
    }
    this.mModal = pModal;

    if (pFullContent) {
      this.mFullContent = true;
    }
    else {
      this.mFullContent = false;
    }

    if (pResizable) {
      this.mResizeBarOn = true;
    }
    else {
      this.mResizeBarOn = false;
    }

    this.mDeBug = DSWindowSkin.mDeBug;
    this.mLeftTopW = DSWindowSkin.mLeftTopW;
    this.mLeftTopH = DSWindowSkin.mLeftTopH;
    this.mRightTopW = DSWindowSkin.mRightTopW;
    this.mRightTopH = DSWindowSkin.mRightTopH;
    this.mLeftBottomW = DSWindowSkin.mLeftBottomW;
    this.mLeftBottomH = DSWindowSkin.mLeftBottomH;
    this.mRightBottomW = DSWindowSkin.mRightBottomW;
    this.mRightBottomH = DSWindowSkin.mRightBottomH;
    this.mContentLeft = DSWindowSkin.mContentLeft;
    this.mContentTop = DSWindowSkin.mContentTop;
    this.mContentRight = DSWindowSkin.mContentRight;
    this.mContentBottom = DSWindowSkin.mContentBottom;
    this.mHeaderLeft = DSWindowSkin.mHeaderLeft;
    this.mHeaderTop = DSWindowSkin.mHeaderTop;
    this.mHeaderRight = DSWindowSkin.mHeaderRight;
    this.mHeaderHeight = DSWindowSkin.mHeaderHeight;
    this.mHeaderIconX = DSWindowSkin.mHeaderIconX;
    this.mHeaderIconY = DSWindowSkin.mHeaderIconY;

    this.mNoIconHeaderLeft = DSWindowSkin.mNoIconHeaderLeft;
    this.mImgP = DSWindowSkin.mImgP;
    this.mIconBaseP = DSWindowSkin.mIconBaseP;
    this.mResizeLimits = pResizeLimits || {'x': 100, 'y': 100};

    this.mLeftFW = DSWindowSkin.mLeftFW;
    this.mTopFW = DSWindowSkin.mTopFW;
    this.mRightExtendFW = DSWindowSkin.mRightExtendFW;
    this.mBottomExtendFW = DSWindowSkin.mBottomExtendFW;

    this.mCloseButton = pCloseButton;
    this.mCloseConfirm = pCloseConfirm;
    this.mReloadAfterClose = pReloadAfterClose;
    this.mCloseBtnW = DSWindowSkin.mCloseBtnW;
    this.mCloseBtnH = DSWindowSkin.mCloseBtnH;
    this.mCloseBtnRight = DSWindowSkin.mCloseBtnRight;
    this.mCloseBtnTop = DSWindowSkin.mCloseBtnTop;

    this.mMinimizeButton = pMinimizeButton;
    this.mMinimizeBtnW = DSWindowSkin.mMinimizeBtnW;
    this.mMinimizeBtnH = DSWindowSkin.mMinimizeBtnH;
    this.mMinimizeBtnRight = DSWindowSkin.mMinimizeBtnRight;
    this.mMinimizeBtnTop = DSWindowSkin.mMinimizeBtnTop;
    this.mIsMinimized = false;

    this.CalculateWinParams();
    this.CreateInitialGraphic();
    this.CreateWindowGraphics();

    if (this.mModal) {
      this.ShowModalLayer();
    }

    if (MooTools.Cookie.read(this.mWinID + 'width') && MooTools.Cookie.read(this.mWinID + 'height') && true !== pIgnoreResize) {
      this.Resize(MooTools.Cookie.read(this.mWinID + 'width'), MooTools.Cookie.read(this.mWinID + 'height'));
    }

    if (MooTools.Cookie.read(this.mWinID + 'x') && MooTools.Cookie.read(this.mWinID + 'y') && true !== pIgnorePosition) {
      this.MoveTo(MooTools.Cookie.read(this.mWinID + 'x'), MooTools.Cookie.read(this.mWinID + 'y'));
    }
    this.BringToFront();

    if (true === pMinimize) {
      this.Minimize();
    }

  },

  CalculateWinParams: function() {

    // Maximize window size
    if ('max' == this.mWidth) {
      this.mContentWidth = window.getWidth() - this.mLeftTopW - this.mRightTopW - this.mContentRight;
      this.mWinWidth = window.getWidth();
      this.mResizeBarOn = false;
    }
    else {
      this.mContentWidth = parseInt(this.mWidth);
      this.mWinWidth = this.mContentWidth + this.mContentRight + this.mLeftTopW + this.mRightTopW;
    }

    if ('max' == this.mHeight) {
      this.mContentHeight = window.getHeight() - this.mLeftTopH - this.mLeftBottomH - this.mContentBottom;
      this.mWinHeight = window.getHeight();
      this.mResizeBarOn = false;
    }
    else {
      this.mContentHeight = parseInt(this.mHeight);
      this.mWinHeight = this.mContentHeight + this.mContentBottom + this.mLeftTopH + this.mLeftBottomH;
    }

    // Turn on window onresize method
    if ('max' == this.mWidth && 'max' == this.mHeight) {
      window.onresize = new MooTools.Function(this.mInstanceName + '.OnResize()');
    }

    // Window position
    if (this.mX == 'center') {
      this.mX = Math.round(window.getWidth() / 2) - Math.round(this.mWinWidth / 2) + window.getScrollLeft();
      if (this.mX < 0) {
        this.mX = 0;
      }
    }
    else if (this.mX == 'left') {
      this.mX = 0;
    }
    else if (this.mX == 'right') {
      this.mX = window.getWidth() - this.mWinWidth - 2;
    }
    else {
      this.mX = parseInt(this.mX);
    }

    if (this.mY == 'center') {
      this.mY = Math.round(window.getHeight() / 2) - Math.round(this.mWinHeight / 2) + window.getScrollTop();
      if (this.mY < 0) {
        this.mY = 0;
      }
    }
    else if (this.mY == 'top') {
      this.mY = 0;
    }
    else if (this.mY == 'bottom') {
      this.mY = window.getHeight() - this.mWinHeight;
    }
    else {
      this.mY = parseInt(this.mY);
    }

    /*
     Wywalone - wydaje sie niepotrzebne ale... (dooshek) Uniemozliwialo "pokazanie" okna przy x i y ujemnych (poza ekranem)
     if (this.mY < 0) {
     this.mY = 0;
     }

     if (this.mX < 0) {
     this.mX = 0;
     }
     */

  },

  CreateInitialGraphic: function() {

    // Create main window layer
    // eslint-disable-next-line no-multi-assign
    this.mMainElement = main_el = new MooTools.Element('div', {
      'styles': {
        'position': this.mPosition,
        'left': this.mX + 'px',
        'top': this.mY + 'px'
      },
      'id': this.mWinID,
      'class': this.mAbTest ? 'full-screen-view' : null,
    });

    // Background
    this.mBackgroundElement = new MooTools.Element('div', {
      'styles': {
        'position': 'absolute',
        'background': '#ffffff'
      }
    }).injectInside(main_el);

    // Moves content to proper position and resize
    this.mContentElement.setStyles({
      'position': 'absolute'
    }).injectInside(main_el);

    this.mBlendEl = new MooTools.Element('div', {
      'styles': {
        'position': 'absolute',
        'left': '0',
        'top': '0',
        'width': '100%',
        'height': '100%',
        'background': '#fff',
        'opacity': 0.001,
        'z-index': 60000,
        'display': 'none'
      }
    }).injectInside(main_el);

    // Text Header

    // No icon - move to left header
    if (this.mHeaderIcon) {
      var header_left = 0;
    }
    else {
      // eslint-disable-next-line no-redeclare
      var header_left = -this.mNoIconHeaderLeft;
    }

    this.mHeaderElement = new MooTools.Element('div', {
      'styles': {
        'position': 'absolute',
        'top': this.mHeaderTop + 'px',
        'left': this.mHeaderLeft + header_left + 'px'
      },
      'class': 'WindowHeaderText'
    }).injectInside(main_el);
    this.ChangeWindowTitle(this.mHeaderText);

    // Icon
    this.mIconElement = new MooTools.Element('img', {
      'styles': {
        'position': 'absolute',
        'width': 16,
        'height': 16,
        'left': this.mHeaderIconX + 'px',
        'top': this.mHeaderIconY + 'px'
      },
      'src': '/p/_.gif'
    }).injectInside(main_el);

    if (this.mHeaderIcon) {
      this.mIconElement.setProperty('src', this.mHeaderIcon);
    }

    // Create and attach drag layer to rezize window
    this.mResizeBarElement = new MooTools.Element('div', {
      'styles': {
        'position': 'absolute',
        'width': 24,
        'height': 24,
        'cursor': 'nw-resize',
        'display': 'none'
      }
    }).injectInside(document.body);


    // Drag bar
    this.mDragBarElement = new MooTools.Element('div', {
      'styles': {
        'position': 'absolute',
        'borderColor': '#666',
        'borderWidth': '1px',
        'borderStyle': 'dashed',
        'backgroundColor': '#ffffff',
        'opacity': 0
      },
      'class': 'drag',
      'id': this.mWinID + 'DB'
    }).injectInside(document.body);

    this.mDragBarElement.makeDraggable({

      limit: {
        'x': [0],
        'y': [0]
      },
      grid: 10,
      span: 1,

      onDrag: function(pEl, pEvent) {
        // Move window to proper position
        this.MoveTo(parseInt(pEl.getLeft()), parseInt(pEl.getTop()));
      }.bind(this),

      onBeforeStart: function(pEl) {
        // Bring to front
        this.BringToFront();
        this.mBlendEl.setStyle('display', 'block');

        pEl.removeClass('WindowDrag').addClass('WindowDragged');

      }.bind(this),

      onCancel: function(pEl) {
        // Recreate window
        this.mBlendEl.setStyle('display', 'none');
        this.CreateWindowGraphics(true);

        pEl.addClass('WindowDrag').removeClass('WindowDragged');
      }.bind(this),

      onStart: function(pEl) {
        this.mHeaderElement.className = 'WindowHeaderTextPick';
      }.bind(this),

      onComplete: function(pEl) {

        this.mHeaderElement.className = 'WindowHeaderText';

        // Recreate window
        this.CreateWindowGraphics(true);
        this.mBlendEl.setStyle('display', 'none');

        // Hide dragbar
        pEl.setStyles({
          'borderWidth': '1px',
          'backgroundColor': '#ffffff'
        });

        pEl.addClass('WindowDrag').removeClass('WindowDragged');

      }.bind(this)

    });

    // Pick up and drop resizbar element
    if (true === this.mResizeBarOn) {

      this.mResizeDrag = this.mResizeBarElement.makeDraggable({

        limit: {'x': [this.mX + this.mResizeLimits.x], 'y': [this.mY + this.mResizeLimits.y]},
        grid: 10,
        span: 1,

        onBeforeStart: function(pEl) {

          this.BringToFront();

          this.mBlendEl.setStyle('display', 'block');

          pEl.store('pick', {x: pEl.getLeft(), y: pEl.getTop()});
          pEl.store('window', {width: this.mWidth.toInt(), height: this.mHeight.toInt()});
          pEl.store('window_outer', {width: this.mWinWidth.toInt(), height: this.mWinHeight.toInt()});
          pEl.store('previous-z-index', pEl.getStyle('z-index'));

        }.bind(this),

        onCancel: function(pEl) {
          this.mBlendEl.setStyle('display', 'none');
        }.bind(this),

        onDrag: function(pEl) {

          var window = pEl.retrieve('window');
          var pick = pEl.retrieve('pick');

          var new_width = window.width + pEl.getLeft() - pick.x;
          var new_height = window.height + pEl.getTop() - pick.y;

          this.Resize(new_width, new_height);

        }.bind(this),

        onComplete: function(pEl) {
          var window = pEl.retrieve('window');
          var pick = pEl.retrieve('pick');

          this.mBlendEl.setStyle('display', 'none');

          var new_width = window.width + pEl.getLeft() - pick.x;
          var new_height = window.height + pEl.getTop() - pick.y;

          this.Resize(new_width, new_height);
        }.bind(this)

      });
    }

    // Loader
    this.mLoaderEl = new MooTools.Element('div', {'class': 'dswindow-loader'}).injectInside(this.mMainElement);

    // Attach main element to body
    main_el.injectInside(document.body);

  },

  SwapIcon: function(pIcon) {
    this.mHeaderIcon = DSWindowSkin.mIconBaseP + pIcon;
    this.mIconElement.setProperty('src', this.mHeaderIcon);
  },

  CreateWindowGraphics: function() {

    this.mMainElement.setStyles({
      'width': this.mWinWidth + 'px',
      'height': this.mWinHeight + 'px',
      'left': this.mX + 'px',
      'top': this.mY + 'px'
    });

    this.mBackgroundElement.setStyles({
      'left': this.mLeftTopW + 'px',
      'top': this.mLeftTopH + 'px',
      'width': this.mWinWidth - this.mLeftTopW - this.mRightTopW + 'px',
      'height': this.mWinHeight - this.mLeftTopH - this.mLeftBottomH + 'px'
    });

    if (false == this.mFullContent) {

      this.mContentElement.setStyles({
        'width': this.mContentWidth + 'px',
        'height': this.mContentHeight + 'px',
        'left': this.mContentLeft + 'px',
        'top': this.mContentTop + 'px',
        'visibility': 'visible'
      });

    }
    else {

      this.mContentElement.setStyles({
        'width': (parseInt(this.mContentWidth) + parseInt(this.mRightExtendFW)),
        'height': (parseInt(this.mContentHeight) + parseInt(this.mBottomExtendFW)),
        'left': this.mLeftFW + 'px',
        'top': this.mTopFW + 'px',
        'visibility': 'visible'
      });

    }

    if (true === this.mResizeBarOn) {
      this.mResizeBarElement.setStyles({
        'left': parseInt(this.mX) + parseInt(this.mWinWidth) - 24 + 'px',
        'top': parseInt(this.mY) + parseInt(this.mWinHeight) - 24 + 'px',
        'display': 'block'
      });

    }

    // Ramka okna - konfiguracja pozycji
    var frame_config = new MooTools.Hash({
      'lt': {
        'bg': '#f3f4f4',
        'width': this.mLeftTopW,
        'height': this.mLeftTopH,
        'left': 0,
        'top': 0
      },
      'rt': {
        'bg': '#f3f4f4',
        'width': this.mRightTopW,
        'height': this.mRightTopH,
        'left': this.mWinWidth - this.mRightTopW,
        'top': 0
      },
      'lb': {
        'bg': '#ffffff',
        'width': this.mLeftBottomW,
        'height': this.mLeftBottomH,
        'left': 0,
        'top': this.mWinHeight - this.mLeftBottomH
      },
      'rb': {
        'bg': '#ffffff',
        'width': this.mRightBottomW,
        'height': this.mRightBottomH,
        'left': this.mWinWidth - this.mRightBottomW,
        'top': this.mWinHeight - this.mLeftBottomH
      },
      't': {
        'bg': '#f3f4f4',
        'width': this.mWinWidth - this.mLeftTopW - this.mRightTopW,
        'height': this.mLeftTopH,
        'left': this.mLeftTopW,
        'top': 0
      },
      'b': {
        'bg': '#ffffff',
        'width': this.mWinWidth - this.mLeftBottomW - this.mRightBottomW,
        'height': this.mLeftBottomH,
        'left': this.mLeftBottomW,
        'top': this.mWinHeight - this.mLeftBottomH
      },
      'l': {
        'bg': '#ffffff',
        'width': this.mLeftTopW,
        'height': this.mWinHeight - this.mLeftTopH - this.mLeftBottomH,
        'left': 0,
        'top': this.mLeftTopH
      },
      'r': {
        'bg': '#ffffff',
        'width': this.mRightTopW,
        'height': this.mWinHeight - this.mRightTopH - this.mRightBottomH,
        'left': this.mWinWidth - this.mRightTopW,
        'top': this.mRightTopH
      }
    });

    // Odswieza tylko pozycje elementow
    if (this.mFrame.t) {
      frame_config.each(function(pItem, pKey) {

        this.mFrame[pKey].setStyles({
          'width': pItem.width,
          'height': pItem.height,
          'left': pItem.left,
          'top': pItem.top,
          'background': pItem.bg
        });

      }.bind(this));
    }
    // Tworzy elementy ramki okna
    else {
      frame_config.each(function(pItem, pKey) {

        this.mFrame[pKey] = this.CreateImageEl(pItem.bg, pItem.width, pItem.height, pItem.left, pItem.top).injectInside(this.mMainElement);

      }.bind(this));
    }

    // Close button
    if (this.mCloseButton) {

      if (this.mCloseButtonEl) {
        this.mCloseButtonEl.setStyles({
          'width': this.mCloseBtnW,
          'height': this.mCloseBtnH,
          'left': this.mWinWidth - this.mCloseBtnRight,
          'top': this.mCloseBtnTop
        });
      }
      else {
        this.mCloseButtonEl = this.CreateImageEl('url("' + this.mImgP + 'close_button.png")', this.mCloseBtnW, this.mCloseBtnH, this.mWinWidth - this.mCloseBtnRight, this.mCloseBtnTop, 'CloseButtonDiv')
          .setStyle('cursor', 'pointer')
          .addEvent('click', function(pE) {
            pE.stop();
            this.HideOnClose() || this.Destroy();
          }.bind(this));
        this.mMainElement.adopt(this.mCloseButtonEl);
      }
    }

    // Minimize button
    if (this.mMinimizeButton) {

      var delta = this.mCloseButton ? this.mCloseBtnW + 5 : 0;

      if (this.mMinimizeButtonEl) {
        this.mMinimizeButtonEl.setStyles({
          'width': this.mMinimizeBtnW,
          'height': this.mMinimizeBtnH,
          'left': this.mWinWidth - this.mCloseBtnRight - delta,
          'top': this.mMinimizeBtnTop
        });
      }
      else {
        this.mMinimizeButtonEl = this.CreateImageEl('url("' + this.mImgP + 'minimize_button.png")', this.mMinimizeBtnW, this.mMinimizeBtnH, this.mWinWidth - this.mCloseBtnRight - delta, this.mMinimizeBtnTop, 'MinimizeButtonDiv')
          .setStyle('cursor', 'pointer')
          .addEvent('click', this.ToggleMinMax.bind(this));
        this.mMainElement.adopt(this.mMinimizeButtonEl);
      }
    }

    // Resize and move dragbar element
    var dragbar_width = this.mWinWidth - this.mHeaderRight;

    if (this.mCloseButton) {
      dragbar_width -= this.mCloseBtnRight;
    }

    if (this.mMinimizeButton) {
      dragbar_width -= this.mMinimizeBtnRight;
    }

    var dragbar_left = this.mX + this.mContentLeft;

    this.mDragBarElement.setStyles({
      'width': dragbar_width,
      'height': this.mHeaderHeight,
      'left': dragbar_left,
      'top': parseInt(this.mY + 5),
      'visibility': 'visible'
    });

    // Centruje loadera
    this.mLoaderEl.position({
      relativeTo: this.mMainElement,
      position: 'center',
      edge: 'center'
    });

  },

  Resize: function(pWidth, pHeight) {
    this.mWidth = pWidth;
    this.mHeight = pHeight;
    MooTools.Cookie.write(this.mWinID + 'width', this.mWidth);
    MooTools.Cookie.write(this.mWinID + 'height', this.mHeight);

    this.CalculateWinParams();
    this.CreateWindowGraphics();

    if (this.mResizeDrag) {
      this.mResizeDrag.limit = {'x': [this.mX + this.mResizeLimits.x], 'y': [this.mY + this.mResizeLimits.y]};
    }

    this.fireEvent('OnResize');
  },

  Reload: function() {
    location.href = location.href.replace(/#.*$/, '');
  },

  MoveTo: function(pX, pY) {
    this.mX = pX;
    this.mY = pY;

    MooTools.Cookie.write(this.mWinID + 'x', this.mX);
    MooTools.Cookie.write(this.mWinID + 'y', this.mY);

    this.CalculateWinParams();
    this.CreateWindowGraphics();

    this.fireEvent('OnMove');
  },

  BringToFront: function() {
    // eslint-disable-next-line spaced-comment
    //Zeby miec pewnosc ze jest na gorze...
    DSWindowSkin.maxZIndex = DS_WINDOW_Z_INDEX;

    if (this.mModalElement) {
      this.mModalElement.setStyle('z-index', DSWindowSkin.maxZIndex);
    }
    this.mMainElement.setStyle('z-index', DSWindowSkin.maxZIndex + 1);
    this.mHeaderElement.setStyle('z-index', DSWindowSkin.maxZIndex + 2);
    this.mContentElement.setStyle('z-index', DSWindowSkin.maxZIndex + 2);
    if (this.mIconElement) {
      this.mIconElement.setStyle('z-index', DSWindowSkin.maxZIndex + 2);
    }
    if (this.mResizeBarElement) {
      this.mResizeBarElement.setStyle('z-index', DSWindowSkin.maxZIndex + 2);
    }
    this.mDragBarElement.setStyle('z-index', DSWindowSkin.maxZIndex + 3);
  },

  Hide: function() {
    if (this.mModalElement) {
      this.mModalElement.setStyle('display', 'none');
      window.removeEvent('resize', this.mFitModalLayerEventRef);
    }
    this.mMainElement.setStyle('display', 'none');
    this.mDragBarElement.setStyle('display', 'none');
    if (this.mResizeBarElement) {
      this.mResizeBarElement.setStyle('display', 'none');
    }
    this.mHidden = true;
  },

  HideOnClose: function() {
    if (true === this.mHideOnClose) {
      this.Hide();
      this.fireEvent('OnHide');
      return true;
    }
    return false;
  },

  Show: function() {
    if (this.mModalElement) {
      this.mModalElement.setStyle('display', 'block');
      window.removeEvent('resize', this.mFitModalLayerEventRef);
      this.mFitModalLayerEventRef = this.FitModalLayer.bind(this);
      window.addEvent('resize', this.mFitModalLayerEventRef);
    }
    this.mMainElement.setStyle('display', 'block');
    this.mDragBarElement.setStyle('display', 'block');
    if (this.mResizeBarElement) {
      this.mResizeBarElement.setStyle('display', 'block');
    }
    this.mHidden = false;
  },

  /**
   * Maxymalizuje lub minimalizuje okno
   */
  ToggleMinMax: function() {

    if (this.mIsMinimized) {
      return this.Maximize();
    }

    return this.Minimize();
  },

  IsMinimized: function() {
    return this.mIsMinimized;
  },

  Minimize: function() {

    this.mMinimizeButtonEl.setStyle('background-image', 'url("' + this.mImgP + 'maximize_button.png")');

    this.mHeightBeforeMinimize = this.mHeight;

    this.mContentElement.hide();
    this.mBackgroundElement.hide();

    this.mFrame.rb.hide();
    this.mFrame.b.hide();
    this.mFrame.lb.hide();
    this.mFrame.l.hide();
    this.mFrame.r.hide();
    this.mFrame.rt.setStyle('background', '#f3f4f4');
    this.mFrame.lt.setStyle('background', '#f3f4f4');
    this.mFrame.t.setStyle('background', '#f3f4f4');

    this.Resize(this.mWidth, -10);
    this.mResizeBarElement.hide();

    this.fireEvent('OnMinimize');
    this.mIsMinimized = true;

    this.MaximizeEvent = this.Maximize.bind(this);
    this.mDragBarElement.addEvent('mouseup', this.MaximizeEvent);
    this.mDragBarElement.addClass('WindowDragBarMinimized');
  },

  Maximize: function() {

    this.mMinimizeButtonEl.setStyle('background-image', 'url("' + this.mImgP + 'minimize_button.png")');

    this.mContentElement.show();
    this.mBackgroundElement.show();
    this.mResizeBarElement.show();

    this.mFrame.rb.show();
    this.mFrame.b.show();
    this.mFrame.lb.show();
    this.mFrame.l.show();
    this.mFrame.r.show();
    this.mFrame.rt.setStyle('background', '#f3f4f4');
    this.mFrame.lt.setStyle('background', '#f3f4f4');
    this.mFrame.t.setStyle('background', '#f3f4f4');

    this.Resize(this.mWidth, this.mHeightBeforeMinimize);

    this.fireEvent('OnMaximize');
    this.mIsMinimized = false;

    this.mDragBarElement.removeEvent('mouseup', this.MaximizeEvent);
    this.mDragBarElement.removeClass('WindowDragBarMinimized');
  },

  /**
   * Mruga okienkiem zmieniając opacity
   *
   * W przypadku jesli okno nie jest zmiminalizowane to mruga tylko headerem i ikonką
   *
   */
  Blink: function() {
    if (this.mIsMinimized) {
      this.mMainElement.set('tween', {
        'duration': 300,
        'link': 'ignore'
      }).tween('opacity', 0.2).get('tween').chain(this.mMainElement.tween.pass(['opacity', 1], this.mMainElement));
    }
    else {
      this.mHeaderElement.set('tween', {
        'duration': 300,
        'link': 'ignore'
      }).tween('opacity', 0).get('tween').chain(this.mHeaderElement.tween.pass(['opacity', 1], this.mHeaderElement));
      this.mIconElement.set('tween', {
        'duration': 300,
        'link': 'ignore'
      }).tween('opacity', 0).get('tween').chain(this.mIconElement.tween.pass(['opacity', 1], this.mIconElement));
    }
  },

  isHidden: function() {
    return this.mHidden;
  },

  /**
   * Pokazuje loadera okna
   *
   * Zastosowane rozwiazanie uzywa CSS3 ale do poprawnego dzialania potrzebuje
   * jednak JS (delay przy chowaniu) aby ladnie dzialala animacja
   *
   * @param boolean pInstant (default: false) true - powoduje pokazanie się kręcioły natychmiast zamiast po chwili
   */
  ShowLoadIndicator: function(pInstant) {
    MooTools.$clear(this.mLoaderTimer);

    if (true === pInstant) {
      this.mLoaderEl
        .removeClass('end')
        .addClass('instant')
        .setStyle('display', 'block');
    }
    else {

      this.mLoaderEl.addClass.delay(10, this.mLoaderEl, ['active']);

      this.mLoaderEl
        .removeClass('end')
        .setStyle('display', 'block')
    }
  },

  /**
   * Chowa loader okna
   */
  HideLoadIndicator: function() {

    this.mLoaderEl
      .addClass('end')
      .removeClass('active')
      .removeClass('instant')

    // Po 2s klasa active jest wylaczana a element chowany tak aby nie przeszkadzal uzytkownikowi
    MooTools.$clear(this.mLoaderTimer);
    this.mLoaderTimer = function() {
      this.mLoaderEl.setStyle('display', 'none');
    }.delay(2000, this);

  },

  Destroy: function(pForceClose) {
    this.fireEvent('beforeClose');

    if (true !== pForceClose && true === this.mCloseConfirm) {
      ConfirmMode = 1;

      var yes_callback = function() {
        this.Destroy(true);

        if (this.mReloadAfterClose) {
          location.href = location.href.split('#')[0];
        }
      }.bind(this);

      DSWindow.CreateConfirm(DSWindowSkin.mCloseWinMessage, DSWindowSkin.mCloseWinHeader, DSWindowSkin.mCloseWinYes, DSWindowSkin.mCloseWinNo, yes_callback);
      return false;
    }

    this.mImgP = null;
    this.mIconBaseP = null;
    this.mIconElement = null;
    this.mContentID = null;
    this.mPNGAElements = null;
    this.mBackgroundElement = null;
    this.mCloseButtonEl = null;

    if (this.mModalElement) {
      window.removeEvent('resize', this.mFitModalLayerEventRef);
      this.mModalElement.destroy();
      this.mModalElement = null;
    }

    if (this.mDragBarElement) {
      this.mDragBarElement.destroy();
      this.mDragBarElement = null;
    }

    if (this.mResizeBarElement) {
      this.mResizeBarElement.destroy();
      this.mResizeBarElement = null;
    }

    if (this.mHeaderElement) {
      this.mHeaderElement.destroy();
      this.mHeaderElement = null;
    }

    if (this.mContentElement) {
      this.mContentElement.destroy();
      this.mContentElement = null;
    }

    if (this.mMainElement) {
      this.mMainElement.destroy();
      this.mMainElement = null;
    }

    this.fireEvent('OnClose');

    this.removeEvents();

    ConfirmMode = 0;

    if (ActiveWindow == this) {
      ActiveWindow = null;
    }

    return true;
  },

  CreateModalLayer: function() {
    if (!this.mModalElement) {
      this.mModalElement = new MooTools.Element('div', {
        'styles': {
          'position': 'absolute',
          'opacity': 0.7,
          'left': '0px',
          'top': '0px',
          'width': window.getWidth() + 'px',
          'height': window.getHeight() + 'px',
          'visibility': 'hidden'
        },
        'class': 'WindowModalLay',
        'id': this.mWinID + 'MDL'
      }).injectInside(document.body);
    }
  },

  ShowModalLayer: function() {
    this.CreateModalLayer();
    this.BringToFront();
    this.FitModalLayer();

    this.mFitModalLayerEventRef = this.FitModalLayer.bind(this);
    window.addEvent('resize', this.mFitModalLayerEventRef);
  },

  /**
   * Dostosowuje wartwe modalna tak aby pokrywala cale okno
   */
  FitModalLayer: function() {
    this.mModalElement.setStyles({
      'visibility': 'visible',
      'width': window.getWidth(),
      'height': window.getScrollHeight()
    });
  },

  OnResize: function() {
    this.Resize('max', 'max');
  },

  CreateImageEl: function(pBg, pWidth, pHeight, pLeft, pTop, pId) {
    var div_el = new MooTools.Element('div', {
      'styles': {
        'position': 'absolute',
        'left': pLeft + 'px',
        'top': pTop + 'px',
        'width': pWidth + 'px',
        'height': pHeight + 'px',
        'background': pBg
      }
    });

    return div_el;
  },

  ChangeWindowTitle: function(pTitle) {
    this.mHeaderElement.set('html', pTitle);
  }

});

var DSWConfirmWin;
// eslint-disable-next-line no-shadow
(function(DSWindow) {
  // eslint-disable-next-line space-before-function-paren
  var MPP_DIALOGS = (function () {
    function getTemplate(templateId) {
      return _.template(document.getElementById(templateId).innerHTML);
    }

    /**
     * @param {String} templateId
     * @param {Object} options
     * @constructor
     */
    function Dialog(templateId, options) {
      this.options = _.defaults({
        template: getTemplate(templateId),
      }, options);

      this.init();
    }

    Dialog.prototype.init = function() {
      this.el = new MooTools.Element('section', {
        'class': 'modal-box',
        html: this.options.template(this.options),
      }).inject(document.body);

      this.addEvents();
      setTimeout(this.show.bind(this), 0);
    };

    Dialog.prototype.show = function() {
      this.el.addClass('visible');
    };

    Dialog.prototype.hide = function() {
      this.el.removeClass('visible');
      this.removeEvents();

      setTimeout(function() {
        this.el.dispose();
      }.bind(this), 100);
    };

    Dialog.prototype.addEvents = function() {
      _.each(this.el.querySelectorAll('[data-action="cancel"]'), function(element) {
        element.addEvent('click', function(event) {
          event.stop();
          this.hide();
          if (_.isFunction(this.options.noAction)) {
            this.options.noAction();
          } else {
            eval(this.options.noAction); // legacy
          }
        }.bind(this));
      }.bind(this));

      _.each(this.el.querySelectorAll('[data-action="close"]'), function(element) {
        element.addEvent('click', function(event) {
          event.stop();
          this.hide();

          if (_.isFunction(this.options.closeAction)) {
            this.options.closeAction();
          }
        }.bind(this));
      }.bind(this));

      _.each(this.el.querySelectorAll('[data-action="success"]'), function(element) {
        element.addEvent('click', function(event) {
          event.stop();
          this.hide();

          if (_.isFunction(this.options.yesAction)) {
            this.options.yesAction();
          } else {
            eval(this.options.yesAction); // legacy
          }
        }.bind(this));
      }.bind(this));

      this.refHandleKeyDown = this.handleKeyDown.bind(this);
      document.addEvent('keydown', this.refHandleKeyDown);
    };

    Dialog.prototype.handleKeyDown = function(event) {
      if (event.code === 13) {
        event.stop();
        if (_.isFunction(this.options.yesAction)) {
          this.options.yesAction();
        } else {
          eval(this.options.yesAction); // legacy
        }
      }

      this.hide();
    };

    Dialog.prototype.removeEvents = function() {
      document.removeEvent('keydown', this.hide);
      document.removeEvent('keydown', this.refHandleKeyDown);
    };

    // legacy compatibility
    Dialog.prototype.Destroy = function() {
      this.hide();
    };

    return Object.freeze({
      confirm: function(params) {
        DSWConfirmWin = new Dialog('dswindow-confirm-template-uxpin-30', params);
      },
      alert: function(params) {
        DSWConfirmWin = new Dialog('dswindow-alert-template-uxpin-30', params);
      },
    });
  })();

  DSWindow.CreateConfirm = function(pConfirmText, pConfirmHeaderText, pYesText, pNoText, pYesAction, pNoAction, pTextTop, pIcon) {
    if (typeof window.dpManager !== 'undefined' && dpManager.isEditor) {
      MPP_DIALOGS.confirm({
        text: pConfirmText,
        header: pConfirmHeaderText,
        yesText: pYesText,
        noText: pNoText,
        yesAction: pYesAction,
        noAction: pNoAction,
      });
      return;
    }

    var content_el = new MooTools.Element('div', {
      'id': 'DSWConfirmWinContent',
      'styles': {
        'position': 'absolute',
        'width': 370
      },
      'html': '\
      <div class="DSWindowConfirmText">' + pConfirmText + '</div>\
      <div id="DSWBtnsArea">\
        <a href="" class="styled-button gray" id="DSWConfirmButtonYes" style="float:left">' + pYesText + '</a>\
        <a href="" id="DSWConfirmButtonNo" style="float:left">' + pNoText + '</a>\
      </div>\
    '
    }).inject(document.body);

    $('DSWConfirmButtonYes').addEvent('click', function(pEvent) {
      pEvent.stop();
      DSWConfirmWin.Destroy(true);

      if ('function' == MooTools.$type(pYesAction)) {
        pYesAction();
      }
      else {
        // dla zachowania kompatybilnosci wstecz
        eval(pYesAction);
      }

    });

    $('DSWConfirmButtonNo').addEvent('click', function(pEvent) {
      pEvent.stop();

      DSWConfirmWin.Destroy(true);

      if ('function' == MooTools.$type(pNoAction)) {
        pNoAction();
      }
      else {
        // dla zachowania kompatybilnosci wstecz
        eval(pNoAction);
      }
    });


    var height = document.getElement('#DSWConfirmWinContent').getComputedSize().totalHeight;

    DSWConfirmWin = new DSWindow('DSWConfirmWin', 500, height, 'center', 'center', pConfirmHeaderText, false, 'DSWConfirmWinContent', true, true, true, '', '', '', '', '', true);

    // Buttony do prawej
    document.getElement('#DSWBtnsArea').position({
      'relativeTo': content_el,
      'edge': 'rightBottom',
      'position': 'rightBottom'
    }).setStyle('left', document.getElement('#DSWBtnsArea').getStyle('left').toInt() - 20);
  }

  /**
   * Odpowiednik standardowego Prompt'a
   *
   * @param pOptions: text, header_text, init_value
   * @param pCallback - function
   */
  DSWindow.Prompt = function(pOptions, pCallback) {

    if (!pOptions.header_text) {
      pOptions.header_text = 'Prompt';
    }

    if (!pOptions.init_value) {
      pOptions.init_value = '';
    }
    ActiveWindow.prompt_callback = pCallback;
    var content_el = new MooTools.Element('div', {
      'id': 'DSWConfirmWinContent',
      'styles': {
        'position': 'absolute',
        'width': 300
      },
      'html': '\
      <div class="DSWindowConfirmText DF_label">' + pOptions.text + '</div>\
      <input class="DF_textbox" value="' + pOptions.init_value + '" type="text" id="DSWindowInputText" />\
      <div id="DSWBtnsArea">\
        <a href="" class="styled-button gray" id="DSWConfirmButtonYes" style="float:left">' + 'OK' + '</a>\
        <a href="" id="DSWConfirmButtonNo" style="float:left">' + 'Anuluj' + '</a>\
        <div class="Clear"></div>\
      </div>\
   '
    }).inject(document.body);

    // zalozenie eventow na poszczegolne buttony
    $('DSWConfirmButtonYes').addEvent('click', function(pEvent) {
      pEvent.stop();
      var input_value = $('DSWindowInputText').get('value');

      if (!input_value) {
        input_value = false;
      }

      if (pCallback) {
        pCallback(input_value);
      }
      DSWConfirmWin.Destroy(true);
    });

    $('DSWConfirmButtonNo').addEvent('click', function(pEvent) {
      pEvent.stop();

      if (pCallback) {
        pCallback(false);
      }
      DSWConfirmWin.Destroy(true);
    });

    var height = document.getElement('#DSWConfirmWinContent').getComputedSize().totalHeight;

    DSWConfirmWin = new DSWindow('DSWConfirmWin', 300, height, 'center', 'center', pOptions.header_text, false, 'DSWConfirmWinContent', true, true, true, '', '', '', '', '', true);

    // Buttony do prawej
    document.getElement('#DSWBtnsArea').position({
      'relativeTo': content_el,
      'edge': 'rightBottom',
      'position': 'rightBottom'
    }).setStyle('left', document.getElement('#DSWBtnsArea').getStyle('left').toInt() - 2);

    var delayed_focus = function() {
      $('DSWindowInputText').focus();

      // jesli przekazano jakas poczatkowa wartosc to zaznaczamy to
      if (pOptions.init_value) {
        $('DSWindowInputText').select();
      }
    }

    // jezeli wcisniety klawisz jest enterem wykonujemy akcje dla 'tak'
    $('DSWindowInputText').addEvent('keyup', function(pE) {
      if (13 == pE.code) {
        var input_value = $('DSWindowInputText').get('value');

        if (!input_value) {
          input_value = false;
        }

        if (pCallback) {
          pCallback(input_value);
        }
        DSWConfirmWin.Destroy(true);
      }
    });

    delayed_focus.delay(200);
  }

  DSWindow.HideLoader = function() {
    var loader = document.getElement('.dswindow-loader');

    if (loader) {
      loader.remove();
    }
  }

  /**
   * Tworzy okno DSWindow  wygladem przypominajace klasyczne alert() :)
   * @param string pText - tresc komunikatu
   * @param string pConfirmHeaderText - tytul okienka
   * @param string pBtnText - napis na buttonie (defaultowo 'OK')
   * @param integer pTextTop @obsolete
   * @param string pIcon @obsolete
   * @param pAction - callback do funkcji
   */
  DSWindow.Alert = function(pText, pConfirmHeaderText, pBtnText, pTextTop, pIcon, pAction, pClass) {
    if (!pBtnText) {
      pBtnText = 'OK';
    }

    if (!pConfirmHeaderText) {
      pConfirmHeaderText = 'Attention';
    }

    if (typeof window.dpManager !== 'undefined' && dpManager.isEditor) {
      MPP_DIALOGS.alert({
        text: pText,
        header: pConfirmHeaderText,
        btnText: pBtnText,
        yesAction: pAction,
        closeAction: pAction,
      });
      return;
    }

    var content_el = new MooTools.Element('div', {
      'id': 'DSWConfirmWinContent',
      'styles': {
        'position': 'absolute',
        'width': 300
      },
      'html': '\
        <div class="DSWindowConfirmText">' + pText + '</div>\
        <a href="" class="styled-button gray" id="DSWCloseButton">' + pBtnText + '</a>\
    '
    }).inject(document.body);

    if (pClass) {
      content_el.addClass(pClass);
    }

    $('DSWCloseButton').addEvent('click', function(pE) {

      pE.stop();

      DSWConfirmWin.Destroy(true);

      if (typeof pAction === 'function') {
        pAction();
      }
      else {
        eval(pAction);
      }

    });

    var height = document.getElement('#DSWConfirmWinContent').getComputedSize().totalHeight;

    DSWConfirmWin = new DSWindow('DSWConfirmWin', 300, height, 'center', 'center', pConfirmHeaderText, false, 'DSWConfirmWinContent', true, true, true);

    // Centruje button
    document.getElement('#DSWCloseButton').position({
      'relativeTo': content_el,
      'edge': 'rightBottom',
      'position': 'rightBottom'
    }).setStyle('left', document.getElement('#DSWCloseButton').getStyle('left').toInt() - 2);

  };
})(DSWindow);

DSWindow.IsAlertDisplayed = function(pType) {
  if (typeof DSWConfirmWin != 'undefined' && DSWConfirmWin && DSWConfirmWin.mMainElement && (!pType || DSWConfirmWin.mHeaderText == pType)) {
    return true;
  }

  return false;
};

DSWindow.EscapeKeyListener = function(pE) {
  if (pE.key == 'esc' && ActiveWindow != null && !ConfirmMode) {
    pE.stop();
    if (ActiveWindow.prompt_callback) {
      ActiveWindow.prompt_callback(false);
    }
    ActiveWindow.HideOnClose() || ActiveWindow.Destroy();
  }
};

window.addEvent('domready', function() {
  document.addEvent('keydown', DSWindow.EscapeKeyListener.bindWithEvent(null));
});
