/* eslint-disable */
/* globals Dejax */
/* globals FEATURE_PROJECT_IMPORT */
/* globals UXPStack */
/* globals uxpft */

var UXPUpload = new MooTools.Class({

  name: 'UXPUpload',

  Implements: [MooTools.Options, MooTools.Events],

  options: {
    'droppable': true,
    'droppable_el': document,
    'selectable': false,
    'selectable_el': null,
    'cloud_url': '/ajax/dmsCollections/GetUploadUrl/',
    'upload_url': '/mod/dmsUXPUpload/Upload/',
    'local_upload_url': '/mod/dmsCollections/ImportProject/',
    'multiple': true,
    'size_limit': 1024 * 1024 * 30,  // 30MB
    'formats': ['ppt', 'pptx', 'doc', 'docx', 'odt', 'odp', 'rtf', 'pdf', 'jpg', 'jpeg', 'png', 'bmp', 'uxpin'],
    'localy_parsed_formats': ['uxpin'],
    'uploader_type': ['local', 'cloud'],
    'data': {},
    'xhr': true,
    'auto_submit': true,
    'onSubmit': MooTools.$empty,
    'onSuccess': MooTools.$empty,
    'onProgress': MooTools.$empty,
    'onFailure': MooTools.$empty,
    'onComplete': MooTools.$empty,
    'onCancel': MooTools.$empty,
    'onDragOver': MooTools.$empty,
    'onDragLeave': MooTools.$empty,
    'onDragEnd': MooTools.$empty,
    'onDrop': MooTools.$empty,
    'onChoose': MooTools.$empty,
    'onSelectFiles': MooTools.$empty,
    'onValidFiles': MooTools.$empty,
    'onInvalidFiles': MooTools.$empty
  },

  mFormData: null,
  mUploadEl: null,

  mFiles: null,

  mFilesPosted: [],

  mStatus: null,

  mProgress: 0,
  mFilesProgress: {},

  mDragging: false,

  mSelectionType: null,

  mXhr: null,


  initialize: function(pOptions) {
    this.mStatus = UXPUpload.STATUS_NONE;

    this.setOptions(pOptions);
    this.reset();

    if(this.options.droppable) {
      this.setDroppable();
    }

    if(this.options.selectable) {
      this.setSelectable();
    }

    this.addEvent('selectFiles', this.onSelectFiles.bind(this));
  },

  /**
   * Resetuje instancje przygotowujac ja do kolejnego uzycia
   */
  reset: function() {
    this.setStatus(UXPUpload.STATUS_NONE);
    this.mFormData = null;
    this.mResult = null;
    this.mFiles = null;
    this.mFilesPosted = [];
    this.mProgress = 0;
    this.mFilesProgress = {};

    this.createFormData();
  },

  /**
   * Deaktywuje instancje UXPUpload,
   * tzn nie niszczy jej i nie zmienia stanu, ale odpina eventy odpowiedzialne za mozliwosc rozpoczecia procesu uploadu za pomoca tej isntancji
   */
  deactivate: function() {

    // odepnij mozliwosc dropowania plikow
    if(this.options.droppable && this.options.droppable_el) {
      this.options.droppable_el.ondragover = MooTools.$empty;
      this.options.droppable_el.ondragend = MooTools.$empty;
      this.options.droppable_el.ondragleave = MooTools.$empty;
      this.options.droppable_el.ondrop =  MooTools.$empty;
    }

    // odepnij mozliwosc wybrania plikow
    if(this.options.selectable && this.mUploadEl) {

      // odepnij change w polu wyboru pliku
      this.mUploadEl.removeEvents('change', this.mSelectableChangeRef);

      // odepnij klik na syntetycznym przycisku aktywujacym wybor pliku
      if(this.mSelectableClickRef) {
        if (this.options.selectable_el.removeEvent) {
          this.options.selectable_el.removeEvent('click', this.mSelectableClickRef);
        }
        else if(this.options.selectable_el.length) {
          this.options.selectable_el.each(function(pEl) {
            pEl.removeEvent('click', this.mSelectableClickRef);
          }.bind(this));
        }
      }
    }
  },

  cancel: function() {
    this.mXhr && this.mXhr.abort();
    this.setStatus(UXPUpload.STATUS_CANCELLED);
    this.fireEvent('cancel');
  },

  setStatus: function(pStatus) {
    this.mStatus = pStatus;
  },

  getStatus: function() {
    return this.mStatus;
  },

  getResult: function() {
    return this.mResult;
  },

  getProgress: function() {
    return this.mProgress;
  },

  /**
   * Tworzy obiekt FormData i uzupelnia ewentualnymi danymi do wyslania
   */
  createFormData: function() {
    this.mFormData = new FormData();

    new MooTools.Hash(this.options.data).each(function(pValue, pName) {
      this.mFormData.append(pName, pValue);
    }.bind(this));
  },

  /**
   * Aktywuje mozliwosc upuszczenia plikow do zuploadowania wewnatrz okna przegladarki
   */
  setDroppable: function() {
    var droppable = this.options.droppable_el;

    // Szybki test, czy w ogole przgladarka wspiera
    // eslint-disable-next-line space-before-function-paren
    var dndSupported = function () {
      var div = document.createElement('div');
      return ('draggable' in div) || ('ondragstart' in div && 'ondrop' in div);
    };

    if(false == dndSupported()) {
      console.error("Drag and drop not supported by the browser");
      return;
    }

    // Anulowanie domyslnej akcji przgladarki przy eventach drag & dropa
    var preventRef = function(pE) {
      pE.preventDefault && pE.preventDefault();
      return false;
    };

    // Uniemozliwiamy dragowanie obrazkow i tekstow (i w ogole niczego) umieszczonych na stronie
    document.getElements('*').each(function(pItem) {
      pItem.draggable = false;
    });

    // drag over z plikami
    droppable.ondragover = function(pE) {
      this.mDragging = true;
      this.fireEvent('dragOver', [pE]);
      return preventRef(pE);
    }.bind(this);

    //
    droppable.ondragend = function(pE) {
      this.mDragging = false;
      this.fireEvent('dragEnd', [pE]);
      return preventRef(pE);
    }.bind(this);

    // wyjechanie z plikami
    droppable.ondragleave = function(pE) {
      this.mDragging = false;
      this.fireEvent('dragLeave', [pE]);
    }.bind(this);

    // upuszczenie plikow
    droppable.ondrop =  function(pE) {
      this.reset();

      if(pE.dataTransfer.files.length == 0) {
        return false;
      }

      this.mFiles = pE.dataTransfer.files;
      this.mSelectionType = 'drop';

      this.fireEvent('selectFiles');
      this.fireEvent('drop', [pE]);
      return preventRef(pE);
    }.bind(this);
  },

  /**
   * Tworzy element input file, umozliwiajacy reczne wybranie plikow do uploadu
   */
  setSelectable: function() {

    // sprawdzamy, czy podany selectable_el, nie jest juz inputem type=file, jesli nie, tworzymy taki
    if(undefined === this.options.selectable_el.length && this.options.selectable_el.nodeName.toLowerCase() == 'input' && this.options.selectable_el.getAttribute('type') == 'file') {
      this.mUploadEl = this.options.selectable_el;
    }
    else {
      this.mUploadEl = new MooTools.Element('input', {
        'type': 'file',
        'name': 'Filedata',
        'styles': {
          'position': 'absolute',
          'top': 0,
          'left': 0,
          'z-index': 1000,
          'visibility': 'hidden'
        }
      }).inject(document.getElement('body'));

      this.mSelectableClickRef = function() {
        this.mUploadEl.click();
      }.bind(this);

      if (this.options.selectable_el.addEvent) {
        this.options.selectable_el.addEvent('click', this.mSelectableClickRef);
      }
      else if(this.options.selectable_el.length) {
        this.options.selectable_el.each(function(pEl) {
          pEl.addEvent('click', this.mSelectableClickRef);
        }.bind(this));
      }
    }

    // czy multiple?
    if(this.options.multiple) {
      this.mUploadEl.setAttribute('multiple', 'multiple');
    }

    this.mSelectableChangeRef = function(pE) {
      this.reset();
      this.mFiles = pE.target.files;
      this.mSelectionType = 'select';
      this.fireEvent('selectFiles');
      this.fireEvent('choose');
    }.bind(this);

    // przypinamy event, gdy wybrano pliki
    this.mUploadEl.addEvent('change', this.mSelectableChangeRef);
  },

  /**
   * Obsluguje event wybrania plikow przez usera
   */
  // eslint-disable-next-line consistent-return
  onSelectFiles: function() {

    // Jesli wysylka ma przekierowac usera
    if(this.options.xhr == false) {
      console.error("Feature not supported yet");
      return false;
    }

    // Waliduj pliki
    var valid = this.validateFiles();

    if(true === valid) {
      var uploadType = this.getUploaderType();
      if ('local' == uploadType && this.mFiles.length > 1) {
        this.fireEvent('invalidFiles', [['You can drop only one file']]);
      }

      // odpal event
      this.fireEvent('validFiles', [uploadType]);

      // wyslij automatycznie
      this.options.auto_submit && this.send();
    }
    else {
      this.fireEvent('invalidFiles', [valid]);
    }
  },

  /**
   * Waliduje pliki zawarte w this.mFiles pod katem rozmiaru
   */
  validateFiles: function() {
    var errors = [];
    var files = [];
    var total_size = 0;
    var file, ext;

    for (var i = 0; i < this.mFiles.length; i++) {
      file = this.mFiles[i];

      // aktualizuj calkowity rozmiar
      total_size+= file.size;

      // sprawdz poprawnosc pliku
      ext = this.getFileExtension(file.name);
      if ('uxpin' == ext && uxpft.disabledInPlan(FEATURE_PROJECT_IMPORT)) {
        errors.push(file.name + ": file format not supported in your plan");
      } else if(null != this.options.formats && false == this.options.formats.contains(ext.toLowerCase())) {
        if ('sketch' == ext) {
          errors.push("To import Sketch file you need to generate *.uxpin import file using our plugin. <br/><button class='btn-flat upload-error-btn' onclick='document.getElementById(\"DSWCloseButton\").click();window.pluginDownloadDialog.show();'>Get plugin</button>");
        } else if ('psd' == ext || 'psb' == ext) {
          errors.push("To import Photoshop file you need to generate *.uxpin import file using our plugin. <br/><button class='btn-flat upload-error-btn' onclick='document.getElementById(\"DSWCloseButton\").click();window.pluginDownloadDialog.show();'>Get plugin</button>");
        } else {
          errors.push("Sorry, we currently don't support this file format.");
        }
      }
      files.push({'name': file.name, 'size': file.size});
    }

    // sprawdz czy nie przekroczono rozmiaru
    if(null != this.options.size_limit && total_size > this.options.size_limit) {
      errors.push("Max upload size of "+Math.round(this.options.size_limit/1024/1024)+"MB exceeded, trying to upload "+(total_size/1024/1024).toFixed(2)+"MB");
    }

    // Jesli wystapily bledy, zaloguj na serwerze i zwroc userowi
    if(errors.length > 0) {
      new Dejax().newRequest({
        'url': '/ajax/dmsCollections/LogFail/',
        'eval': false,
        'data': {'files': files, 'errors': errors, 'id_account': this.options.id_account, 'id_collection': this.options.id_collection}
      });

      return errors;
    }

    return true;
  },

  getFileExtension: function(pName) {
    return /^.*\.([a-z0-9]+)$/i.exec(pName)[1];
  },

  getUploaderType: function() {
    var are_localy_parsed = true;
    var ext;

    for (var i = 0; i < this.mFiles.length; i++) {
      ext = this.getFileExtension(this.mFiles[i].name);
      if (null == this.options.localy_parsed_formats || false == this.options.localy_parsed_formats.contains(ext.toLowerCase())) {
        are_localy_parsed = false;
        break;
      }
    }
    if (are_localy_parsed) {
      return 'local';
    }
    return 'cloud';
  },

  /**
   * Wysyla request
   */
  send: function() {
    this.mFilesProgress = new MooTools.Hash();

    // Jesli request juz sie rozpoczal nie pozwalamy wyslac ponownie
    if(this.getStatus() != UXPUpload.STATUS_NONE) {
      return;
    }

    // czekaj az wysla sie wszystkie pliki na S3
    this.addEvent('S3Uploaded', function(pE) {

      // Jesli wszystkie przeszly, wislij info na serwer
      if(this.mFilesPosted.length === this.mFiles.length) {
        this.sendToServer(false == pE.files_sent);
      }
    }.bind(this));

    for (var i = 0; i < this.mFiles.length; i++) {
      var current_index = i;
      this.mFilesProgress.set(current_index, 0);

      // dla kazdego pliku pobieramy adres do clouda z serwera
      if (this.options.cloud_url) {
        var dejax_request = new Dejax();

        dejax_request.send({
          'mode': 'queue',
          'url': this.options.cloud_url,
          'data': MooTools.$extend(this.options.data, {
            'file_name': this.mFiles[i].name,
            'file_type': this.mFiles[i].type || 'application/octet-stream',
            'file_index': current_index
          }),
          // eslint-disable-next-line no-loop-func
          'onComplete': function(pResult) {
            if(pResult.url) {
              pResult.url = decodeURIComponent(pResult.url);
              this.sendToS3(pResult);
            }
            else {
              this.onUploadError(this.mFiles[current_index].name, 'CU', dejax_request.mRh.response);
            }
          }.bind(this)
        });
      }
      // plik gotowy do wyslania bezposrednio na nasz serwer
      else {
        this.mFilesPosted.push(this.mFiles[i].name);
        this.fireEvent('S3Uploaded', [{files_sent: false}]);
      }
    }
  },

  sendToUXPin: function() {
    this.mFilesProgress = new MooTools.Hash();

    // Jesli request juz sie rozpoczal nie pozwalamy wyslac ponownie
    if(this.getStatus() != UXPUpload.STATUS_NONE) {
      return;
    }

    this.sendToServer(true, true);
  },

  /**
   * Wysyla plik bezposrednio do S3
   *
   * @param int pI index pliku w tablicy this.mFiles
   * @param string pUrl url na jakis wyslac request PUT
   */
  sendToS3: function(pFile) {
    var fi = pFile.i;
    var total = this.mFiles.length;
    var current = this.mFilesPosted.length + 1;

    var xhr = new XMLHttpRequest();
    xhr.open('PUT', pFile.url);

    xhr.onload = function() {
      if('200' == xhr.status) {
        // eslint-disable-next-line semi
        this.mFilesPosted.push(pFile.file_key)
        this.fireEvent('S3Uploaded', [{files_sent: true}]);
      }
      else {
        this.onUploadError(this.mFiles[fi].name, 'CL:'+xhr.status, xhr.response);
      }
    }.bind(this);

    xhr.onerror = function() {
      this.onUploadError(this.mFiles[fi].name, 'CE', xhr.response);
    }.bind(this);

    xhr.upload.onprogress = function(e) {
      var progress_total = 0;
      var progress = e.loaded/e.total*(100) || 0;

      this.mFilesProgress.set(fi, progress);
      this.mFilesProgress.each(function(pProgress) {
        progress_total+= pProgress/this.mFiles.length;
      }.bind(this));

      this.mProgress = progress_total >= 98 ? 98 : progress_total;
      this.mStatus = UXPUpload.STATUS_PENDING;
      this.fireEvent('progress', [this.mProgress]);
    }.bind(this);

    xhr.setRequestHeader('Content-Type', pFile.file_type);
    xhr.setRequestHeader('x-amz-acl', 'public-read');

    xhr.send(this.mFiles[fi]);
  },

  sendToServer: function(pAppendFiles, pUseLocalUrl) {
    var xhrUrl = this.options.local_upload_url;
    if (typeof pUseLocalUrl === 'undefined') {
      xhrUrl = this.options.upload_url;
    }
    this.mXhr = new XMLHttpRequest();
    this.mXhr.open('POST', xhrUrl);

    // wyslij pliki na serwer
    if (pAppendFiles) {
      for (var i = 0; i < this.mFiles.length; i++) {
        this.mFormData.append(this.mFiles[i].name, this.mFiles[i]);
      }
      if(this.options.id_collection) {
        this.mFormData.append('collection', this.options.id_collection);
      }
      // progress
      this.mXhr.upload.onprogress = function(e) {
        // eslint-disable-next-line radix
        var progress = parseInt(e.loaded/e.total*(100)) || 0;

        this.mProgress = progress;
        this.fireEvent('progress', [this.mProgress]);
      }.bind(this);
    }
    // wyslij tylko info o plikach wyslanych wczesniej do chmury
    else {
      this.mFilesPosted.each(function(pFileKey) {
        this.mFormData.append('Files[]', pFileKey);
      }.bind(this));

      this.mProgress = 99;
      this.fireEvent('progress', [this.mProgress]);
    }

    this.mXhr.onload = function(e) {
      if(this.mXhr.status != 200) {
        this.fireEvent('fail');
      }
      else {
        this.mStatus = UXPUpload.STATUS_DONE;
        this.mResult = this.mXhr.response;
        this.mProgress = 100;
        this.fireEvent('success', this.mResult);
      }

      this.fireEvent('complete');
    }.bind(this);

    this.mStatus = UXPUpload.STATUS_WAITING;

    // zakolejkuj wysylanie
    UXPUpload.chain(this);

    this.fireEvent('submit');
  },

  onUploadError: function(pFileName, pErrorCode, pErrorDetails) {
    var message = "Could not upload file " + pFileName + ". \nPlease refresh page and try again.\n\n";
    message+= "Error code: " + (pErrorCode || "unknown");

    UXPStack.error(message, pErrorDetails);
    alert(message);
  }

}).extend({

  STATUS_NONE: 0,
  STATUS_WAITING: 50,
  STATUS_PENDING: 100,
  STATUS_DONE: 200,
  STATUS_CANCELLED: 500,

  queue: [],

  /**
   * Dodaje instancje UXPUpload do kolejki wyslania plikow. Aktywuje wykonywanie kolejki jesli jest pusta.
   *
   * @param UXPUpload pUpload Instancja UXPUpload, w ramach ktorej maja zostac wyslane pliki
   */
  chain: function(pUpload) {

    UXPUpload.queue.push(pUpload);

    // Uruchamiamy 'egzekujce' tylko gdy jedynym uploadem w kolejce jest ten wlasnie dodany
    if(UXPUpload.queue.length == 1) {
      UXPUpload.execute();
    }
  },

  /**
   * Pobiera instancje UXPUpload z kolejki i rozpoczyna wysylanie plikow
   */
  execute: function() {
    var upload, i;

    // pobierz pierwsza oczekujaca instancje
    for(i = 0; i < UXPUpload.queue.length; i++) {
      if(UXPUpload.queue[i] && UXPUpload.queue[i].getStatus() == UXPUpload.STATUS_WAITING) {
        upload = UXPUpload.queue[i];
        break;
      }
    }

    // brak, bye bye
    if(null == upload) {
      return;
    }

    var executeRef = function() {
      UXPUpload.queue.splice(i, 1);
      UXPUpload.execute();
    };

    // po zakonczeniu usun z kolejki i rozpocznij kolejny upload
    upload.addEvent('complete', executeRef);
    upload.addEvent('cancel', executeRef);

    // rozpocznij wysylanie
    upload.mXhr.send(upload.mFormData);
  }

});
