/* eslint-disable no-useless-escape */

// eslint-disable-next-line no-extra-semi,wrap-iife
;(function UtilsValidatorScope(nsp) {
  'use strict';

  var validator = {
    email: function(email) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    empty: function(value) {
      if (typeof value !== 'string') {
        throw new Error('Only string validation available.');
      }

      return value.trim() === '';
    // eslint-disable-next-line comma-dangle
    }
  // eslint-disable-next-line semi
  }

  // eslint-disable-next-line no-param-reassign
  nsp.validator = validator;

// eslint-disable-next-line padded-blocks
})(window.utils);
