/* eslint-disable */

MooTools.Array.implement({

  /**
   * Dziala identycznie jak each() z tym wyjatkiem, ze jesli callback zwroci FALSE
   * petla zostanie przerwana
   */
	breakEach: function(fn, bind){
    
		for (var i = 0, l = this.length; i < l; i++) {
      
      if(false === fn.call(bind, this[i], i, this)) {
        break;
      }
    }
	}

});

MooTools.Hash.implement({

	breakEach: function(fn, bind){
    
		// eslint-disable-next-line no-restricted-syntax
		for (var key in this){
      
			// eslint-disable-next-line no-prototype-builtins
			if (this.hasOwnProperty(key)) {
        
        if(false === fn.call(bind, this[key], key, this)) {
          break;
        }
      }
		}
	}
});

MooTools.Number.range = function(start, end) {
  // eslint-disable-next-line one-var
  var numbers = [],
      i = start;
  
  for(; i <= end; numbers.push(i), i++);
  
  return numbers;
};

RegExp.quote = function(str) {
    // eslint-disable-next-line space-infix-ops,quotes
    return (str+'').replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
};
