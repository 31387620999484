/* eslint-disable */
/* globals Dejax */
/* globals i */
/* globals script */
/* globals script_to_asset */
/* globals scripts */
/* globals scripts_to_asset */

/**
 * Klasa służąca do obsługi requestów AJAXowych
 *
 * Wykorzystuje mootoolsową klasę Json.Remonte
 *
 * Przykład uzycia:
 *
 *
 *     DejaxRequest.newRequest({
 *       url: '/ajax/dmsDPPage/SetActivePage/',
 *       eval: true,
 *       mode: 'cancel',
 *       data: {},
 *       onComplete: this.switchActivePage.bind(this)
 *     });
 *
 * @copyright This file is DeSmart module. Copyright DeSmart.com. All rights reserved. www.desmart.com desmart@desmart.com
 */
Dejax = new MooTools.Class({

  name: 'Dejax',

  Implements: [MooTools.Events],

  /**
  * Po tym czasie request zostanie uznany za "zaginiony" i zraportowany do debugera
  */
  mTTL: 30000,

  /**
   * Request handler - uchwyt aktywnego połączenia, które należy wykorzystać, chyba że zapytanie należy wykonać w trybie free (mode: free)
   *
` */
  mRh: null,

  /**
   * Flaga informująca czy istnieje aktywne połączenie
   *
` */
  mIsActiveConnection: false,

  /**
   * Stos requestów do wykonania
   *
` */
  // eslint-disable-next-line no-array-constructor,new-parens
  mQueue: new Array,

  /**
   * Służy do ominięcia eventa onbeforeunload
   * Ustawiany na true w momencie wyłaczenia systemu przez administratora
   *
` */
  mForceLeave: false,

  mLogoutUrl: '/mod/Login/Logout',

  /**
   * mode - tryb zapytania, możliwe wartości:
   *    1) queue - zapytanie dodaje się do stosu i jest wykonywane gdy poprzednie zostanie zakończone
   *    2) cancel - zapytanie anuluje istniejące połączenie i wywołuje nowe
   *    4) free - poza kontrolą trybów - tworzy nowy obiekt Json.Request

   * eval - decyduje o tym czy zwrócaona treść ma być evalowana
   * onComplete - event uruchamiany po zakończeniu zapytania.
   * completePass - Agumenty które zostaną przekazane do onComplete.
   * data - dane do przesłania
   * prevent - uniemożliwia wykonanie jakiegokolwiek zapytania do czasu zakończenia istniejącego
   *
   */
	options: {
    mode: 'free',
    completePass: new MooTools.Array(),
    data: null,
    prevent: false,
    url: null,
    eval: true,
    evalData: null,
    debugRequest: false
	},

	initialize: function(){
	  this.resetOptions();
	},

  /**
   * Tworzy nowy obiekt Json.Remonte i przypisuje go do requestHandlera.
   *
   */
	createRequestHandler: function(){
    this.mRh = new MooTools.Request.JSON({
      onComplete: this.onCompleteCall.bind(this),
      onSuccess: this.onSuccessCall.bind(this),
      onFailure: this.onFailureCall.bind(this),
      evalScripts: false,
      url: this.options.url
    });
	},

	resetOptions: function(){
	  this.options = {
      mode: 'free',
      eval: true,
      completePass: new MooTools.Array(),
      data: null,
      prevent: false,
      url: null,
      evalData: null,
      debugRequest: false
  	}
	  this.removeEvents('onComplete');
	  this.removeEvents('onSuccess');
	  this.removeEvents('onFailure');
	},

	setOptions: function(pOptions){
    if(pOptions.onComplete){
      this.addEvent('onComplete', pOptions.onComplete);
    }
    if(pOptions.onSuccess){
      this.addEvent('onSuccess', pOptions.onSuccess);
    }
    if(pOptions.onFailure){
      this.addEvent('onFailure', pOptions.onFailure);
    }
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for(i in this.options){
      this.options[i] = pOptions[i]
    }
	},

  /**
   * inicjuje nowe zapytanie, w zależności od trybu
   *
   */
	newRequest: function(pOptions){

    switch(pOptions.mode){
      default:
      case 'free':
        var tmp = new Dejax();
        tmp.send(pOptions);
        return true;
        // eslint-disable-next-line no-unreachable
        break;
      case 'cancel':
    	  if(true == this.options.prevent){
          // Jeżeli w kolejce poprzednim trybem jest cancel to usuwamy ostatniego requesta z kolejki
          var prev = this.mQueue.length.toInt() - 1;
          if(this.mQueue[prev] && this.mQueue[prev].mode == 'cancel'){
            this.mQueue.pop();
          }

    	    // Jeżeli włączony jest tryb prevent to request jest kolejkowany
          this.mQueue.include(pOptions);
    	    return false;
    	  }
        if(true == this.mIsActiveConnection){
          MooTools.$clear(this.options.debugDelay);
          this.mRh.cancel();
        }
        break;
      case 'queue':
        if(true == this.mIsActiveConnection){
          this.mQueue.extend([pOptions]);
    	    return true;
        }
        break;
    }

	  if(true == this.options.prevent){
	    return false;
	  }
    this.send(pOptions);
	},

	send: function(pOptions){
    if(pOptions){
  	  this.resetOptions();
  	  this.setOptions(pOptions);
    }
    if(!this.options){
      return false;
    }

    this.fireEvent('onStart');

    this.mIsActiveConnection = true;

    // czyscimy urla z  parametru __ajax_request jeżeli juz go posiada
    this.options.url = this.options.url.replace(/(\?|&)__ajax_request=1/g, '');

    if (!pOptions.noDmsAjax) {
      if(!this.options.url.match(/\?/)){
        this.options.url +='?__ajax_request=1'
      }
      else{
        this.options.url +='&__ajax_request=1'
      }
    }

	  // Poprzedni request się zakończył więc trzeba stworzyć nowy!
	  this.createRequestHandler();

    if (pOptions.method === 'GET') {
      this.mRh.get();
    }
    else {
      var postData = this.options.data;
      this.mRh.post(postData);
    }
	},

	showAlert: function(){
	  alert('nie wolno!')
	},

  onSuccessCall: function(data){
    this.fireEvent('onSuccess', data);
  },

  onFailureCall: function(data){
    this.fireEvent('onFailure', data);
  },

  /**
   * Metoda wywoływana po zakończeniu requesta ajaxowego.
   *
   * evaluje również wszystkie skrypty które zostały zwrócone
   * jako parametr zwracane są dane przesłane z requesta
   *
   * @param object pData
   */
  onCompleteCall: function(pData){
    MooTools.$clear(this.options.debugDelay);
    this.mIsActiveConnection = false;
    this.options.prevent = false;

    if(pData && pData.system_message && 'disabled' == pData.system_message){
      this.mForceLeave = true;
      window.location.href = this.mLogoutUrl;
    }

    if(!this.options.completePass){
      this.options.completePass = new MooTools.Array();
    }
    this.options.completePass.extend([pData])

    this.fireEvent('onComplete', this.options.completePass);

    if(true == this.options.eval){
      if(this.options.evalData){
        this.options.evalData.each(function(pEl){
          this.evalScripts(pData[pEl]);
        }.bind(this))
      }
      else{
        this.evalScripts(pData);
      }
    }

    this.fireEvent('onEnd');


    if(this.mQueue.length > 0){
      this.send(this.mQueue.pop());
    }

    return pData;
  },

  /**
   * Jeżeli request trwa zbyt długo to informacje na jego temat sa zapisywane do bzy danych,
   * sam request jest cancelowany i uruchamiany ponownie
   *
   * @param object pData
   */
  runDebug: function(pData){

    if(false == this.mIsActiveConnection){
      return false;
    }

    this.mRh.cancel();
    // Jeżeli jest to request Debugowy to anulujemy trwający ale nie wysyłamy kolejnego requesta
    if(true === this.options.debugRequest){
      return true;
    }

    this.newRequest({
      url: '/ajax/Dejax/DeBug/',
      eval: false,
      debugRequest: true,
      data: {'data': pData}
    });

    this.newRequest(pData);
  },

  /**
   * Metoda evalujaca javascripta z podanego stringa.
   *
   * @param string pData
   */
	evalScripts: function(pData){
		scripts_to_asset = [];
		var regexp_to_asset = /<script[^>]* src="([\s\S]*?)"[^>]*?>[\s\S]*?<\/script>/gi;
		// eslint-disable-next-line no-new,new-cap
		while ((script_to_asset = regexp_to_asset.exec(pData))) new Asset.javascript(script_to_asset[1]);

	  this.evalInLine.delay(1, this, pData);
	},

	evalInLine: function(pData){
		scripts = [];
		var regexp = /<script[^>]*>([\s\S]*?)<\/script>/gi;
		while ((script = regexp.exec(pData))) scripts.push(script[1]);
		scripts = scripts.join('\n');

		// eslint-disable-next-line no-unused-expressions
		if (scripts) (window.execScript) ? window.execScript(scripts) : window.setTimeout(scripts, 0);
	}


});
var DejaxRequest;
window.addEvent('domready', function(){
  DejaxRequest = new Dejax();
});
