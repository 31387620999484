// eslint-disable-next-line no-extra-semi,wrap-iife
;(function GetUpgradeLinkScope(nsp) {
  'use strict';

  const getFormData = function(formElement) {
    const data = new FormData(formElement);

    data.append('return_json', 1);

    return data;
  };

  const getUpgradeLink = function(formElement) {
    const url = formElement.getAttribute('action');
    const data = getFormData(formElement);

    return fetch(url, {
      body: data,
      method: 'POST',
    })
      .then(response => response.json());
  };

  // eslint-disable-next-line no-param-reassign
  nsp.getUpgradeLink = getUpgradeLink;

// eslint-disable-next-line padded-blocks
})(window.utils);
