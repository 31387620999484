/* eslint-disable */
/* globals dpManager */

Drag.MoveWithScroll = new MooTools.Class({

  name: 'DragMoveWithScroll',

  Extends: Drag.Move,

  // eslint-disable-next-line spaced-comment
  options: {/*
   onEnter: MooTools.$empty(thisElement, overed),
   onLeave: MooTools.$empty(thisElement, overed),
   onDrop: MooTools.$empty(thisElement, overed, event),*/
    droppables: [],
    container: false,
    precalculate: false,
    includeMargins: true,
    checkDroppables: true,
    stepSize: 50,
    speed: 50,
    canStart: true
  },

  start: function(event) {
    this.topAutoScroll =  dpManager.dpWorkBench.mWorkBench.getParent().getStyle('top').toInt() + 17;
    this.bottomAutoScroll = this.topAutoScroll + dpManager.dpWorkBench.mWorkBench.getStyle('height').toInt() - 20;
    this.leftAutoScroll =dpManager.dpWorkBench.mWorkBench.getParent().getStyle('left').toInt() + 17;
    this.rightAutoScroll = this.leftAutoScroll + dpManager.dpWorkBench.mWorkBench.getStyle('width').toInt() - 20;

    this.scrolls = {};
    this.scrolls.start = dpManager.dpWorkBench.mWorkBench.getScroll();

    this.workbenchStyles = {
      width: dpManager.dpWorkBench.mWorkBench.getStyle('width').toInt(),
      height: dpManager.dpWorkBench.mWorkBench.getStyle('height').toInt()
    };

    this.elementStyles = {
      width:this.element.getStyle('width').toInt(),
      height:this.element.getStyle('height').toInt()
    };


    this.parent(event)
  },

  // eslint-disable-next-line consistent-return
  drag: function(event) {
    if (this.options.preventDefault) event.preventDefault();

    if (!this.options.canStart) {
      return false;
    }

    this.mouse.now = event.page;
    // eslint-disable-next-line no-restricted-syntax,vars-on-top
    for (var z in this.options.modifiers){
      if (!this.options.modifiers[z]) continue;
      this.value.now[z] = this.mouse.now[z] - this.mouse.pos[z] +  dpManager.dpWorkBench.mWorkBench.getScroll()[z]- this.scrolls.start[z];
      if (this.options.invert) this.value.now[z] *= -1;
      if (this.options.limit && this.limit[z]){
        if (MooTools.$chk(this.limit[z][1]) && (this.value.now[z] > this.limit[z][1])){
          this.value.now[z] = this.limit[z][1];
        } else if (MooTools.$chk(this.limit[z][0]) && (this.value.now[z] < this.limit[z][0])){
          this.value.now[z] = this.limit[z][0];
        }
      }

      if(z == 'y') {
        if(this.mouse.now.y < this.topAutoScroll) {
          if(!this.scrollCycle) {
            // eslint-disable-next-line comma-spacing
            this.scrollCycle = this.computeDrag.periodical(this.options.speed, this,["up", z, event]);
          }
          else continue;
        }
        else {
          MooTools.$clear(this.scrollCycle);
          this.scrollCycle = null;
        }


        if(this.mouse.now.y > this.bottomAutoScroll) {
          if(!this.scrollCycleDown) {
            this.scrollCycleDown = this.computeDrag.periodical(this.options.speed, this, ["down", z, event]);
          }
          else continue;

        // eslint-disable-next-line padded-blocks
        }
        else {
          MooTools.$clear(this.scrollCycleDown);
          this.scrollCycleDown = null;
        }
      }
      else {
        if(this.mouse.now.x < this.leftAutoScroll) {
          if(!this.scrollCycleLeft) {
            this.scrollCycleLeft = this.computeDrag.periodical(this.options.speed, this, ["left", z, event]);
          }
          else continue;
        }
        else {
          MooTools.$clear(this.scrollCycleLeft);
          this.scrollCycleLeft = null;
        }


        if(this.mouse.now.x > this.rightAutoScroll) {
          if(!this.scrollCycleRight) {
            this.scrollCycleRight = this.computeDrag.periodical(this.options.speed, this, ["right", z, event]);
          }
          else continue;
        }
        else {
          MooTools.$clear(this.scrollCycleRight);
          this.scrollCycleRight = null;
        }
      }


      if (this.options.grid[z]) this.value.now[z] -= ((this.value.now[z] - (this.limit[z][0]||0)) % this.options.grid[z]);
      if (this.options.style) {
        this.element.setStyle(this.options.modifiers[z], this.value.now[z] + this.options.unit);
      } else {
        this.element[this.options.modifiers[z]] = this.value.now[z];
      }
    }
    this.fireEvent('drag', [this.element, event]);
  },


  computeDrag: function(pDir, pM, pEvent) {
    if(pDir == 'right') {
      dpManager.dpWorkBench.mWorkBench.scrollLeft += this.options.stepSize;
      this.value.now.x =  dpManager.dpWorkBench.mWorkBench.scrollLeft + this.workbenchStyles.width - this.elementStyles.width;
    }

    if(pDir == 'left') {
      dpManager.dpWorkBench.mWorkBench.scrollLeft -= this.options.stepSize;
      this.value.now.x =  dpManager.dpWorkBench.mWorkBench.scrollLeft;
    }

    if(pDir == 'up') {
      dpManager.dpWorkBench.mWorkBench.scrollTop -= this.options.stepSize;
      this.value.now.y =  dpManager.dpWorkBench.mWorkBench.scrollTop;
    }

    if(pDir == 'down') {
      dpManager.dpWorkBench.mWorkBench.scrollTop += this.options.stepSize;
      this.value.now.y = dpManager.dpWorkBench.mWorkBench.scrollTop - this.elementStyles.height + this.workbenchStyles.height;
    }

    if (this.options.grid[pM]) this.value.now[pM] -= ((this.value.now[pM] - (this.limit[pM][0]||0)) % this.options.grid[pM]);
    if (this.options.style) {
      this.element.setStyle(this.options.modifiers[pM], this.value.now[pM] + this.options.unit);
    } else {
      this.element[this.options.modifiers[pM]] = this.value.now[pM];
    }
    this.fireEvent('drag', [this.element, pEvent]);
  },


  stop: function(event) {
    this.clearCycles();
    this.parent(event)
  },

  cancel: function(event) {
    this.clearCycles();
    this.parent(event)
  },

  clearCycles: function() {
    MooTools.$clear(this.scrollCycleRight);
    this.scrollCycleRight = null;

    MooTools.$clear(this.scrollCycleLeft);
    this.scrollCycleLeft = null;

    MooTools.$clear(this.scrollCycleDown);
    this.scrollCycleDown = null;

    MooTools.$clear(this.scrollCycle);
    this.scrollCycle = null;
  }

});

MooTools.Element.implement({

  makeDraggableWithScroll: function(options) {
    var drag = new Drag.MoveWithScroll(this, options);
    this.store('dragger', drag);
    return drag;
  }
});
