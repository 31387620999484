/* eslint-disable comma-dangle */
/* eslint-disable indent */
/* eslint-disable no-undef */
/* eslint-disable padded-blocks */
/* eslint-disable quote-props */
/* eslint-disable semi */
/**
 *
 * Pokazuje tooltipa ze strzałką po najechaniu na element podany jako relative:element
 * do konstruktora
 *
 */
// eslint-disable-next-line no-unused-vars
var DPToolTip = new MooTools.Class({

  name: 'DPToolTip',

  Implements: [MooTools.Options, MooTools.Events],

  options: {
    'relative': {
      'element': null, // Element względem którego pokaże się tip
      'position': 'center', // Pozycja
      'edge': 'centerLeft'
    },
    'arrow': {
      'position': 'centerLeft', // Pozycja elementu "szczałki" względem głównego kontenera
      'edge': 'centerRight'
    },
    'timeout': 5000 // Po jakim czasie element ma zniknac
  },

  initialize: function(pOptions) {
    this.setOptions(pOptions)
    this.render();
  },

  render: function() {
    this.mEl = new MooTools.Element('div', {
      'class': 'info-tip',
      'styles': {
        'opacity': 0
      },
      'html': '<div></div><span></span>'
    }).inject(document.body).addClass(this.options.klass);

    this.mRefShow = this.show.bind(this);
    this.mRefHide = this.hide.bind(this);

    this.options.relative.element.addEvents({
      'mouseenter': this.mRefShow,
      'mouseleave': this.mRefHide
    })

  },

  setText: function(pText) {
    this.reposition();
    this.mText = pText;
    this.mEl.getElement('span').set('html', pText);
  },

  getText: function() {
    return this.mText;
  },

  show: function() {
    MooTools.$clear(this.mTimer);

    // eslint-disable-next-line no-floating-decimal
    this.mEl.tween('opacity', .96)
    this.mTimer = this.hide.delay(this.options.timeout, this);

    this.reposition();
  },

  hide: function() {
    MooTools.$clear(this.mTimer);
    this.mEl.tween('opacity', 0);
  },

  destroy: function() {
    this.mEl.destroy();

    if (this.options.relative.element && this.options.relative.element.getElement) {
      this.options.relative.element.removeEvent('mouseenter', this.mRefShow);
      this.options.relative.element.removeEvent('mouseleave', this.mRefHide);
      this.options.relative.element = null;
    }
  },

  // eslint-disable-next-line consistent-return,key-spacing
  reposition:function() {

    // Element nam zniknął :(
    if (!this.options.relative.element.getElement) {
      return this.destroy();
    }

    this.mEl
      .position({
        'position': this.options.relative.position,
        'edge': this.options.relative.edge,
        'relativeTo': this.options.relative.element
      })
      .getElement('div')
        .position({
          'position': this.options.arrow.position,
          'edge': this.options.arrow.edge,
          'relativeTo': this.mEl
        });
  }

})
