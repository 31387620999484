/* eslint-disable wrap-iife */

// eslint-disable-next-line no-extra-semi
;(function DetectVendorPrefixScope(nsp) {
  'use strict';

  var prefix = (function() {
    var styles = window.getComputedStyle(document.documentElement, '');
    var pre = (Array.prototype.slice.call(styles).join('').match(/-(moz|webkit|ms)-/) || (styles.OLink === '' && ['', 'o']))[1];
    var dom = ('WebKit|Moz|MS|O').match(new RegExp('(' + pre + ')', 'i'))[1];

    return {
      dom: dom,
      lowercase: pre,
      css: '-' + pre + '-',
      js: pre[0].toUpperCase() + pre.substr(1),
    };
  })();

  // eslint-disable-next-line no-param-reassign
  nsp.prefix = prefix;

// eslint-disable-next-line padded-blocks
})(window.utils);
