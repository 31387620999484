/* eslint-disable camelcase */
/* eslint-disable no-undef */

// eslint-disable-next-line no-extra-semi,wrap-iife
;(function DPManager_CloseHandlerScope(global) {
  'use strict';

  var DPManager_CloseHandler = new MooTools.Class({

    name: 'DPManager_CloseHandler',

    mCookieName: 'closed-sections',

    mClosed: [],

    mItems: [],

    initialize: function() {
      this.setSavedData();
      MooTools.Array.prototype.forEach.call(document.querySelectorAll('.action-remove'), this.initializeItem.bind(this));
    },

    setSavedData: function() {
      this.mClosed = MooTools.JSON.parse(MooTools.Cookie.read(this.mCookieName)) || [];
    },

    saveData: function() {
      MooTools.Cookie.write(this.mCookieName, MooTools.JSON.stringify(this.mClosed));
    },

    initializeItem: function(pItem) {
      if (this.mClosed.indexOf(pItem.dataset.closeItem) === -1) {
        this.showItem(pItem);
      }
    },

    showItem: function(pItem) {
      var close_btn = document.createElement('span');
      close_btn.className = 'close';
      close_btn.innerHTML = 'close';
      close_btn.addEventListener('click', function() {
        this.hideItem(pItem);
      }.bind(this));

      pItem.appendChild(close_btn);

      setTimeout(function() {
        pItem.classList.add('visible');
      }, 500);
    },

    hideItem: function(pItem) {
      pItem.classList.remove('visible');

      this.mClosed.push(pItem.dataset.closeItem);
      this.saveData();
    // eslint-disable-next-line comma-dangle
    }

  });

  // eslint-disable-next-line no-param-reassign
  global.DPManager_CloseHandler = DPManager_CloseHandler;

// eslint-disable-next-line padded-blocks
})(window);
