// eslint-disable-next-line wrap-iife
(function FeatureTogglerScope(global) {
  'use strict';

  const FeatureToggler = function (data) {
    // set features names
    if (data.features_names && typeof data.features_names === 'object') {
      Object.keys(data.features_names).forEach(function (featureName) {
        window[featureName] = data.features_names[featureName];
      });
    }

    // set features status
    this.features_status = data.features_status;

    // features status in current plan
    this.features_status_by_plan = data.features_by_plan;

    // trials for features
    this.features_trial_active = data.features_trial_active;

    // are trials for features expired?
    this.features_trial_expired = data.features_trial_expired;
  };

  /**
   * Checks if there is a trial active for a feature
   *
   * @param featureName feature or feature group name
   * @returns {*}
   */
  FeatureToggler.prototype.isTrialActive = function (featureName) {
    if (typeof this.features_trial_active[featureName] === 'undefined') {
      return false;
    }

    return this.features_trial_active[featureName];
  };

  /**
   * Checks if feature trial has expired
   *
   * @param featureName feature or feature group name
   * @returns {*}
   */
  FeatureToggler.prototype.hasTrialExpired = function (featureName) {
    if (typeof this.features_trial_expired[featureName] === 'undefined') {
      return false;
    }

    return this.features_trial_expired[featureName];
  };

  /**
   * Checks if feature is enabled
   *
   * @param featureName feature or feature group name
   * @returns {*}
   */
  FeatureToggler.prototype.enabled = function (featureName) {
    if (typeof this.features_status[featureName] === 'undefined') {
      return true;
    }

    return this.features_status[featureName];
  };

  /**
   * Checks if feature is disabled only by account plan
   *
   * @param featureName feature or feature group name
   * @returns {boolean}
   */
  FeatureToggler.prototype.disabledInPlan = function (featureName) {
    if (typeof this.features_status_by_plan[featureName] === 'undefined') {
      return false;
    }

    return !this.features_status_by_plan[featureName];
  };

  // eslint-disable-next-line no-param-reassign
  global.FeatureToggler = FeatureToggler;
})(window);
