/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable padded-blocks */
/* eslint-disable no-console */
/* globals AnalyticsEnums */
/* globals Promise */
/* globals _ */

// eslint-disable-next-line wrap-iife
(function AnalyticsManagerScope(global) {
  'use strict';

  var PROMISE_TIMEOUT = 500;

  var LOCAL_ENV = location.hostname === 'app.uxpin.test';
  var STAGING_ENV = /\.test\.uxpin\.com$/.test(location.hostname);
  var shouldTrackAnalytics = !LOCAL_ENV && !STAGING_ENV;

  /**
   * All handlers supported by eventCallbackDelayedRedirect must return a Promise
   *
   * @type {{}}
   */
  var eventHandlers = {};

  function AnalyticsManager() {
  }

  AnalyticsManager.registerHandler = function(type, handler) {
    eventHandlers[type] = handler;
  };

  // eslint-disable-next-line consistent-return
  AnalyticsManager.event = function(type, data) {
    if (typeof eventHandlers[type] === 'function') {
      return eventHandlers[type](data);
    }
  };

  AnalyticsManager.eventCallback = function(type, data) {
    return AnalyticsManager.event.bind(this, type, data);
  };

  AnalyticsManager.eventCallbackDelayedRedirect = function(e, cb) {
    var redirect = e.target.href;

    if (!redirect) {
      cb();
    } else {
      e.preventDefault();

      cb()
        .then(function() {
          document.location.href = redirect;
        });
    }
  };

  // eslint-disable-next-line max-len
  AnalyticsManager.analyticsEvent = function(category, action, label, value, noninteraction, callback) {
    var trackArgs = arguments;
    return new Promise(function(resolve) {
      callback = callback || function defaultAnalyticsCallback() {
        resolve();
      };

      if (!shouldTrackAnalytics) {
        console.log('trackEvent', trackArgs);
        callback();
      } else {
        try {
          window._gaq.push(['_trackEvent', category, action, label, value, noninteraction], callback);
        } catch (e) {
          console.error('Error Tracking GA Event', e);
        }
      }
    });
  };

  AnalyticsManager.analyticsTiming = function(pCategory, pVariable, pTime, pLabel, pSampleRate) {
    var trackArgs = arguments;
    if (!shouldTrackAnalytics) {
      console.log('trackTiming', trackArgs);
    } else if (pTime > 0) {
      // eslint-disable-next-line vars-on-top
      var rate = pSampleRate || 100;
      try {
        window._gaq.push(['_trackTiming', pCategory, pVariable, pTime, pLabel, rate]);
      } catch (e) {
        console.error('Error Tracking GA Timing', e);
      }
    }
  };

  AnalyticsManager.dataLayerEvent = function(event, meta, callback) {
    window.dataLayer = window.dataLayer || [];
    return new Promise(function(resolve) {
      var cbTimeout = setTimeout(resolve, PROMISE_TIMEOUT);
      event = { event: event };
      callback = {
        eventCallback: callback || function resolveDataLayerCallback() {
          clearTimeout(cbTimeout);
          resolve();
          // eslint-disable-next-line comma-dangle
        },
      };

      _.extend(event, meta, callback);
      window.dataLayer.push(event);
    });
  };

  // Creating wireframe handler
  AnalyticsManager.registerHandler(AnalyticsEnums.UXPIN.CREATE_WIREFRAME, function() {
    var promises = [];

    promises.push(AnalyticsManager.dataLayerEvent(AnalyticsEnums.APPUSE.CREATE_WIREFRAME));

    return Promise.all(promises);
  });

  global.AnalyticsManager = AnalyticsManager;

})(window);
