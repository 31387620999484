/* eslint-disable wrap-iife */

(function AnalyticsEnumsScope(global) {
  'use strict';

  var AnalyticsEnums = (function() {
    return Object.freeze({

      APPUSE: {
        CREATE_WIREFRAME: 'appuse_createwireframe',
      },

      UXPIN: {
        CREATE_WIREFRAME: 'uxpin_create_wireframe',
      },

    });
  })();

  // eslint-disable-next-line no-param-reassign
  global.AnalyticsEnums = AnalyticsEnums;

// eslint-disable-next-line padded-blocks
})(window);
