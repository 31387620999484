/* eslint-disable */

var DSMenuBar = new MooTools.Class({

  name: 'DSMenuBar',

  mItemActive: false,
  mLastClicked: false,
  mItems: [],
  mNotifications: {},
  mWidth: 'auto',
  mPosition: 'topLeft',
  mEdge: 'leftTop',
  mRelativeTo: document.body,

  initialize: function() {
    this.CreateGraphics();
    this.WindowResize.delay(1000, this);
  },

  CreateGraphics: function() {
    this.mMenuBarEl = new MooTools.Element('div', {
      'class': 'MenuBar',
      'styles': {
        'position': 'absolute',
        'width': window.getWidth() + 'px',
        'top': '-30px'
      }
    })

    new MooTools.Element('a', {
      'class': 'Badge',
      'href': 'http://uxpin.com/',
      'target': '_blank'
    }).inject(this.mMenuBarEl);

    window.addEvent('resize', this.WindowResize.bind(this));
    window.addEvent('scroll', this.WindowScroll.bind(this));

    this.mMenuBarInner = new MooTools.Element('div', {
      'class': 'Inner'
    }).injectInside(this.mMenuBarEl);

    // Obszar powiadomien (notifications area)
    this.mNotificationsArea = new MooTools.Element('div', {
      'styles': {
        'position': 'absolute',
        'right': 100,
        'top': 0
      }
    }).injectInside(this.mMenuBarInner);

    // Dodaje menu "Options"
    var el = this.AddMenuItem('Options', new DSContextMenu(101, [
      {
        'text': 'Logout',
        'href': '/mod/Login/Logout'
      }
    ]), true, true).addClass('options');

    // Chowamy
    this.mMenuBarEl.hide();

    // Add menubar to body
    this.mMenuBarEl.injectInside(document.body);
  },

  Show: function() {
    this.mMenuBarEl.setStyle('top', 0);
  },

  makeVisible: function() {
    this.mMenuBarEl.show();
  },

  Hide: function() {
    // eslint-disable-next-line spaced-comment
    //this.mMenuBarEl.effect('top', {duration: 600}).start(-30);
  },

  // Position properly context menus
  PositionContextMenus: function() {
    var it;

    for(i = 0; i < this.mItems.length; i++) {

      it = this.mItems[i];

      it.cxMenu.Move(it.getPosition().x.toIntZero(), it.getPosition().y.toIntZero() + this.mMenuBarEl.getStyle('height').toIntZero() - 1);
    }
  },

  setWidth: function(pWidth) {
    this.mWidth = pWidth;
    this.WindowResize();
    return this;
  },

  setPosition: function(pPosition, pEdge, pRelativeTo) {

    this.mPosition = pPosition || this.mPosition;
    this.mEdge = pEdge || this.mEdge;
    this.mRelativeTo = pRelativeTo || this.mRelativeTo;

    this.WindowResize();
    return this;
    // eslint-disable-next-line indent
    },

  WindowResize: function() {

    var width = this.mWidth;

    if ('auto' == this.mWidth) {
      width = window.getWidth();
    }

    this.mMenuBarEl
      .setStyle('width', width)
      .position({
        'position': this.mPosition,
        'edge': this.mEdge,
        'relativeTo': this.mRelativeTo
      })

    // Skraca obszar powiadomien zeby nie wchodzil na klawisze menu
    this.mNotificationsArea.setStyle('left', this.mNotificationsArea.getDimensions().totalWidth);
  },

  WindowScroll: function() {
    return;
    // eslint-disable-next-line no-unreachable
    var scroll_x = window.getScroll().x;

    this.mMenuBarEl.setStyle('left', scroll_x);

    if (this.mBaloon) {
      this.mBaloon.setStyle('right', 12 - scroll_x);
    }

  },

  AddMenuItem: function(pText, pContextMenu, pInsertFirst) {

    this.mMenuBarEl.show();

    var it = new MooTools.Element('a', {
      'href': '',
      'class': 'Item'
    }).injectInside(this.mMenuBarInner);
    it.addEvent('mouseup', this.MenuItemClick.bind(this, it));
    it.addEvent('mouseover', this.MenuItemMouseOver.pass([it], this));
    it.addEvent('click', function(pEvent) {
      var event = new MooTools.Event(pEvent);
      event.stop();
    }.bindWithEvent());
    it.cxMenu = pContextMenu;
    it.set('html', pText);

    // Dodaje klase CXMenuBar - potrzebne do prawidłowego ostylowania
    it.cxMenu.getDomElement().addClass('CXMenuBar');

    // Skraca obszar powiadomien zeby nie wchodzil na klawisze menu
    this.mNotificationsArea.setStyle('left', this.mNotificationsArea.getDimensions().totalWidth);

    if (true === pInsertFirst) {
      it.itemNumber = this.mItems.unshift(it);
    }
    else {
      it.itemNumber = this.mItems.push(it);
    }

    return it;
  },

  AddMenuLink: function(pText, pHref, pInsertFirst) {
    it = new MooTools.Element('a', {
      'class': 'Item',
      'href': pHref
    }).set('html', pText).addEvent('mouseover', this.HideAllContextMenus.bind(this));

    if (true === pInsertFirst && this.mItems.length > 0) {
      it.injectBefore(this.mItems[0]);
    }

    it.injectInside(this.mMenuBarInner);
  },

  MenuItemClick: function(pItem, pEvent) {

    this.PositionContextMenus();

    if (this.mLastClicked == pItem.itemNumber || false == this.mItemActive) {
      this.mItemActive = !this.mItemActive;
    }

    this.HideAllContextMenus();

    if (this.mItemActive) {
      pItem.cxMenu.Show();
      this.mClickEvent1 = document.addEvent('click', this.MenuItemHideAll.bind(this));
      pItem.addClass('active');
    }

    this.mLastClicked = pItem.itemNumber;

    pItem.blur();
    pEvent.preventDefault();
  },

  MenuItemMouseOver: function(pItem) {

    if (this.mItemActive) {

      this.HideAllContextMenus();
      pItem.cxMenu.Show();
      this.mClickEvent2 = document.addEvent('click', this.MenuItemHideAll.bind(this));
      pItem.addClass('active');

      this.mLastClicked = pItem.itemNumber;
    }

  },

  MenuItemHideAll: function(pEvent) {
    document.removeEvent('mouseup', this.mClickEvent1);
    document.removeEvent('mouseup', this.mClickEvent2);

    if (pEvent.clientY < this.mMenuBarEl.getStyle('height').toInt()) {
      return;
    }

    this.mLastClicked = false;
    this.mItemActive = false;
    this.HideAllContextMenus();
  },

  HideAllContextMenus: function() {
    for(i = 0; i < this.mItems.length; i++) {
      it = this.mItems[i];
      it.cxMenu.Hide();
      it.removeClass('active');
    }
  },

  /**
   * Powiadomienie
   *
   * Jesli element powiadomienia nie byl jeszcze utworzony to za pierwszym razem zostanie.
   *
   * DSMenuBar1.setNotification({
   *   'name': 'login_name',
   *   'class': 'Notify',
   *   'html': '<strong>Piotr</strong> Duszyński',
   *   'fx': 'blink'
   * });
   *
   * pOptions object
   */
  // eslint-disable-next-line space-before-function-paren
  setNotification: function (pOptions) {

    var name = pOptions.name;

    pOptions.onClick = pOptions.onClick || function(){};

    MooTools.$clear(this.mPeriodical1);

    if (this.mNotifications[name]) {
      this.removeNotification(pOptions.name);
    }
    this.mNotifications[name] = new MooTools.Element('div').injectInside(this.mNotificationsArea);

    if(pOptions['class'] != ''){
      this.mNotifications[name].setProperty('class', pOptions['class']);
    }


    this.mNotifications[name].addEvent('click', pOptions.onClick);

    this.mNotifications[name].set('html', pOptions.html);
    this.mNotifications[name].setStyle('width', this.mNotifications[name].getStyle('width'));

    // Jesli na koncu tekstu wstawianego sa 3 kropki (...) to animujemy je
    if (pOptions.html.match(/\.\.\.$/)) {
      // eslint-disable-next-line consistent-return
      this.mPeriodical1 = function() {

        if(!this.mNotifications[name]){
          return false;
        }

        this.mNotifications[name].innerHTML += '.';
        this.mNotifications[name].innerHTML = this.mNotifications[name].innerHTML.replace(/\.\.\.\.$/, '');
      }.periodical(300, this);
    }
  },

  removeNotification: function(pName) {
    MooTools.$clear(this.mPeriodical1);
    if (this.mNotifications[pName]) {
      this.mNotifications[pName].destroy();
      this.mNotifications[pName] = null;
    }
  },

  /**
   * Pokazuje balona z powiadomieniem
   *
   *
   * DSMenuBar1.setBaloon({
   *   'html': '<strong>Piotr</strong> Duszyński',
   *   'title': 'Warning',
   *   'position': {
   *     'x': 36,
   *     'y': 12,
   *     'align': 'right'
   *   }
   *   'direction': 'right' // z ktorej strony ma byc poczatek chmurki, domyslnie z prawej
   // eslint-disable-next-line max-len
   *    opcjonalnie po jakim czasie ma nastapic wylaczenie (domyslnie 30 sekund), jesli "false" to okno pozostanie az do czasu wylaczenia przez uzytkownika
   *   'close_delay': 30000,
   * });
   */
  setBaloon: function(pOptions) {

    if (!this.mBaloon) {
    	if (MooTools.$defined(pOptions.direction) && pOptions.direction == 'left') {
    		this.mBaloon = new MooTools.Element('div', {'class': 'MenuBarBaloon', 'styles': {'opacity': 0}}).adopt(ImgAlpha('/p/dsmenubar_baloon2.png', 245, 109)).injectInside(document.body);
    	}
    	else {
    		this.mBaloon = new MooTools.Element('div', {'class': 'MenuBarBaloon', 'styles': {'opacity': 0}}).adopt(ImgAlpha('/p/dsmenubar_baloon.png', 245, 109)).injectInside(document.body);
    	}
      this.mBaloonTitle = new MooTools.Element('div', {'class': 'Title'}).injectInside(this.mBaloon);
      this.mBaloonFX = new MooTools.Fx.Tween(this.mBaloon, {'duration': 700});
      this.mBaloonContent = new MooTools.Element('div', {'class': 'Content'}).injectInside(this.mBaloon);
      this.mBaloon.addEvent('click', this.removeBaloon.bind(this));
    }

    // Domyslne wartosci position
    if (!pOptions.position) {
      pOptions.position = {
        'x': 12 - window.getScroll().x,
        'y': 20,
        'align': 'right'
      };
    }

    // Pozycjonowanie
    this.mBaloon.setStyles({'left': 'auto', 'right': 'auto'});
    this.mBaloon.setStyle(pOptions.position.align, pOptions.position.x.toIntZero());
    this.mBaloon.setStyle('top', pOptions.position.y.toIntZero());

    this.mBaloonTitle.set('html', pOptions.title);
    this.mBaloonContent.set('html', pOptions.html);

    this.mBaloonContent.setStyle('top', 36 + Math.abs(((50 - this.mBaloonContent.getStyle('height').toIntZero())) / 2));

    this.mBaloonFX.start('opacity', 1);

    if (false !== pOptions.close_delay) {
      MooTools.$clear(this.mTimer1);
      if (1000 <= parseInt(pOptions.close_delay)) {
      	this.mTimer1 = this.removeBaloon.delay(parseInt(pOptions.close_delay), this);
      } else {
      	this.mTimer1 = this.removeBaloon.delay(30000, this);
      }
    }
  },

  removeBaloon: function() {

    if (!this.mBaloonFX) {
      return;
    }

    this.mBaloonFX.start('opacity', 0).chain(function() {
      this.mBaloonFX = null;
      this.mBaloonTitle.destroy();
      this.mBaloonTitle = null;
      this.mBaloonContent.destroy();
      this.mBaloonContent = null;
      this.mBaloon.destroy();
      this.mBaloon = null;
    }.bind(this));
  }

});

var DSMenuBar1;

DSMenuBar.Load = function() {
  if (!DSMenuBar1) {
    DSMenuBar1 = new DSMenuBar();
  }

  return DSMenuBar1;
};
