/* eslint-disable */
/* globals dpManager */

var ExportItem = new MooTools.Class({

  name: 'ExportItem',

  Implements: [MooTools.Options],

  mId: null,

  mEl: null,

  options: {
    'project_hash': null,
    'type': null,   // valid types: Images, Pdf, ToZip
    'menu': 1,      // 1 || 0, uzywane tylko w przypadku type: ToZip
    'name': null,   // nazwa exportowanego projektu
    'pdfhead': 0,    // 1 || 0
    'create_iteration': true
  },

  mTypeToText: {
    'Images': 'Images',
    'Pdf': 'PDF',
    'ToZip': 'HTML'
  },

  mUrl: null,

  mProgress: '',

  mRestared: false,

  mInitialized: false,


  initialize: function(pOptions, pId) {
    var id = pId || this.generateUniqueId();

    this.setId(id);
    this.setOptions(pOptions);

    this.mEl = new MooTools.Element('div', {
      'id': 'export_'+this.getId(),
      'class': 'export-info'
    });
  },

  generateUniqueId: function() {
    // eslint-disable-next-line quotes,no-bitwise,comma-spacing
    return ("0000" + (Math.random()*Math.pow(36,4) << 0).toString(36)).substr(-4);
  },

  setId: function(pId) {
    this.mId = pId;
  },

  getId: function() {
    return this.mId;
  },

  getEl: function() {
    return this.mEl;
  },

  isInitialized: function() {
    return this.mInitialized;
  },

  setUrl: function(pUrl) {
    this.mUrl = pUrl;

    if(pUrl.length > 0) {
      // data zakonczenia lub obecny czas gdy nie ma daty zakonczenia, odswiezy sie po reloadzie strony.
      this.options.finished = this.options.finished > 0 ? this.options.finished : this.getTimestamp();

      var time = this.getFormattedTime(this.options.finished);
      var hour_text = '<span class="export_time_diff">' + time.hour + ':' + time.minute + '</span>';
      var project_name = window.htmlspecialchars(this.options.name, 'ENT_QUOTES');

      this.mEl.getElement('.export-icon img').set('src', '/p/download.gif');
      this.mEl.getElement('.export-text').set('html', hour_text + ' <a href="'+this.mUrl+'">Download "' + project_name + '" '+this.mTypeToText[this.options.type]+'</a>');

      this.mEl.getElement('.export-icon').classList.add('export-ready');
    }
  },

  getTimestamp: function() {
      // eslint-disable-next-line indent
      return Math.round((new Date()).getTime() / 1000); // in seconds since 1970...
  },

  getFormattedTime: function(timestampInSec) {
    // provided timestamp or current
    var date_finished = new Date(timestampInSec * 1000) || this.getTimestamp;
    var hours = date_finished.getHours() < 10 ? '0' + date_finished.getHours() : date_finished.getHours();
    var minutes = date_finished.getMinutes() < 10 ? '0' + date_finished.getMinutes() : date_finished.getMinutes();

    return {'hour': hours, 'minute': minutes};
  },

  setProgress: function(pProgress) {
    this.mProgress = pProgress;
    this.mEl.getElement('.export-progress').set('html', this.mProgress);
  },

  setFailed: function() {
    this.mEl.getElement('.export-icon img').setStyle('display', 'none');
    this.mEl.getElement('.export-text').set('html', 'Failed to generate '+this.mTypeToText[this.options.type]+' for "'+this.options.name+'"');
  },

  start: function() {
    this.mProgress = '0%';

    // zapisz info w cookie
    var cookie = dpManager.Export.readCookie();
    cookie.push(this.getId());
    dpManager.Export.setCookie(cookie);

    // wypelnij box informacja o eksporcie
    this.fillEl();

    // wystartuj export na serwerze
    var url = '/ajax/dmsExport/Start/?__ajax_request=1';

    // przygotuj zestaw danych
    var data = MooTools.$extend(this.options, {
      'id': this.getId()
    });

    new MooTools.Request({
      'method': 'post',
      'url': url,
      'data': {'json': MooTools.JSON.encode(data)},
      'onSuccess': function(res) {
        // eslint-disable-next-line no-shadow
        var data = MooTools.JSON.decode(res);

        if(!data.status) {
          this.stop();
        }

        this.mInitialized = true;
        dpManager.Export.startChecker();
      }.bind(this),
      'onFailure': this.stop.bind(this)
    }).send();
  },

  restart: function() {
    this.mRestared = true;
    this.mInitialized = true;
  },

  fillEl: function() {
    var timestamp = this.options.finished > 0 ? this.options.finished : this.options.started_at;
    // eslint-disable-next-line no-redeclare
    var timestamp = timestamp > 0 ? timestamp : ((+new Date(this.options.enqueued_at)) / 1000);
    var time = this.getFormattedTime(timestamp);
    var hour_text = '<span class="export_time_diff">' + time.hour + ':' + time.minute + '</span>';
    var project_name = window.htmlspecialchars(this.options.name, 'ENT_QUOTES');

    this.mEl.set('html', '\
      <span class="export-icon"><img src="/p/spinner.gif" /></span>\n\
      <span class="export-text">' + hour_text + ' Generating '+this.mTypeToText[this.options.type]+' for "' + project_name + '"</span>\n\
      <span class="export-progress">'+this.mProgress+'</span>\n\
    ');

    var close_el = new MooTools.Element('a', {
      'class': 'export-item-close',
      'html': 'discard'
    }).inject(this.mEl);

    close_el.addEvent('click', function(pE) {
      pE.stop();
      this.stop();
    }.bind(this));
  },

  close: function() {
    this.mEl.destroy();
  },

  /**
   *
   */
  stop: function() {
    dpManager.Export.stop(this.getId());
  }

});

ExportItem.exportTypes = {
  images: 'Images',
  pdf: 'Pdf',
  html: 'ToZip'
};
