/* eslint-disable block-scoped-var */
/* eslint-disable no-redeclare */
/* eslint-disable vars-on-top */
/* eslint-disable yoda */

// eslint-disable-next-line no-extra-semi,wrap-iife
;(function CloneRecursivelyScope(nsp) {
  'use strict';

  // eslint-disable-next-line no-multi-spaces,padded-blocks
  var  CloneRecursively = function(obj) {

    // Handle the 3 simple types, and null or undefined
    // eslint-disable-next-line quotes,eqeqeq
    if (null == obj || "object" != typeof obj) {
      return obj;
    }

    // Handle Date
    if (obj instanceof Date) {
      var copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    // eslint-disable-next-line no-undef
    if (obj instanceof MooTools.Array) {
      var copy = [];
      // eslint-disable-next-line no-plusplus
      for (var i = 0, len = obj.length; i < len; i++) {
        copy[i] = CloneRecursively(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      if (typeof obj.clone === 'function') {
        return obj.clone();
      // eslint-disable-next-line no-else-return
      } else {
        var copy = {};
        // eslint-disable-next-line no-restricted-syntax
        for (var attr in obj) {
          // eslint-disable-next-line no-prototype-builtins
          if (obj.hasOwnProperty(attr)) {
            copy[attr] = CloneRecursively(obj[attr]);
          }
        }
        return copy;
      }
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  // eslint-disable-next-line semi
  }

  // eslint-disable-next-line no-param-reassign
  nsp.CloneRecursively = CloneRecursively;
})(window.utils);
